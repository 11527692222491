import * as React from 'react'

import {AddIcon, CloseIcon, RepeatIcon} from '@chakra-ui/icons'
import {Collapse, Flex, HStack, IconButton, Skeleton, Text, useDisclosure} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {DeviceContext} from '..'
import {emptyAssignment} from '../constants'
import AssignementItem from './item'

const AssignementManager = () => {
  const {t} = useTranslation()
  const {isOpen, onToggle, onClose} = useDisclosure()
  const {isCurrentlyRented, assignments} = React.useContext(DeviceContext)

  return (
    <Flex direction="column">
      <HStack>
        <Text fontWeight="bold">{t('organizer:devices:assignments')}</Text>
        {isCurrentlyRented && (
          <IconButton
            aria-label="add-assignment"
            icon={isOpen ? <CloseIcon /> : <AddIcon />}
            onClick={onToggle}
            size="sm"
            isDisabled={assignments?.loading}
          />
        )}
        <IconButton
          aria-label="refetch-assignments"
          icon={<RepeatIcon />}
          onClick={assignments?.fetch}
          size="sm"
          isLoading={assignments?.loading}
        />
      </HStack>
      <Collapse in={isOpen} unmountOnExit={true}>
        <AssignementItem assignment={emptyAssignment} onCancel={onClose} />
      </Collapse>

      {assignments?.loading ? (
        <>
          <Skeleton h="90px" mt={2} borderRadius="lg" />
          <Skeleton h="90px" mt={2} borderRadius="lg" />
        </>
      ) : (
        assignments?.data?.map((a, i) => <AssignementItem key={i} assignment={a} />)
      )}
    </Flex>
  )
}

export default AssignementManager
