import * as React from 'react'

import {DownloadIcon, TimeIcon, SettingsIcon} from '@chakra-ui/icons'
import {Button, Flex, Menu, MenuButton, MenuItem, MenuList, Tooltip, useDisclosure} from '@chakra-ui/react'
import {Users as UsersIcon} from 'react-feather'
import {useTranslation} from 'react-i18next'

import ConfigModal from './config-modal'
import useSyncEventData from './use-bridge-sync'

type Props = {
  eventID?: string
  onComplete?: () => void
}

const SyncMenu = ({eventID, onComplete}: Props) => {
  const {t} = useTranslation()
  const {isOpen: isConfigOpen, onOpen: onConfigOpen, onClose: onConfigClose} = useDisclosure()

  const {syncParticipants, syncReadouts, syncing} = useSyncEventData({eventID, onComplete, onConfigOpen})

  return (
    <>
      <Menu placement="bottom">
        <MenuButton as={Button} p={1} isLoading={syncing}>
          <DownloadIcon />
        </MenuButton>
        <MenuList minW={0} p={2} display="flex" gap={2}>
          <Tooltip label={t('organizer:participants:synchronizeParticipants')}>
            <MenuItem w="auto" p={1} as={Button} onClick={syncParticipants} isLoading={syncing}>
              <UsersIcon size="16px" />
            </MenuItem>
          </Tooltip>
          <Tooltip label={t('organizer:participants:synchronizeReadouts')}>
            <MenuItem w="auto" p={1} as={Button} onClick={syncReadouts} isLoading={syncing}>
              <TimeIcon />
            </MenuItem>
          </Tooltip>
          <Tooltip label={t('organizer:participants:configureDataSources')}>
            <MenuItem w="auto" p={1} as={Button} onClick={onConfigOpen} isDisabled={syncing}>
              <SettingsIcon />
            </MenuItem>
          </Tooltip>
        </MenuList>
      </Menu>
      <ConfigModal eventID={eventID ?? ''} open={isConfigOpen} onClose={onConfigClose} />
    </>
  )
}

export default SyncMenu
