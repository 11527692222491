import * as React from 'react'

import {Button, IconButton, Link, Menu, MenuList, MenuItem, MenuButton, Stack, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import Flag from 'react-world-flags'

import './language-selector.css'

const languageToFlag = {
  en: 'gb',
  pl: 'pl',
}
const languageToName = {
  en: 'English',
  pl: 'Polski',
}

const LanguageSelector = () => {
  const {i18n} = useTranslation()

  return (
    <Menu gutter={4} placement="bottom-end">
      <MenuButton
        aria-label="Select the language"
        as={IconButton}
        className="flagSelectorButton"
        bg="none"
        icon={<Flag className="flagSelector" code={(languageToFlag as any)[i18n.language]} />}
      />
      <MenuList>
        <MenuItem onClick={() => i18n.changeLanguage('pl')}>
          <Stack isInline={true} spacing={2} align="center">
            <Flag className="flagSelector" code="pol" />
            <Text as="span">Polski</Text>
          </Stack>
        </MenuItem>
        <MenuItem onClick={() => i18n.changeLanguage('en')}>
          <Stack isInline={true} spacing={2} align="center">
            <Flag className="flagSelector" code="gb" />
            <Text as="span">English</Text>
          </Stack>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default LanguageSelector
