import * as React from 'react'

import {AddIcon, RepeatIcon} from '@chakra-ui/icons'
import {
  Heading,
  Stack,
  Grid,
  GridItem,
  HStack,
  Text,
  Input,
  useDisclosure,
  NumberInput,
  NumberInputField,
  Button,
  Tooltip,
  Skeleton,
  Box,
  Center,
  InputRightElement,
  InputGroup,
  Flex,
} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'
import Select, {type MultiValue} from 'react-select'

import {EditorMode} from '@/admin/types'
import {supabase} from '@/api'
import {OrganizerEventParticipant} from '@/api/models'
import {DeleteResourceModal, SortByButton} from '@/common/components'
import useTableState from '@/common/data-table/use-table-state'
import {useSupabaseQuery} from '@/common/hooks'
import {selectStyles} from '@/theme/components/select'
import {SelectOption} from '@/types'

import {EventContext} from '..'
import {emptyEventParticipant} from './constants'
import EditorEventParticipantModal from './editor-modal'
import ParticipantListItem from './list-item'
import SyncMenu from './sync-menu'
import {ParticipantFilter} from './types'
import {filterParticipants} from './utils'

const ParticipantsList = () => {
  const {t} = useTranslation()
  const {event, classes} = React.useContext(EventContext)
  const [filter, setFilter] = React.useState<ParticipantFilter>({number: 0, name: '', classes: []})
  const [editorMode, setEditorMode] = React.useState<EditorMode>('add')
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [currentEventParticipant, setCurrentEventParticipant] =
    React.useState<OrganizerEventParticipant>(emptyEventParticipant)

  const tableState = useTableState({sortBy: 'number'})
  const {
    loading,
    data: participants,
    fetch,
  } = useSupabaseQuery<OrganizerEventParticipant>({
    fields: '*',
    table: 'organizer_event_participants',
    autoFetch: false,
    order: tableState.sortBy.column as keyof OrganizerEventParticipant,
    descending: tableState.descending,
    filter: React.useCallback(
      (query: PostgrestFilterBuilder<OrganizerEventParticipant>) => query.match({event: event.id}),
      [event]
    ),
  })

  React.useEffect(() => {
    event.id && fetch()
  }, [event, fetch])

  const handleFilterChange = React.useCallback(
    ({target: {value, id}}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setFilter((prev) => ({...prev, [id]: id === 'number' ? +value : value}))
    },
    []
  )
  const handleClasssesChange = React.useCallback(
    (e: MultiValue<SelectOption>) => {
      setFilter((prev) => ({...prev, classes: Array.isArray(e) ? e.map((x) => x.value) : []}))
    },
    [setFilter]
  )

  const handleSortByChange = React.useCallback(
    (column: string) => {
      if (!(column in emptyEventParticipant)) return
      tableState.setSortBy({column})
      tableState.sortBy.column === column
        ? tableState.setDescending(!tableState.descending)
        : tableState.setDescending(false)
    },
    [tableState]
  )

  const handleAdd = React.useCallback(() => {
    if (!event?.id) return
    setEditorMode('add')
    setCurrentEventParticipant({...emptyEventParticipant, event: event.id})
    onEditorOpen()
  }, [event, onEditorOpen])

  const handleEdit = React.useCallback(
    (item: OrganizerEventParticipant) => {
      setEditorMode('update')
      setCurrentEventParticipant(item)
      onEditorOpen()
    },
    [onEditorOpen]
  )

  const handleDeleteAction = React.useCallback(
    (item: OrganizerEventParticipant) => {
      setCurrentEventParticipant(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  const handleDelete = React.useCallback(async () => {
    // deletes all class assignments of participant
    // to actually delete the particicipant the user must do it in the serie's screen
    // delete participant here or leave it as it is?
    const {error} = await supabase
      .from('event_participants')
      .delete()
      .match({event: event.id, participant: currentEventParticipant.id})
    if (error) throw error
  }, [event, currentEventParticipant])

  const classesOptions = React.useMemo(
    () => [
      {label: t('organizer:participants:anyClass'), value: 'any'},
      {label: t('organizer:participants:noClassesAssigned'), value: 'empty'},
      ...(classes?.data?.map((c) => ({label: c.name, value: c.id})) || []),
    ],
    [classes, t]
  )

  const filteredParticipants = React.useMemo(
    () => (participants ? participants.filter((p) => filterParticipants(p, filter)) : []),
    [participants, filter]
  )

  return (
    <>
      <Stack spacing={2}>
        <Stack position="sticky" top="15px" zIndex="sticky">
          <HStack justify="space-between">
            <Heading size="md">{t('organizer:events:manageParticipants')}</Heading>
            <Heading size="sm">
              {t('organizer:events:found')}: {filteredParticipants.length}
            </Heading>
          </HStack>
          {/* FILTER */}
          {/* TODO: extract filter to a separate component */}
          <Grid
            p={2}
            w="100%"
            templateColumns="80px 200px 100px 1fr"
            gap={2}
            bgColor="gray.900"
            borderRadius="md"
            boxShadow="lg"
          >
            <Center>
              {/* NUMBER FILTER */}
              <InputGroup>
                <NumberInput value={filter.number || ''}>
                  <NumberInputField id="number" px={2} placeholder="#" onChange={handleFilterChange} />
                  <InputRightElement>
                    <SortByButton
                      column="number"
                      current={tableState.sortBy.column}
                      descending={tableState.descending}
                      onSortChange={handleSortByChange}
                    />
                  </InputRightElement>
                </NumberInput>
              </InputGroup>
            </Center>
            <Center>
              {/* NAME FILTER */}
              <InputGroup>
                <Input
                  id="name"
                  type="text"
                  placeholder="name"
                  value={filter.name}
                  onChange={handleFilterChange}
                />
                <InputRightElement>
                  <SortByButton
                    column="name"
                    current={tableState.sortBy.column}
                    descending={tableState.descending}
                    onSortChange={handleSortByChange}
                  />
                </InputRightElement>
              </InputGroup>
            </Center>
            <HStack justify="center">
              <Text>Avg</Text>
              <SortByButton
                column="avg"
                current={tableState.sortBy.column}
                descending={tableState.descending}
                onSortChange={handleSortByChange}
              />
            </HStack>
            <GridItem>
              <Flex gap={2}>
                {/* CLASS FILTER */}
                <Box flex="1">
                  <Select
                    placeholder={t('events:participants:classes')}
                    value={classesOptions?.filter((item) => filter.classes.includes(item.value))}
                    options={classesOptions}
                    isMulti={true}
                    isClearable={true}
                    isDisabled={classes?.loading || loading}
                    onChange={handleClasssesChange}
                    styles={selectStyles}
                  />
                </Box>
                {/* REFRESH BUTTON */}
                <Tooltip label={t('common:actions:refresh')}>
                  <Button onClick={fetch} p={1}>
                    <RepeatIcon />
                  </Button>
                </Tooltip>

                <SyncMenu eventID={event.id} onComplete={fetch} />

                {/* ADD PARTICIPANT BUTTON */}
                <Tooltip label={t('organizer:participants:addParticipant')}>
                  <Button onClick={handleAdd} p={1}>
                    <AddIcon />
                  </Button>
                </Tooltip>
              </Flex>
            </GridItem>
          </Grid>
        </Stack>
        {/* PARTICIPANTS LIST */}
        {loading
          ? Array(10)
              .fill(0)
              .map((_, i) => <Skeleton key={i} h="46px" borderRadius="xl" overflow="hidden" />)
          : filteredParticipants.map((p, i) => (
              <ParticipantListItem key={i} item={p} onEdit={handleEdit} onDelete={handleDeleteAction} />
            ))}
      </Stack>
      {/* MODALS */}
      <EditorEventParticipantModal
        item={currentEventParticipant}
        mode={editorMode}
        open={isEditorOpen}
        onClose={onEditorClose}
        onComplete={fetch}
      />
      <DeleteResourceModal
        onDelete={handleDelete}
        onClose={onDeleteClose}
        open={isDeleteOpen}
        onComplete={fetch}
      />
    </>
  )
}

export default ParticipantsList
