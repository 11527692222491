import {add} from 'date-fns'

import {ParticipantTag} from '@/api/models'

export const emptyParticipantTag: ParticipantTag = {
  id: '',
  epc: '',
  created_at: new Date(),
  updated_at: new Date(),
  start_time: new Date(),
  end_time: add(new Date(), {hours: 1}),
  participant_id: '',
  participant_name: '',
  participant_number: 0,
  event_id: '',
  event_name: '',
  description: '',
}
