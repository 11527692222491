import {add} from 'date-fns'

import {OrganizerEventParticipant, ParticipantAssignedClass} from '@/api/models'

export const emptyEventParticipant: OrganizerEventParticipant = {
  event: '',
  id: '',
  number: 0,
  name: '',
  first_name: '',
  avg: 0,
  best3: [],
  lap_times: [],
  classes: [],
}

export const emptyClass: ParticipantAssignedClass = {
  class_assignment_id: '',
  class_id: '',
  name: '',
  car_id: '',
  car_name: '',
  start_time: new Date(),
  end_time: add(new Date(), {hours: 4}),
}
