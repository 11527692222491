import * as React from 'react'

import {
  Stack,
  HStack,
  FormControl,
  Button,
  FormLabel,
  Input,
  Checkbox,
  Select,
  Textarea,
  Box,
  useToast,
  Flex,
  FormErrorMessage,
  Tooltip,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {ImageType} from 'react-images-uploading'

import {AdminEvent, TrackLayout, RPC} from '@/api/models'
import {uploadFile} from '@/api/utils'
import {selectSelectedIdentity} from '@/auth/state'
import {ImageUpload} from '@/common/components'
import {useSupabaseQuery, useSupabaseRPC, useLoadingState} from '@/common/hooks'
import {SUPABASE_EVENTS_BUCKET} from '@/constants'
import {useAppSelector} from '@/store'
import {readOnlyStyles} from '@/theme/components/input'
import {dateToInput} from '@/utils/string'

import {emptyEvent} from './constants'
import {inputToUpsertArgs} from './utils'

const EventForm = ({item, refetch}: {item: AdminEvent; refetch: () => Promise<void>}) => {
  const {t} = useTranslation()
  const toast = useToast()
  const selectedIdentity = useAppSelector(selectSelectedIdentity)
  const [editing, setEditing] = React.useState(false)
  const [input, setInput] = React.useState(emptyEvent)
  const [image, setImage] = React.useState<ImageType>()
  const {loading: layoutsLoading, data: layouts} = useSupabaseQuery<TrackLayout>({
    fields: 'id, name',
    table: 'track_layouts',
    order: 'name',
  })

  const onComplete = React.useCallback(() => {
    setEditing(false)
    refetch()
  }, [refetch])

  const {handleRPC} = useSupabaseRPC({
    fnName: RPC.UpsertEvent,
    params: {},
    mode: 'update',
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
    item.picture_url ? setImage({dataURL: item.picture_url}) : setImage(undefined)
  }, [item])

  const _handleSubmit = React.useCallback(async () => {
    try {
      const filepath = image?.file
        ? await uploadFile(SUPABASE_EVENTS_BUCKET, input.id, image?.file)
        : image?.dataURL
        ? input.picture
        : undefined
      await handleRPC(inputToUpsertArgs({...input, picture: filepath}))
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        status: 'error',
        title: t('common:dataTable:updateFail'),
      })
    }
  }, [handleRPC, image, input, toast, t])
  const {handleSubmit, loading} = useLoadingState(_handleSubmit)

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handlePublicChange = React.useCallback(
    ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) =>
      setInput((input) => ({...input, public: checked})),
    []
  )

  const handleDateChange = React.useCallback(({target: {id, value}}: React.ChangeEvent<HTMLInputElement>) => {
    setInput((input) => ({...input, [id]: new Date(value)}))
  }, [])

  const handleLapTimeChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement>) => {
      setInput((input) => ({...input, [id]: +value * 1000}))
    },
    []
  )

  const handleLayoutChange = React.useCallback(
    ({target: {value}}: React.ChangeEvent<HTMLSelectElement>) => {
      if (!editing) return
      setInput((input) => ({...input, track_layout: {...input.track_layout, id: value}}))
    },
    [editing]
  )

  const handleModeChange = React.useCallback(() => {
    if (editing) {
      item && setInput(item)
      item.picture_url ? setImage({dataURL: item.picture_url}) : setImage(undefined)
    }
    setEditing(!editing)
  }, [editing, item])

  const minLapTimeInput = React.useMemo(
    () => Math.floor(input.min_lap_time / 1000) || '',
    [input.min_lap_time]
  )
  const maxLapTimeInput = React.useMemo(
    () => Math.floor(input.max_lap_time / 1000) || '',
    [input.max_lap_time]
  )
  const startTimeInput = React.useMemo(() => dateToInput(new Date(input.start_time)), [input.start_time])
  const endTimeInput = React.useMemo(() => dateToInput(new Date(input.end_time)), [input.end_time])
  const isTimeValid = React.useMemo(
    () => new Date(input.start_time) < new Date(input.end_time),
    [input.start_time, input.end_time]
  )

  const isSubmitDisabled = React.useMemo(
    () =>
      !input.name ||
      !input.description ||
      !input.track_layout?.id ||
      !input.start_time ||
      !input.end_time ||
      !isTimeValid,
    [input, isTimeValid]
  )

  return (
    <Stack p={4} bgColor="gray.900" borderRadius="2xl" position="relative" boxShadow="lg">
      {selectedIdentity?.role === 'admin' && (
        <Flex gap={4} position="absolute" top={4} right={4} zIndex="modal">
          {editing && (
            <Button
              variant="solid"
              colorScheme="blue"
              onClick={handleSubmit}
              isDisabled={isSubmitDisabled}
              isLoading={loading}
            >
              {t('common:actions:save')}
            </Button>
          )}
          <Button
            variant={editing ? 'ghost' : 'solid'}
            colorScheme="blue"
            onClick={handleModeChange}
            isDisabled={loading}
          >
            {editing ? t('common:actions:cancel') : t('common:actions:edit')}
          </Button>
        </Flex>
      )}
      <HStack>
        <Box w="100%">
          <ImageUpload onChange={setImage} value={image} editing={editing} />
        </Box>
        <Stack spacing={4} align="center" width="100%">
          {/* PUBLIC */}
          <FormControl id="public" isDisabled={loading || !editing}>
            <Checkbox isChecked={input.public ?? false} onChange={handlePublicChange}>
              {t('common:fields:public')}
            </Checkbox>
          </FormControl>
          {/* NAME */}
          <FormControl id="name" isDisabled={loading} isRequired={editing} isReadOnly={!editing}>
            <FormLabel color="whiteAlpha.600">{t('common:fields:name')}</FormLabel>
            <Input value={input.name ?? ''} onChange={handleInputChange} _readOnly={readOnlyStyles} />
          </FormControl>
          {/* LAYOUT */}
          <FormControl
            id="layout_id"
            isDisabled={layoutsLoading || loading}
            isRequired={editing}
            isReadOnly={!editing}
          >
            <FormLabel color="whiteAlpha.600">{t('common:entities:trackLayout')}</FormLabel>
            <Select
              value={input.track_layout?.id ?? ''}
              onChange={handleLayoutChange}
              _readOnly={readOnlyStyles}
            >
              <option value="">{t('events:form:selectLayout')}</option>
              {!layoutsLoading &&
                layouts?.map((l) => (
                  <option value={l.id} key={l.id}>
                    {l.name}
                  </option>
                ))}
            </Select>
          </FormControl>
          {/* ALLOWED LAP TIMES */}
          <HStack w="100%">
            <FormControl id="min_lap_time" isDisabled={loading} isRequired={editing} isReadOnly={!editing}>
              <Tooltip label={t('events:form:inSeconds')}>
                <FormLabel color="whiteAlpha.600">{t('events:fields:minLapTime')}</FormLabel>
              </Tooltip>
              <Input
                value={minLapTimeInput}
                onChange={handleLapTimeChange}
                type="number"
                _readOnly={readOnlyStyles}
              />
            </FormControl>
            <FormControl id="max_lap_time" isDisabled={loading} isRequired={editing} isReadOnly={!editing}>
              <Tooltip label={t('events:form:inSeconds')}>
                <FormLabel color="whiteAlpha.600">{t('events:fields:maxLapTime')}</FormLabel>
              </Tooltip>
              <Input
                value={maxLapTimeInput}
                onChange={handleLapTimeChange}
                type="number"
                _readOnly={readOnlyStyles}
              />
            </FormControl>
          </HStack>
        </Stack>
      </HStack>
      <Stack p={2}>
        {/* START / END TIME */}
        <HStack w="100%">
          <FormControl id="start_time" isDisabled={loading} isRequired={editing} isReadOnly={!editing}>
            <FormLabel color="whiteAlpha.600">{t('common:fields:startTime')}</FormLabel>
            <Input
              value={startTimeInput}
              onChange={handleDateChange}
              type="datetime-local"
              _readOnly={readOnlyStyles}
            />
          </FormControl>
          <FormControl
            id="end_time"
            isDisabled={loading}
            isRequired={editing}
            isInvalid={!isTimeValid}
            isReadOnly={!editing}
          >
            <FormLabel color="whiteAlpha.600">{t('common:fields:endTime')}</FormLabel>
            <Input
              value={endTimeInput}
              onChange={handleDateChange}
              type="datetime-local"
              _readOnly={readOnlyStyles}
            />
            <FormErrorMessage>{t('errors:endTime')}</FormErrorMessage>
          </FormControl>
        </HStack>
        {/* DESC */}
        <FormControl id="description" isDisabled={loading} isRequired={editing} isReadOnly={!editing}>
          <FormLabel color="whiteAlpha.600">{t('common:fields:description')}</FormLabel>
          <Textarea
            value={input.description ?? ''}
            onChange={handleInputChange}
            required={true}
            resize="vertical"
            _readOnly={readOnlyStyles}
            rows={4}
          />
        </FormControl>
      </Stack>
    </Stack>
  )
}

export default EventForm
