import * as React from 'react'

import {Box, Heading, useDisclosure} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {Track} from '@/api/models'
import {DeleteResourceModal} from '@/common/components'
import DataTable from '@/common/data-table'
import {Column} from '@/common/data-table/types'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import {FilterField} from '@/common/filter-builder/types'
import {useSupabaseQuery} from '@/common/hooks'

import {EditorMode} from '../types'
import {manageLayoutsAction} from './actions'
import {emptyTrack} from './constants'
import TrackEditorModal from './editor-modal'

const defaultSelectedColumns = ['name', 'website', 'location', 'public']

const Tracks = () => {
  const {t} = useTranslation()
  const tableState = useTableState({sortBy: 'created_at'})
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [currentTrack, setCurrentTrack] = React.useState<Track>(emptyTrack)
  const [currentMode, setCurrentMode] = React.useState<EditorMode>('add')
  const {loading, data, fetch, rows} = useSupabaseQuery<Track>({
    fields: '*',
    table: 'admin_tracks',
    order: tableState.sortBy.column as keyof Track,
    itemsPerPage: tableState.rowsPerPage,
    pageNumber: tableState.page,
    descending: tableState.descending,
    filter: tableState.filter,
  })

  const columns: Column<Track>[] = React.useMemo(
    () => [
      {
        Header: t('common:fields:id').toString(),
        id: 'id',
        renderAs: 'code',
        sortable: false,
      },
      {
        Header: t('common:fields:name').toString(),
        id: 'name',
      },
      {
        Header: t('common:fields:description').toString(),
        id: 'description',
        renderAs: 'longString',
      },
      {
        Header: t('common:fields:website').toString(),
        id: 'website',
        renderAs: 'url',
      },
      {
        Header: `${t('tracks:fields:location')}`.toString(),
        id: 'location',
        renderer: ({location}) =>
          location
            ? `${location.coordinates[0].toFixed(6)}, ${location.coordinates[1].toFixed(6)}`
            : t('common:dataTable:notApplicable'),
      },
      {
        Header: t('common:fields:public').toString(),
        id: 'public',
        renderAs: 'boolean',
      },
      {
        Header: t('common:fields:createdAt').toString(),
        id: 'created_at',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:updatedAt').toString(),
        id: 'updated_at',
        renderAs: 'timestamp',
      },
    ],
    [t]
  )

  // TODO: Add filtering by location
  const filterFields: FilterField[] = React.useMemo(
    () => [
      {
        label: t('common:fields:name'),
        name: 'name',
        variant: 'text',
      },
      {
        label: t('common:fields:public'),
        name: 'public',
        variant: 'boolean',
        valueEditorType: 'checkbox',
      },
      {
        label: t('common:fields:createdAt'),
        name: 'created_at',
        variant: 'datetime',
      },
      {
        label: t('common:fields:updatedAt'),
        name: 'updated_at',
        variant: 'datetime',
      },
    ],
    [t]
  )

  const handleAdd = React.useCallback(() => {
    setCurrentTrack(emptyTrack)
    setCurrentMode('add')
    onEditorOpen()
  }, [onEditorOpen])

  const handleEdit = React.useCallback(
    (item: Track) => {
      setCurrentTrack(item)
      setCurrentMode('update')
      onEditorOpen()
    },
    [onEditorOpen]
  )

  const handleDelete = React.useCallback(
    (item: Track) => {
      setCurrentTrack(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  const actions = React.useMemo(
    () => [manageLayoutsAction, editAction(handleEdit), deleteAction(handleDelete)],
    [handleEdit, handleDelete]
  )

  return (
    <Box>
      <Heading fontSize="xl" mb={4}>
        {t('admin:menu:tracks')}
      </Heading>
      <DataTable<Track>
        columns={columns}
        defaultSelectedColumns={defaultSelectedColumns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        loading={loading}
        onRefresh={fetch}
        onAdd={handleAdd}
        filterFields={filterFields}
      />
      <TrackEditorModal
        item={currentTrack}
        open={isEditorOpen}
        onClose={onEditorClose}
        onComplete={fetch}
        mode={currentMode}
      />
      <DeleteResourceModal
        table="tracks"
        id={currentTrack.id}
        open={isDeleteOpen}
        onClose={onDeleteClose}
        onComplete={fetch}
      />
    </Box>
  )
}

export default Tracks
