import {add} from 'date-fns'

import {EventClassAssignment} from '@/api/models'

export const emptyEventClassAssignment: EventClassAssignment = {
  id: '',
  created_at: new Date(),
  updated_at: new Date(),
  class_id: '',
  event_id: '',
  event_name: '',
  participant_id: '',
  participant_number: 0,
  participant_name: '',
  car_id: '',
  car_name: '',
  start_time: new Date(),
  end_time: add(new Date(), {hours: 1}),
}
