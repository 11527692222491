import {User} from '@/api/models'
import i18n from '@/i18n'

import {UserRoleItem} from '../types'

export const userRoleItems: UserRoleItem[] = [
  {
    label: i18n.t('user:roles:admin'),
    name: 'admin',
  },
  {
    label: i18n.t('user:roles:user'),
    name: 'user',
  },
]

export const emptyUser: User = {
  id: '',
  email: '',
  avatar_url: '',
  role: 'user',
  assigned_organizers: [],
}
