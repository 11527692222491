import {EditorMode} from '@/admin/types'
import {TrackLayout} from '@/api/models'

export const inputToUpsertArgs = (input: TrackLayout) => ({
  track_layout_id: input.id,
  track_id: input.track_id,
  name: input.name,
  length: input.length,
  description: input.description,
  image: input.image,
})
