import * as React from 'react'

import {Text, Flex, Divider, Stack, Button, useToast} from '@chakra-ui/react'
import type {Provider} from '@supabase/supabase-js'
import {useTranslation} from 'react-i18next'
import {FaFacebook} from 'react-icons/fa'
import {FcGoogle} from 'react-icons/fc'

import {supabase} from '@/api'
import {REACT_APP_WEB_BASE_URL} from '@/api/constants'

const SocialSignIn = ({isDisabled}: {isDisabled: boolean}) => {
  const {t} = useTranslation()
  const toast = useToast()
  const [providerLoading, setProviderLoading] = React.useState<Provider | null>(null)

  const handleProvider = React.useCallback(
    async (provider: Provider) => {
      try {
        setProviderLoading(provider)
        const {error} = await supabase.auth.signIn(
          {
            provider,
          },
          {
            redirectTo: REACT_APP_WEB_BASE_URL + '/auth/callback',
          }
        )
        if (error) {
          throw new Error(error.message)
        }
      } catch (e) {
        setProviderLoading(null)
        toast({
          description: (e as Error).toString(),
          isClosable: true,
          status: 'error',
          title: t('auth:signin:failed'),
        })
      }
    },
    [toast, t]
  )
  const handleGoogle = React.useCallback(() => handleProvider('google'), [handleProvider])
  const handleFacebook = React.useCallback(() => handleProvider('facebook'), [handleProvider])

  return (
    <>
      <Flex direction="row" alignItems="center">
        <Divider flex={1} />
        <Text fontSize="xs" mx={2} color="gray.200">
          {t('auth:signin:socialMediaDivider')}
        </Text>
        <Divider flex={1} />
      </Flex>
      <Stack spacing={4}>
        <Button
          leftIcon={<FcGoogle />}
          size="sm"
          onClick={handleGoogle}
          isLoading={providerLoading === 'google'}
          isDisabled={isDisabled || !!providerLoading}
        >
          Google
        </Button>
        <Button
          colorScheme="facebook"
          leftIcon={<FaFacebook />}
          size="sm"
          onClick={handleFacebook}
          isLoading={providerLoading === 'facebook'}
          isDisabled={isDisabled || !!providerLoading}
        >
          Facebook
        </Button>
      </Stack>
    </>
  )
}

export default SocialSignIn
