import {supabase} from '.'

export const getSignedURL = async (bucket: string, path: string) => {
  const {data, error} = await supabase.storage.from(bucket).createSignedUrl(path.substring(1), 300)
  if (error) throw error

  if (!data) throw new Error('No data returned')

  return data.signedURL
}

export const uploadFile = async (bucket: string, path: string, file?: File) => {
  if (!file) throw new Error('No file provided')

  const filename = `${Date.now()}.${file.name.slice(
    (Math.max(0, file.name.lastIndexOf('.')) || Infinity) + 1
  )}`
  const joinedPath = '/' + path + '/' + filename

  const {error} = await supabase.storage.from(bucket).upload(joinedPath, file, {upsert: true})
  if (error) throw error

  return joinedPath
}
