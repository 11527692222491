import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Input,
  Textarea,
  Select,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {EditorMode} from '@/admin/types'
import {EPCTag, AdminOrganizer, RPC} from '@/api/models'
import {useSupabaseQuery, useSupabaseRPC} from '@/common/hooks'
import {CommonModalProps} from '@/utils/types'

import {emptyEPCTag} from '../constants'
import {inputToUpsertArgs} from './utils'

const EPCTagEditorModal = ({
  onClose,
  open,
  item,
  onComplete,
  mode,
}: CommonModalProps & {item: EPCTag; mode: EditorMode}) => {
  const {t} = useTranslation()
  const [input, setInput] = React.useState(emptyEPCTag)
  const {loading: organizersLoading, data: organizers} = useSupabaseQuery<AdminOrganizer>({
    fields: '*',
    table: 'organizers',
    order: 'name',
  })
  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertEPCTag,
    params: inputToUpsertArgs(input),
    mode,
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
  }, [item, open])

  const handleInputChange = React.useCallback(
    ({
      target: {id, value},
    }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const isSubmitDisabled = React.useMemo(() => !input.epc || !input.organizer_id, [input])

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`admin:epcTags:form:addEPCTag`)
            // t(`admin:epcTags:form:updateEPCTag`)
            t(`admin:epcTags:form:${mode}EPCTag`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Stack spacing={4} align="center" width="100%">
              {/* TODO: maybe check if input is a valid epc */}
              {mode !== 'update' && (
                <FormControl id="epc" isDisabled={loading} isRequired={true}>
                  <FormLabel>EPC</FormLabel>
                  <Input value={input.epc ?? ''} onChange={handleInputChange} />
                </FormControl>
              )}
              <FormControl id="organizer_id" isDisabled={organizersLoading || loading} isRequired={true}>
                <FormLabel>{t('common:entities:organizer')}</FormLabel>
                <Select value={input.organizer_id ?? ''} onChange={handleInputChange}>
                  <option value="">{t('admin:epcTags:form:selectOrganizer')}</option>
                  {!organizersLoading &&
                    organizers?.map((o) => (
                      <option value={o.id} key={o.id}>
                        {o.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <FormControl id="description" isDisabled={loading}>
                <FormLabel>{t('common:fields:description')}</FormLabel>
                <Textarea resize="vertical" value={input.description ?? ''} onChange={handleInputChange} />
              </FormControl>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleRPC} isDisabled={isSubmitDisabled}>
              {t('common:actions:save')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EPCTagEditorModal
