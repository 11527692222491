import * as React from 'react'

import {Tr, Td, useDisclosure, Stack, Text, Tag, Flex} from '@chakra-ui/react'

import {SeasonRankingsParticipant, SeasonRankingsParticipantPosition} from '@/api/models'
import {useAppSelector} from '@/store'

import {selectRankingsEvents} from '../state'

const ParticipantRow = ({participant, index}: {participant: SeasonRankingsParticipant; index: number}) => {
  const events = useAppSelector(selectRankingsEvents)

  return (
    <Tr>
      <Td>{index}.</Td>
      <Td fontWeight="bold" fontSize="2xl">
        {participant.number}
      </Td>
      <Td>{participant.name}</Td>
      {events?.map((e) => (
        <Td key={e.id}>
          <RenderPositon eventID={e.id} positions={participant.positions} />
        </Td>
      ))}
      <Td>{participant.total}</Td>
    </Tr>
  )
}

const RenderPositon = ({
  eventID,
  positions,
}: {
  eventID: string
  positions: SeasonRankingsParticipantPosition[]
}) => {
  for (const p of positions) {
    if (eventID === p.event_id) {
      return <Text>{`${p.points} (#${p.position})`}</Text>
    }
  }

  return <Text>0</Text>
}

export default ParticipantRow
