import {EditorMode} from '@/admin/types'
import {EventClassAssignment} from '@/api/models'

export const inputToUpsertArgs = (input: EventClassAssignment, mode: EditorMode) => ({
  class_assignment_id: mode === 'update' ? input.id : undefined,
  event_id: input.event_id,
  class_id: input.class_id,
  car_id: input.car_id,
  participant_number: input.participant_number,
  start_time: input.start_time,
  end_time: input.end_time,
})
