import * as React from 'react'

import {Flag as FlagIcon, Clipboard as ClipboardIcon, Users as UsersIcon} from 'react-feather'

import {AdminSerie} from '@/api/models'
import {DataTableAction} from '@/common/data-table/types'
import i18n from '@/i18n'

export const manageEventsAction: DataTableAction<AdminSerie> = {
  icon: <FlagIcon size="1rem" />,
  id: 'events',
  to: (item: AdminSerie) => `/admin/events?filter=and(serie_id.eq.${item.id})`,
  tooltip: i18n.t('series:manageEvents'),
}

export const manageClassesAction: DataTableAction<AdminSerie> = {
  icon: <ClipboardIcon size="1rem" />,
  id: 'classes',
  to: (item: AdminSerie) => `/admin/serie-classes?filter=and(serie_id.eq.${item.id})`,
  tooltip: i18n.t('series:manageClasses'),
}

export const manageParticipantsAction: DataTableAction<AdminSerie> = {
  icon: <UsersIcon size="1rem" />,
  id: 'participants',
  to: (item: AdminSerie) => `/admin/participants?filter=and(serie_id.eq.${item.id})`,
  tooltip: i18n.t('series:manageParticipants'),
}
