import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Input,
  Textarea,
  Checkbox,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {EditorMode} from '@/admin/types'
import {Device, RPC} from '@/api/models'
import {PasswordInput} from '@/common/components'
import {useSupabaseRPC} from '@/common/hooks'
import {CommonModalProps} from '@/utils/types'

import {emptyDevice} from '../constants'
import {inputToUpsertArgs} from './utils'

const DeviceEditorModal = ({
  onClose,
  open,
  item,
  onComplete,
  mode,
}: CommonModalProps & {item: Device; mode: EditorMode}) => {
  const {t} = useTranslation()
  // if the editor is used from the organizer panel `eventID` will be set
  // organizer members can only add virtual devices
  const {eventID} = useParams()

  const [input, setInput] = React.useState(emptyDevice)
  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertDevice,
    params: inputToUpsertArgs(input, mode),
    mode,
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
  }, [item, open])

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handleVirtualChange = React.useCallback(
    ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) =>
      setInput((input) => ({...input, virtual: checked})),
    []
  )

  const isSubmitDisabled = React.useMemo(
    () => !input.name || !input.description || !input.username || !input.password,
    [input]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`admin:devices:form:addDevice`)
            // t(`admin:devices:form:updateDevice`)
            t(`admin:devices:form:${mode}Device`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Stack spacing={4} align="center" width="100%">
              {!eventID && (
                <FormControl id="virtual" isDisabled={loading}>
                  <Checkbox isChecked={input.virtual ?? false} onChange={handleVirtualChange}>
                    {t('admin:devices:fields:virtual')}
                  </Checkbox>
                </FormControl>
              )}
              <FormControl id="name" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:name')}</FormLabel>
                <Input value={input.name ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl id="description" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:description')}</FormLabel>
                <Textarea resize="vertical" value={input.description ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl id="username" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('admin:devices:fields:username')}</FormLabel>
                <Input value={input.username ?? ''} onChange={handleInputChange} />
              </FormControl>
              <PasswordInput
                id="password"
                label={true}
                inputProps={{
                  value: input.password ?? '',
                  onChange: handleInputChange,
                  isDisabled: loading,
                  isRequired: true,
                }}
              />
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleRPC} isDisabled={isSubmitDisabled}>
              {t('common:actions:save')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeviceEditorModal
