import {AdminSerieClass} from '@/api/models'

export const inputToUpsertArgs = (input: AdminSerieClass) => ({
  serie_class_id: input.id || undefined,
  name: input.name,
  serie_id: input.serie_id,
  description: input.description,
  short_description: input.short_description,
  priority: input.priority,
  hidden: input.hidden,
})
