import {BridgeSubscription, BridgeSubscriptionSource, BridgeSubscriptionStatus} from '@/api/models'
import i18n from '@/i18n'

export const subsciptionStatuses: {name: BridgeSubscriptionStatus; label: string}[] = [
  {
    name: 'active',
    label: i18n.t('admin:bridgeSubscriptions:fields:statuses:active'),
  },
  {
    name: 'paused',
    label: i18n.t('admin:bridgeSubscriptions:fields:statuses:paused'),
  },
  {
    name: 'completed',
    label: i18n.t('admin:bridgeSubscriptions:fields:statuses:completed'),
  },
]

export const emptyBridgeSubscription: BridgeSubscription = {
  id: '',
  created_at: new Date(),
  updated_at: new Date(),
  name: '',
  device_id: '',
  event_id: '',
  source: BridgeSubscriptionSource.Materialized,
  source_params: {url: ''},
  status: 'active',
  processed_laps: 0,
  generated_readouts: 0,
}

export const statusColor = {
  active: 'green',
  paused: 'orange',
  completed: 'gray',
}
