import * as React from 'react'

import {Button, FormControl, Input, Link, Stack, useToast} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {Link as RouterLink} from 'react-router-dom'

import {supabase} from '@/api'
import SocialSignInButtons from '@/auth/social-signin'
import {PasswordInput} from '@/common/components'
import {useLoadingState} from '@/common/hooks'

const Form = () => {
  const {t} = useTranslation()
  const toast = useToast()

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')

  const handleEmailChange = React.useCallback(
    ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => setEmail(value),
    []
  )
  const handlePasswordChange = React.useCallback(
    ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => setPassword(value),
    []
  )

  const isSubmitDisabled = React.useMemo(() => !email || !password, [email, password])

  const _handleSubmit = React.useCallback(async () => {
    if (!email || !password) {
      return
    }
    try {
      const {error} = await supabase.auth.signIn({email, password})
      if (error) throw error

      toast({isClosable: true, status: 'success', title: t('auth:signin:succeeded')})
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        status: 'error',
        title: t('auth:signin:failed'),
      })
    }
  }, [email, password, toast, t])
  const {loading, handleSubmit} = useLoadingState(_handleSubmit)

  return (
    <div>
      <Stack spacing={4}>
        <FormControl isRequired={true}>
          <Input
            type="email"
            placeholder={t('auth:signin:email')}
            variant="filled"
            value={email}
            onChange={handleEmailChange}
            isDisabled={loading}
          />
        </FormControl>
        <PasswordInput
          inputProps={{
            value: password,
            variant: 'filled',
            placeholder: t('auth:password'),
            isRequired: true,
            isDisabled: loading,
            onChange: handlePasswordChange,
          }}
        />
        <Stack spacing={4}>
          <Link color="blue.400" fontSize="sm">
            {t('auth:signin:forgotPassword')}
          </Link>
          <Button
            bg="blue.400"
            color="white"
            _hover={{
              bg: 'blue.500',
            }}
            size="sm"
            onClick={handleSubmit}
            isLoading={loading}
            isDisabled={isSubmitDisabled}
          >
            {t('auth:signin:signIn')}
          </Button>
          <Button
            colorScheme="gray"
            _hover={{
              bg: 'blue.500',
            }}
            size="sm"
            as={RouterLink}
            to="/auth/signup"
            isDisabled={loading}
          >
            {t('auth:signin:signUp')}
          </Button>
        </Stack>
        <SocialSignInButtons isDisabled={loading} />
      </Stack>
    </div>
  )
}

export default Form
