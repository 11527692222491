import * as React from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  ListItem,
  Checkbox,
  List,
  Flex,
  Spacer,
  ScaleFade,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {sentenceCase} from '@/utils/string'
import {CommonModalProps} from '@/utils/types'

type LabeledItem = {
  id: string
  label?: string
}

type Props = CommonModalProps & {
  items: LabeledItem[]
  initialValue: string[]
  onApply: (value: string[]) => void
}

const ColumnSelector = ({onClose, open, initialValue, items, onApply}: Props) => {
  const {t} = useTranslation()

  const [value, setValue] = React.useState(initialValue)
  React.useEffect(() => setValue(initialValue), [open, initialValue])

  const handleApplyClick = React.useCallback(() => {
    onApply(value)
    onClose()
  }, [onApply, value, onClose])

  const handleSelectAllClick = React.useCallback(() => setValue(items.map(({id}) => id)), [setValue, items])

  return (
    <Modal isOpen={open} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <Flex direction="row" alignItems="center">
          <ModalHeader whiteSpace="nowrap">{t('common:dataTable:columnSelector:header')}</ModalHeader>
          <Spacer />
          <ScaleFade in={value.length !== items.length}>
            <Button mr={12} variant="ghost" size="sm" onClick={handleSelectAllClick}>
              {t('common:dataTable:columnSelector:selectAll')}
            </Button>
          </ScaleFade>
          <ModalCloseButton mt={1.5} />
        </Flex>
        <ModalBody>
          <List>
            {items.map(({id, label}, index) => (
              <ListItem key={index} pt={1} pb={1} cursor="pointer">
                <Flex flexDirection="row" alignItems="center">
                  <Checkbox
                    isChecked={value.includes(id)}
                    onChange={({target: {checked}}) =>
                      setValue(checked ? [...value, id] : value.filter((v) => v !== id))
                    }
                    size="lg"
                    mr={2}
                  />
                  {label ?? sentenceCase(id)}
                </Flex>
              </ListItem>
            ))}
          </List>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={handleApplyClick} isDisabled={!value.length}>
            {t('common:actions:apply')}
          </Button>
          <Button variant="ghost" onClick={onClose}>
            {t('common:actions:close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ColumnSelector
