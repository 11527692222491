import React from 'react'

import {useToast} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {supabase} from '@/api'
import {FilterBuilderFn} from '@/api/models'

type Props<T extends {}> = {
  fields: string
  table: string
  autoFetch?: boolean
  itemsPerPage?: number
  pageNumber?: number
  order?: keyof T
  descending?: boolean
  finalize?: FilterBuilderFn
  filter?: FilterBuilderFn
}

const useSupabaseQuery = <T extends {}>({
  fields,
  table,
  autoFetch = true,
  itemsPerPage,
  pageNumber,
  order,
  descending,
  finalize,
  filter,
}: Props<T>) => {
  const {t} = useTranslation()
  const toast = useToast()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<Error | null>(null)
  const [data, setData] = React.useState<T[] | null>([])
  const [rows, setRows] = React.useState<number | null>(0)

  const fetch = React.useCallback(async () => {
    setLoading(true)
    try {
      let query = supabase.from<T>(table).select(fields, {count: 'exact'})

      if (itemsPerPage) {
        query = query.limit(itemsPerPage)
        if (pageNumber) {
          const offset = itemsPerPage * pageNumber
          query = query.range(offset, offset + itemsPerPage - 1) // Both start and end indices are inclusive
        }
      }

      if (order) {
        query = query.order(order, {ascending: !descending})
      }

      if (filter) {
        query = filter(query)
      }
      if (finalize) {
        query = finalize(query)
      }

      const {data, count, error} = await query
      if (error) throw error

      setData(data)
      setRows(count)
    } catch (e) {
      setError(e as Error)
      toast({
        description: (e as Error).message,
        isClosable: true,
        title: t('errors:resourcesFetchFail'),
        status: 'error',
      })
    } finally {
      setLoading(false)
    }
  }, [descending, filter, finalize, itemsPerPage, order, pageNumber, table, fields, t, toast])

  React.useEffect(() => {
    autoFetch && fetch()
  }, [descending, filter, itemsPerPage, order, pageNumber]) // eslint-disable-line

  return {data, error, fetch, loading, rows}
}

export const emptySupabaseQueryResult = {
  data: [],
  error: null,
  fetch: () => new Promise<void>((res) => res()),
  loading: true,
  rows: 0,
}

export default useSupabaseQuery
