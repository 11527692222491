import * as React from 'react'

import {Button, type ButtonProps} from '@chakra-ui/react'

import {
  BridgeSubscriptionBase,
  BridgeSubscriptionSource,
  BridgeSubscriptionStatus,
  ParticipantsSubscription,
  ReadoutsSubscription,
} from '@/api/models'

export const subsciptionStatuses: BridgeSubscriptionStatus[] = ['active', 'paused', 'completed']

const emptySubscriptionBase: BridgeSubscriptionBase = {
  id: '',
  created_at: new Date(),
  updated_at: new Date(),
  name: '',
  event_id: '',
  status: 'paused',
}

export const emptyCSVSubscription: ParticipantsSubscription = {
  ...emptySubscriptionBase,
  source: BridgeSubscriptionSource.CSV,
  source_params: {csv_source: ''},
}

export const emptySpreadsheetSubscription: ParticipantsSubscription = {
  ...emptySubscriptionBase,
  source: BridgeSubscriptionSource.Spreadsheet,
  source_params: {api_key: '', sheet_id: ''},
}

export const emptyCouchDBSubscription: ReadoutsSubscription = {
  ...emptySubscriptionBase,
  source: BridgeSubscriptionSource.CouchDB,
  source_params: {poll: false, database: '', url: ''},
  source_state: {last_rev: ''},
  device_id: '',
  processed_laps: 0,
  generated_readouts: 0,
}

export const emptyMaterializedSubscription: ReadoutsSubscription = {
  ...emptySubscriptionBase,
  source: BridgeSubscriptionSource.Materialized,
  source_params: {url: ''},
  device_id: '',
  processed_laps: 0,
  generated_readouts: 0,
}

export const SourceButton = ({
  source,
  currentSource,
  onClick,
  children,
  ...rest
}: Omit<ButtonProps, 'onClick'> & {
  children: React.ReactNode
  source: BridgeSubscriptionSource
  currentSource: BridgeSubscriptionSource
  onClick: (source: BridgeSubscriptionSource) => void
}) => {
  const handleClick = React.useCallback(() => {
    onClick(source)
  }, [source, onClick])

  return (
    <Button
      onClick={handleClick}
      variant={source === currentSource ? 'solid' : 'ghost'}
      colorScheme="blue"
      borderRadius="full"
      {...rest}
    >
      {children}
    </Button>
  )
}
