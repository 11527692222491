import {configureStore} from '@reduxjs/toolkit'
import type {ThunkAction, Action} from '@reduxjs/toolkit'
import {useDispatch, useSelector} from 'react-redux'
import type {TypedUseSelectorHook} from 'react-redux'

import {supabase} from '@/api'
import authReducer, {fetchUser} from '@/auth/state'
import eventReducer from '@/events/event-page/state'
import organizerReducer from '@/organizer/state'
import seasonRankingsReducer from '@/series/season-rankings/state'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    event: eventReducer,
    seasonRankings: seasonRankingsReducer,
    organizer: organizerReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

supabase.auth.onAuthStateChange(async (_, session) => {
  store.dispatch(fetchUser(session?.user ?? undefined))
})
