import * as React from 'react'

import {Users as UsersIcon, Download as DownloadIcon} from 'react-feather'

import {AdminEvent} from '@/api/models'
import {DataTableAction} from '@/common/data-table/types'
import i18n from '@/i18n'

export const classAssignmentsAction: DataTableAction<AdminEvent> = {
  icon: <UsersIcon size="1rem" />,
  id: 'class_assignments',
  to: (item: AdminEvent) => `/admin/events/${item.id}/class-assignments`,
  tooltip: i18n.t('admin:events:classAssignment'),
}

export const importParticipantsAction = <T extends {}>(onClick: (item: T) => void): DataTableAction<T> => ({
  icon: <DownloadIcon size="1rem" />,
  id: 'import_participants',
  onClick,
  tooltip: i18n.t('events:importParticipants'),
})
