import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {RPC, EventParticipant} from '@/api/models'
import {useSupabaseRPC} from '@/common/hooks'
import {CommonModalProps} from '@/utils/types'

import {emptyEventParticipant} from '../constants'
import {inputToUpsertArgs} from './utils'

type Props = {
  item: EventParticipant
}

const AddModal = ({item, open, onClose, onComplete}: CommonModalProps & Props) => {
  const {t} = useTranslation()
  const [input, setInput] = React.useState(emptyEventParticipant)
  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertEventParticipant,
    params: inputToUpsertArgs(input),
    mode: 'add',
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
  }, [item])

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const isSubmitDisabled = React.useMemo(() => !input.event_id || !input.participant_number, [input])

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('admin:eventClassAssignments:form:addParticipantLabel')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack px={2} py={2}>
            <Stack spacing={4} align="center" width="100%">
              <FormControl id="participant_number" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('events:participants:participantNumber')}</FormLabel>
                <Input value={input.participant_number ?? ''} type="number" onChange={handleInputChange} />
              </FormControl>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleRPC} isDisabled={isSubmitDisabled}>
              {t('common:actions:save')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AddModal
