import React from 'react'

import {HStack} from '@chakra-ui/layout'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/modal'
import {Button} from '@chakra-ui/react'
import {useToast} from '@chakra-ui/toast'
import {useTranslation} from 'react-i18next'

import {supabase} from '@/api'
import {useLoadingState} from '@/common/hooks'
import {CommonModalProps} from '@/utils/types'

type Props = CommonModalProps & {
  epc: string
}

const DeleteEPCTagModal = ({epc, open, onClose, onComplete}: Props) => {
  const {t} = useTranslation()
  const toast = useToast()

  const handleDelete = React.useCallback(async () => {
    try {
      if (!epc) {
        return
      }
      const {error} = await supabase.from('epc_tags').delete().match({epc})
      if (error) throw error

      toast({isClosable: true, status: 'success', title: t('common:dataTable:deleteSuccess')})
      onClose()
      onComplete && onComplete()
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        title: t('common:dataTable:deleteFail'),
        variant: 'error',
      })
    }
  }, [epc, onClose, onComplete, t, toast])
  const {loading, handleSubmit} = useLoadingState(handleDelete)

  return (
    <Modal isOpen={open} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('common:dataTable:deletePrompt')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t('common:dataTable:deleteMessage')}</ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleSubmit}>
              {t('common:actions:delete')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeleteEPCTagModal
