import {Device} from '@/api/models'

export const emptyDevice: Device = {
  id: '',
  created_at: new Date(),
  updated_at: new Date(),
  name: '',
  description: '',
  virtual: false,
  username: '',
  password: '',
}
