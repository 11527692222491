import * as React from 'react'

import {Box, Heading, useDisclosure} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {AdminSerie} from '@/api/models'
import {DeleteResourceModal} from '@/common/components'
import DataTable from '@/common/data-table'
import {Column} from '@/common/data-table/types'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import {FilterField} from '@/common/filter-builder/types'
import {useSupabaseQuery} from '@/common/hooks'

import {EditorMode} from '../types'
import {manageEventsAction, manageClassesAction, manageParticipantsAction} from './actions'
import {emptySerie} from './constants'
import SerieEditorModal from './editor-modal'

const defaultSelectedColumns = ['name', 'organizer_name', 'public', 'start_date', 'end_date']

const Series = () => {
  const {t} = useTranslation()
  const tableState = useTableState({sortBy: 'created_at'})
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [currentSerie, setCurrentSerie] = React.useState<AdminSerie>(emptySerie)
  const [currentMode, setCurrentMode] = React.useState<EditorMode>('add')
  const {loading, data, fetch, rows} = useSupabaseQuery<AdminSerie>({
    fields: '*',
    table: 'admin_series',
    order: tableState.sortBy.column as keyof AdminSerie,
    itemsPerPage: tableState.rowsPerPage,
    pageNumber: tableState.page,
    descending: tableState.descending,
    filter: tableState.filter,
  })

  const columns: Column<AdminSerie>[] = React.useMemo(
    () => [
      {
        Header: t('common:fields:id').toString(),
        id: 'id',
        renderAs: 'code',
        sortable: false,
      },
      {
        Header: t('common:fields:name').toString(),
        id: 'name',
      },
      {
        Header: t('common:entities:organizer').toString(),
        id: 'organizer_name',
      },
      {
        Header: t('common:fields:public').toString(),
        id: 'public',
        renderAs: 'boolean',
      },
      {
        Header: t('series:fields:startDate').toString(),
        id: 'start_date',
        renderAs: 'date',
      },
      {
        Header: t('series:fields:endDate').toString(),
        id: 'end_date',
        renderAs: 'date',
      },
      {
        Header: t('common:fields:createdAt').toString(),
        id: 'created_at',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:updatedAt').toString(),
        id: 'updated_at',
        renderAs: 'timestamp',
      },
    ],
    [t]
  )

  const filterFields: FilterField[] = React.useMemo(
    () => [
      {
        label: t('common:fields:name'),
        name: 'name',
        variant: 'text',
      },
      {
        label: t('common:entities:organizer'),
        name: 'organizer_name',
        variant: 'text',
      },
      {
        label: t('common:fields:public'),
        name: 'public',
        variant: 'boolean',
        valueEditorType: 'checkbox',
      },
      {
        label: t('series:fields:startDate'),
        name: 'start_date',
        variant: 'date',
      },
      {
        label: t('series:fields:endDate'),
        name: 'end_date',
        variant: 'date',
      },
      {
        label: t('common:fields:createdAt'),
        name: 'created_at',
        variant: 'datetime',
      },
      {
        label: t('common:fields:updatedAt'),
        name: 'updated_at',
        variant: 'datetime',
      },
    ],
    [t]
  )

  const handleAdd = React.useCallback(() => {
    setCurrentSerie(emptySerie)
    setCurrentMode('add')
    onEditorOpen()
  }, [onEditorOpen])

  const handleEdit = React.useCallback(
    (item: AdminSerie) => {
      setCurrentSerie(item)
      setCurrentMode('update')
      onEditorOpen()
    },
    [onEditorOpen]
  )

  const handleDelete = React.useCallback(
    (item: AdminSerie) => {
      setCurrentSerie(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  const actions = React.useMemo(
    () => [
      manageEventsAction,
      manageClassesAction,
      manageParticipantsAction,
      editAction(handleEdit),
      deleteAction(handleDelete),
    ],
    [handleEdit, handleDelete]
  )

  return (
    <Box>
      <Heading fontSize="xl" mb={4}>
        {t('admin:menu:series')}
      </Heading>
      <DataTable<AdminSerie>
        columns={columns}
        defaultSelectedColumns={defaultSelectedColumns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        loading={loading}
        onRefresh={fetch}
        onAdd={handleAdd}
        filterFields={filterFields}
      />
      <SerieEditorModal
        item={currentSerie}
        onClose={onEditorClose}
        open={isEditorOpen}
        onComplete={fetch}
        mode={currentMode}
      />
      <DeleteResourceModal
        table="series"
        id={currentSerie.id}
        onClose={onDeleteClose}
        open={isDeleteOpen}
        onComplete={fetch}
      />
    </Box>
  )
}

export default Series
