import * as React from 'react'

import {IconButton, Tooltip} from '@chakra-ui/react'
import {Link} from 'react-router-dom'

import {DataTableAction} from './types'

type Props<T extends {}> = {
  action: DataTableAction<T>
  item: T
}

const ActionButton = <T extends {}>({action, item}: Props<T>) => {
  const handleClick = React.useCallback(() => 'onClick' in action && action.onClick(item), [item, action])

  return (
    <Tooltip label={action.tooltip}>
      {'to' in action ? (
        <IconButton
          as={Link}
          to={action.to(item)}
          size="sm"
          aria-label="edit-button"
          onClick={'onClick' in action ? handleClick : undefined}
        >
          {action.icon}
        </IconButton>
      ) : (
        <IconButton
          size="sm"
          aria-label="edit-button"
          onClick={'onClick' in action ? handleClick : undefined}
        >
          {action.icon}
        </IconButton>
      )}
    </Tooltip>
  )
}

export default ActionButton
