import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Input,
  Select,
  FormErrorMessage,
  Checkbox,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import isURL from 'validator/lib/isURL'

import {EditorMode} from '@/admin/types'
import {BridgeSubscription, Event, RPC, Device, BridgeSubscriptionSource} from '@/api/models'
import {useSupabaseQuery, useSupabaseRPC} from '@/common/hooks'
import {CommonModalProps} from '@/utils/types'

import {emptyBridgeSubscription, subsciptionStatuses} from '../constants'
import {inputToUpsertArgs} from './utils'

const BridgeSubscriptionEditorModal = ({
  item,
  mode,
  open,
  onClose,
  onComplete,
}: CommonModalProps & {item: BridgeSubscription; mode: EditorMode}) => {
  const {t} = useTranslation()
  const [input, setInput] = React.useState(emptyBridgeSubscription)
  const {loading: eventsLoading, data: events} = useSupabaseQuery<Event>({
    fields: 'id, name',
    table: 'events',
    order: 'name',
  })
  const {loading: devicesLoading, data: devices} = useSupabaseQuery<Device>({
    fields: 'id, name',
    table: 'devices',
    order: 'name',
  })
  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertBridgeSubscription,
    params: inputToUpsertArgs(input, mode),
    mode,
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
  }, [item, open])

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handleSourceParamsChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement>) => {
      return setInput({...input, source_params: {...input.source_params, [id]: value}} as BridgeSubscription)
    },
    [input]
  )

  const handlePollChange = React.useCallback(
    ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) => {
      if (input.source !== BridgeSubscriptionSource.CouchDB) return
      setInput({...input, source_params: {...input.source_params, poll: checked}})
    },
    [input]
  )

  const isURLValid = React.useMemo(() => {
    switch (input.source) {
      case BridgeSubscriptionSource.CouchDB:
      case BridgeSubscriptionSource.Materialized:
        return !!input.source_params.url && isURL(input.source_params.url)
      case BridgeSubscriptionSource.CSV:
        return !!input.source_params.csv_source && isURL(input.source_params.csv_source)
    }
    return true
  }, [input])

  const areSourceParamsValid = React.useMemo(() => {
    switch (input.source) {
      case BridgeSubscriptionSource.CouchDB:
        return !!input.source_params.database && isURLValid && !!input.device_id
      case BridgeSubscriptionSource.Materialized:
        return isURLValid && !!input.device_id
      case BridgeSubscriptionSource.CSV:
        return isURLValid
      case BridgeSubscriptionSource.Spreadsheet:
        return !!input.source_params.api_key && !!input.source_params.sheet_id
    }
  }, [input, isURLValid])

  const isSubmitDisabled = React.useMemo(
    () =>
      !input.name ||
      !input.event_id ||
      !input.source ||
      !input.source_params ||
      !input.status ||
      !areSourceParamsValid,
    [input, areSourceParamsValid]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`admin:bridgeSubscriptions:form:addBridgeSubscription`)
            // t(`admin:bridgeSubscriptions:form:updateBridgeSubscription`)
            t(`admin:bridgeSubscriptions:form:${mode}BridgeSubscription`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Stack spacing={4} align="center" width="100%">
              <FormControl id="name" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:name')}</FormLabel>
                <Input value={input.name ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl id="event_id" isDisabled={eventsLoading || loading} isRequired={true}>
                <FormLabel>{t('common:entities:event')}</FormLabel>
                <Select value={input.event_id ?? ''} onChange={handleInputChange}>
                  <option value="">{t('admin:bridgeSubscriptions:form:selectEvent')}</option>
                  {!eventsLoading &&
                    events?.map((e) => (
                      <option value={e.id} key={e.id}>
                        {e.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <FormControl id="status" isDisabled={loading}>
                <FormLabel>{t('admin:bridgeSubscriptions:fields:status')}</FormLabel>
                <Select value={input.status} onChange={handleInputChange}>
                  {subsciptionStatuses.map((s) => (
                    <option key={s.name} value={s.name}>
                      {s.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl id="source" isDisabled={loading}>
                <FormLabel>{t('admin:bridgeSubscriptions:fields:source')}</FormLabel>
                <Select value={input.source} onChange={handleInputChange}>
                  {Object.entries(BridgeSubscriptionSource).map(([k, v]) => (
                    <option key={k} value={v}>
                      {k}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Stack w="100%">
                {(input.source === BridgeSubscriptionSource.Materialized ||
                  input.source === BridgeSubscriptionSource.CouchDB) && (
                  <>
                    <FormControl id="device_id" isDisabled={eventsLoading || loading} isRequired={true}>
                      <FormLabel>{t('common:entities:device')}</FormLabel>
                      <Select value={input.device_id ?? ''} onChange={handleInputChange}>
                        <option value="">{t('admin:bridgeSubscriptions:form:selectDevice')}</option>
                        {!devicesLoading &&
                          devices?.map((d) => (
                            <option value={d.id} key={d.id}>
                              {d.name}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl id="url" isDisabled={loading} isRequired={true} isInvalid={!isURLValid}>
                      <FormLabel>URL</FormLabel>
                      <Input value={input.source_params.url ?? ''} onChange={handleSourceParamsChange} />
                      <FormErrorMessage>{t('errors:invalidURL')}</FormErrorMessage>
                    </FormControl>
                    {input.source === BridgeSubscriptionSource.CouchDB && (
                      <>
                        <FormControl id="database" isDisabled={loading} isRequired={true}>
                          <FormLabel>{t('admin:bridgeSubscriptions:fields:database')}</FormLabel>
                          <Input
                            value={input.source_params.database ?? ''}
                            onChange={handleSourceParamsChange}
                          />
                        </FormControl>
                        <FormControl id="poll" isDisabled={loading}>
                          <Checkbox isChecked={input.source_params.poll} onChange={handlePollChange}>
                            Poll
                          </Checkbox>
                        </FormControl>
                      </>
                    )}
                  </>
                )}
                {input.source === BridgeSubscriptionSource.CSV && (
                  <FormControl id="csv_source" isDisabled={loading} isRequired={true} isInvalid={!isURLValid}>
                    <FormLabel>{t('admin:bridgeSubscriptions:fields:sourceCSV')}</FormLabel>
                    <Input value={input.source_params.csv_source ?? ''} onChange={handleSourceParamsChange} />
                    <FormErrorMessage>{t('errors:invalidURL')}</FormErrorMessage>
                  </FormControl>
                )}
                {input.source === BridgeSubscriptionSource.Spreadsheet && (
                  <>
                    <FormControl id="sheet_id" isDisabled={loading} isRequired={true}>
                      <FormLabel>Spreadsheet ID</FormLabel>
                      <Input value={input.source_params.sheet_id ?? ''} onChange={handleSourceParamsChange} />
                    </FormControl>
                    <FormControl id="api_key" isDisabled={loading} isRequired={true}>
                      <FormLabel>Google API key</FormLabel>
                      <Input value={input.source_params.api_key ?? ''} onChange={handleSourceParamsChange} />
                    </FormControl>
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleRPC} isDisabled={isSubmitDisabled}>
              {t('common:actions:save')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default BridgeSubscriptionEditorModal
