import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Input,
  Textarea,
  Select,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {EditorMode} from '@/admin/types'
import {ParticipantTag, Event, RPC} from '@/api/models'
import {TimeRangeInput} from '@/common/components'
import {useSupabaseQuery, useSupabaseRPC} from '@/common/hooks'
import {CommonModalProps} from '@/utils/types'

import {emptyParticipantTag} from '../constants'
import {inputToUpsertArgs} from './utils'

const ParticipantTagEditorModal = ({
  item,
  mode,
  open,
  onClose,
  onComplete,
}: CommonModalProps & {item: ParticipantTag; mode: EditorMode}) => {
  const {t} = useTranslation()
  const [input, setInput] = React.useState(emptyParticipantTag)
  const {loading: eventsLoading, data: events} = useSupabaseQuery<Event>({
    fields: '*',
    table: 'events',
    order: 'name',
  })
  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertParticipantTagByNumber,
    params: inputToUpsertArgs(input, mode),
    mode,
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
  }, [item, open])

  const handleInputChange = React.useCallback(
    ({
      target: {id, value},
    }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handleTimeChange = React.useCallback((id: string, value: Date) => {
    setInput((input) => ({...input, [id]: value}))
  }, [])

  const isTimeValid = React.useMemo(() => new Date(input.start_time) < new Date(input.end_time), [input])

  const isSubmitDisabled = React.useMemo(
    () => !input.epc || !input.participant_number || !input.event_id || !isTimeValid,
    [input, isTimeValid]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`participants:tags:form:addParticipantTag`)
            // t(`participants:tags:form:updateParticipantTag`)
            t(`participants:tags:form:${mode}ParticipantTag`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Stack spacing={4} align="center" width="100%">
              <FormControl id="epc" isDisabled={loading} isRequired={true}>
                <FormLabel>EPC</FormLabel>
                <Input value={input.epc ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl id="event_id" isDisabled={eventsLoading || loading} isRequired={true}>
                <FormLabel>{t('common:entities:event')}</FormLabel>
                <Select value={input.event_id ?? ''} onChange={handleInputChange}>
                  <option value="">{t('participants:tags:form:selectEvent')}</option>
                  {!eventsLoading &&
                    events?.map((e) => (
                      <option value={e.id} key={e.id}>
                        {e.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <FormControl id="participant_number" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('events:participants:participantNumber')}</FormLabel>
                <Input value={input.participant_number ?? ''} type="number" onChange={handleInputChange} />
              </FormControl>
              <FormControl id="description" isDisabled={loading}>
                <FormLabel>{t('common:fields:description')}</FormLabel>
                <Textarea resize="vertical" value={input.description ?? ''} onChange={handleInputChange} />
              </FormControl>
              <TimeRangeInput
                startTime={input.start_time}
                endTime={input.end_time}
                isDisabled={loading}
                onTimeChange={handleTimeChange}
              />
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleRPC} isDisabled={isSubmitDisabled}>
              {t('common:actions:save')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ParticipantTagEditorModal
