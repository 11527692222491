import {createContext} from 'react'

import {Ability, AbilityBuilder} from '@casl/ability'
import type {AnyAbility} from '@casl/ability'
import {createContextualCan} from '@casl/react'

import {User} from '@/api/models'

export const defineAbilitiesFor = (user?: User) => {
  const {can, rules} = new AbilityBuilder(Ability)

  if (!user?.id) {
    // logged out user
    can('access', 'LoggedOutRoute')
    return new Ability(rules)
  }

  // logged in user
  can('access', 'LoggedInRoute')

  // organizer member
  if (user.assigned_organizers) {
    for (const o of user.assigned_organizers) {
      can('access', `organizer/${o.id}`)
    }
  }

  // admin
  if (user.role === 'admin') {
    can('access', 'AdminPanel')
  }

  return new Ability(rules)
}

export const AbilityContext = createContext({} as AnyAbility)
export const Can = createContextualCan(AbilityContext.Consumer)
