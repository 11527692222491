import * as React from 'react'

import {CheckIcon, CloseIcon, EditIcon, DeleteIcon} from '@chakra-ui/icons'
import {FormControl, HStack, IconButton, Select, Stack, useDisclosure} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'

import {ParticipantAssignedClass, RPC, Car} from '@/api/models'
import {DeleteResourceModal, TimeRangeInput} from '@/common/components'
import {useSupabaseQuery, useSupabaseRPC} from '@/common/hooks'
import {EventContext} from '@/organizer/events/page'
import {isDurationActive} from '@/utils/time'

import {emptyAssignedClass} from './constants'
import {inputToUpsertArgs} from './utils'

type Props = {
  item: ParticipantAssignedClass
  participantID: string
  onClose?: () => void
  onComplete?: () => void
}

const ClassItem = ({item, participantID, onClose, onComplete}: Props) => {
  const {t} = useTranslation()
  const {event, classes} = React.useContext(EventContext)
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [input, setInput] = React.useState<ParticipantAssignedClass>(emptyAssignedClass)
  const [editing, setEditing] = React.useState(true)

  const cars = useSupabaseQuery<Car>({
    fields: '*',
    table: 'cars',
    autoFetch: false,
    order: 'name',
    filter: React.useCallback(
      (query: PostgrestFilterBuilder<Car>) => query.match({participant: participantID}),
      [participantID]
    ),
  })
  React.useEffect(() => {
    participantID && cars.fetch()
  }, [participantID]) // eslint-disable-line

  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertEventParticipantClass,
    params: inputToUpsertArgs(input, participantID, event.id),
    mode: item.class_assignment_id ? 'update' : 'add',
    onComplete,
    onClose,
  })

  React.useEffect(() => {
    item && setInput(item)
    item.class_assignment_id && setEditing(false)
  }, [item])

  const handleModeChange = React.useCallback(() => {
    if (editing) {
      item && setInput(item)
      !item.class_assignment_id && onClose && onClose()
    }
    setEditing((prev) => !prev)
  }, [item, editing, onClose])

  const handleInputChange = React.useCallback(
    ({target: {name, value}}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setInput((input) => ({...input, [name]: value}))
    },
    []
  )

  const handleTimeChange = React.useCallback((id: string, value: Date) => {
    setInput((input) => ({...input, [id]: value}))
  }, [])

  const isTimeValid = React.useMemo(() => new Date(input.start_time) < new Date(input.end_time), [input])

  const isSubmitDisabled = React.useMemo(
    () => !input.class_id || !participantID || !event?.id || !isTimeValid,
    [input, event, participantID, isTimeValid]
  )

  const isActive = React.useMemo(
    () => item.class_assignment_id && isDurationActive(item.start_time, item.end_time),
    [item]
  )

  return (
    <>
      <Stack
        mt={2}
        border="1px solid"
        borderColor="whiteAlpha.300"
        borderRadius="lg"
        bgColor="gray.800"
        overflow="hidden"
        boxShadow={isActive ? '0px 0px 4px 1px #48BB78' : 'none'}
      >
        <HStack
          w="100%"
          align="flex-end"
          bgColor="gray.900"
          p={2}
          borderBottom="1px solid"
          borderColor="whiteAlpha.300"
        >
          <FormControl>
            <Select
              name="class_id"
              placeholder={t('events:participants:form:selectClass')}
              value={input.class_id}
              onChange={handleInputChange}
              isDisabled={!editing || classes?.loading}
              _disabled={{opacity: 1}}
              iconSize={editing ? 'xl' : '0'}
              bgColor="whiteAlpha.200"
            >
              {classes?.data?.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <Select
              name="car_id"
              placeholder={
                cars.loading
                  ? t('common:loading')
                  : cars.data?.length
                  ? t('events:participants:form:selectCar')
                  : t('events:participants:form:noCarsAvailable')
              }
              value={input.car_id ?? ''}
              onChange={handleInputChange}
              isDisabled={!editing || cars.loading}
              _disabled={{opacity: 1}}
              iconSize={editing ? 'xl' : '0'}
              bgColor="whiteAlpha.200"
            >
              {cars.data?.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </Select>
          </FormControl>
          {editing ? (
            <>
              <IconButton
                aria-label="edit-class-assignment"
                icon={<CheckIcon />}
                onClick={handleRPC}
                isDisabled={isSubmitDisabled}
                isLoading={loading}
              />
              <IconButton
                aria-label="delete-class-assignment"
                icon={<CloseIcon />}
                onClick={handleModeChange}
                isDisabled={loading}
              />
            </>
          ) : (
            <>
              <IconButton aria-label="edit-class-assignment" icon={<EditIcon />} onClick={handleModeChange} />
              <IconButton aria-label="delete-class-assignment" icon={<DeleteIcon />} onClick={onDeleteOpen} />
            </>
          )}
        </HStack>
        <HStack w="100%" p={2} pt={0}>
          <TimeRangeInput
            startTime={input.start_time}
            endTime={input.end_time}
            isReadOnly={!editing}
            isRequired={editing}
            onTimeChange={handleTimeChange}
          />
        </HStack>
      </Stack>
      <DeleteResourceModal
        id={item.class_assignment_id}
        table="event_participant_classes"
        open={isDeleteOpen}
        onComplete={onComplete}
        onClose={onDeleteClose}
      />
    </>
  )
}

export default ClassItem
