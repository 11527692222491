import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Input,
  Checkbox,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {EditorMode} from '@/admin/types'
import {Car, RPC} from '@/api/models'
import {useSupabaseRPC} from '@/common/hooks'
import {CommonModalProps} from '@/utils/types'

import {emptyCar} from '../constants'
import {inputToUpsertArgs} from './utils'

const CarEditorModal = ({
  item,
  mode,
  open,
  onClose,
  onComplete,
}: CommonModalProps & {item: Car; mode: EditorMode}) => {
  const {t} = useTranslation()
  const [input, setInput] = React.useState(emptyCar)
  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertCar,
    params: inputToUpsertArgs(input, mode),
    mode,
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
  }, [item, open])

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handlePublicChange = React.useCallback(
    ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) =>
      setInput((input) => ({...input, public: checked})),
    []
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`participants:cars:form:addCar`)
            // t(`participants:cars:form:updateCar`)
            t(`participants:cars:form:${mode}Car`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack px={2} py={2}>
            <Stack spacing={4} align="center" width="100%">
              <FormControl id="name" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:name')}</FormLabel>
                <Input value={input.name ?? ''} onChange={handleInputChange} />
              </FormControl>
              {/* TODO: Select participant by serie and number */}
              <FormControl id="participant" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('admin:cars:fields:participantsId')}</FormLabel>
                <Input value={input.participant ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl id="public" isDisabled={loading}>
                <Checkbox isChecked={input.public ?? false} onChange={handlePublicChange}>
                  {t('common:fields:public')}
                </Checkbox>
              </FormControl>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleRPC} isDisabled={!input.name}>
              {t('common:actions:save')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CarEditorModal
