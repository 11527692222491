import * as React from 'react'

import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Text,
  Stack,
  Link,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {Link as RouterLink} from 'react-router-dom'

import {useAppSelector} from '@/store'

import {selectRankingsClasses, selectRankingsEvents} from '../state'
import ParticipantRow from './participant-row'

const EventLapTimes = () => {
  const {t} = useTranslation()
  const events = useAppSelector(selectRankingsEvents)
  const classes = useAppSelector(selectRankingsClasses)

  return (
    <Flex justifyContent="center">
      <Tabs w="100%" size="md" variant="soft-rounded" colorScheme="red">
        <TabList p={4} overflow="scroll hidden">
          {classes?.map((c) => (
            <Tab key={c.id} mr={2}>
              {c.name}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {classes?.map((c) => (
            <TabPanel key={c.id} p={0}>
              <TableContainer>
                <Table size="lg">
                  <Thead>
                    <Tr verticalAlign="bottom">
                      <Th w="1%">#</Th>
                      <Th w="1%">{t('events:table:number')}</Th>
                      <Th>{t('events:table:participant')}</Th>
                      {events?.map((e) => (
                        <Th key={e.id}>
                          <Stack>
                            <Link as={RouterLink} to={`/events/${e.id}`} color="red.300">
                              {e.name}
                            </Link>
                            <Text>{t('series:table:points')}</Text>
                          </Stack>
                        </Th>
                      ))}
                      <Th>{t('series:table:seasonPoints')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {c.participants.map((p, i) => (
                      <ParticipantRow key={p.id} participant={p} index={i + 1} />
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  )
}

export default EventLapTimes
