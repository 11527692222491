import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

import {supabase} from '@/api'
import {SerieSeasonRankings} from '@/api/models'
import {RootState} from '@/store'

export interface State {
  loading: boolean
  rankings?: SerieSeasonRankings
}

const initialState: State = {
  loading: true,
  rankings: undefined,
}

export const fetchRankings = createAsyncThunk(
  'seasonRankings/fetchRankings',
  async (id: string, {dispatch, getState}) => {
    const loading = (getState() as RootState).seasonRankings.loading
    if (!loading) {
      dispatch(setLoading(true))
    }

    const {data, error} = await supabase
      .from<SerieSeasonRankings>('serie_season_rankings')
      .select('*')
      .match({id})
      .single()
    if (error) throw error

    return data
  }
)

export const seasonRankingsSlice = createSlice({
  name: 'seasonRankings',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setRankings: (state, action: PayloadAction<SerieSeasonRankings | undefined>) => {
      state.rankings = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRankings.fulfilled, (state, action) => {
      state.loading = false
      state.rankings = action.payload ?? undefined
    })
    builder.addCase(fetchRankings.rejected, (state) => {
      state.loading = false
      state.rankings = undefined
    })
  },
})

export const {setLoading, setRankings} = seasonRankingsSlice.actions

export const selectRankings = (state: RootState) => state.seasonRankings.rankings
export const selectRankingsOrganizer = (state: RootState) => state.seasonRankings.rankings?.organizer
export const selectRankingsEvents = (state: RootState) => state.seasonRankings.rankings?.events
export const selectRankingsClasses = (state: RootState) => state.seasonRankings.rankings?.classes
export const selectRankingsLoading = (state: RootState) => state.seasonRankings.loading

export default seasonRankingsSlice.reducer
