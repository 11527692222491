import * as React from 'react'

import {Box, Flex, Image, Text, useColorModeValue} from '@chakra-ui/react'
import {FaLocationArrow, FaClock, FaTrophy, FaChevronRight} from 'react-icons/fa'

import {OldEvent} from '@/api/models'

const EventTile = ({event}: {event: OldEvent}) => {
  return (
    <Flex w="full" alignItems="center" justifyContent="center">
      <Box
        bg={useColorModeValue('white', 'gray.800')}
        maxW="sm"
        borderWidth="1px"
        rounded="lg"
        shadow="lg"
        position="relative"
        transition="box-shadow 0.3s"
        _hover={{
          cursor: 'pointer',
          shadow: 'dark-lg',
        }}
      >
        <Flex flex={1} justify="center" align="center" position="relative" w="full">
          <Image src={event.thumbnail} alt="Hello!" roundedTop="lg" />
          {!!event.series_icon && (
            <Image
              src={event.series_icon}
              maxWidth={20}
              maxHeight={20}
              position="absolute"
              top={4}
              right={4}
            />
          )}
          {!!event.track_icon && (
            <Image
              src={event.track_icon}
              maxWidth="100px"
              maxHeight="50px"
              position="absolute"
              bottom={4}
              left={4}
            />
          )}
        </Flex>

        <Flex p={4} direction="row">
          <Box flexGrow={1}>
            <Flex justifyContent="space-between" alignContent="center">
              <Text fontSize="md" fontWeight="semibold" as="h4" lineHeight="tight" noOfLines={1}>
                {event.name}
              </Text>
            </Flex>

            <Flex
              fontSize="sm"
              lineHeight="tight"
              alignItems="center"
              color={useColorModeValue('gray.500', 'whiteAlpha.600')}
            >
              <FaLocationArrow />
              <Text ml={2}>
                {event.track_name ?? 'Nieznany'}
                {!!event.track_length && ` (${event.track_length}m)`}
              </Text>
            </Flex>

            <Flex
              fontSize="sm"
              lineHeight="tight"
              alignItems="center"
              color={useColorModeValue('gray.500', 'whiteAlpha.600')}
            >
              <FaTrophy />
              <Text ml={2}>{event.series_name ?? 'Wydarzenie niezależne'}</Text>
            </Flex>

            <Flex
              fontSize="sm"
              lineHeight="tight"
              alignItems="center"
              color={useColorModeValue('gray.500', 'whiteAlpha.600')}
            >
              <FaClock />
              <Text ml={2}>{event.started_at}</Text>
            </Flex>
          </Box>
          <Flex alignItems="center" justifyContent="center" flexGrow={0} ml={4}>
            <FaChevronRight size={20} color={useColorModeValue('#A0AEC0', 'rgba(255, 255, 255, 0.5)')} />
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )
}

export default EventTile
