import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {EditorMode} from '@/admin/types'
import {RPC, EventClassAssignment, Car} from '@/api/models'
import {TimeRangeInput} from '@/common/components'
import {useSupabaseQuery, useSupabaseRPC} from '@/common/hooks'
import {CommonModalProps} from '@/utils/types'

import {emptyEventClassAssignment} from '../constants'
import {inputToUpsertArgs} from './utils'

type Props = {
  item: EventClassAssignment
  mode: EditorMode
}

const EditorModal = ({item, mode, open, onClose, onComplete}: CommonModalProps & Props) => {
  const {t} = useTranslation()
  const [input, setInput] = React.useState(emptyEventClassAssignment)
  const {loading: carsLoading, data: cars} = useSupabaseQuery<Car>({
    fields: 'id, name',
    table: 'cars',
    order: 'name',
  })
  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertEventParticipantClass,
    params: inputToUpsertArgs(input, mode),
    mode,
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
  }, [item])

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handleTimeChange = React.useCallback((id: string, value: Date) => {
    setInput((input) => ({...input, [id]: value}))
  }, [])

  const isTimeValid = React.useMemo(() => new Date(input.start_time) < new Date(input.end_time), [input])

  const isSubmitDisabled = React.useMemo(
    () => !input.event_id || !input.participant_number || !isTimeValid,
    [input, isTimeValid]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`admin:eventClassAssignments:form:addLabel`)
            // t(`admin:eventClassAssignments:form:updateLabel`)
            t(`admin:eventClassAssignments:form:${mode}Label`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack px={2} py={2}>
            <Stack spacing={4} align="center" width="100%">
              {mode !== 'update' && (
                <FormControl id="participant_number" isDisabled={loading} isRequired={true}>
                  <FormLabel>{t('admin:eventClassAssignments:fields:participantNumber')}</FormLabel>
                  <Input value={input.participant_number ?? ''} type="number" onChange={handleInputChange} />
                </FormControl>
              )}
              <FormControl id="car_id" isDisabled={carsLoading || loading} isRequired={true}>
                <FormLabel>{t('admin:eventClassAssignments:fields:car')}</FormLabel>
                <Select value={input.car_id ?? ''} onChange={handleInputChange}>
                  <option value="">{t('admin:eventClassAssignments:form:selectCar')}</option>
                  {!carsLoading &&
                    cars?.map((c) => (
                      <option value={c.id} key={c.id}>
                        {c.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <TimeRangeInput
                startTime={input.start_time}
                endTime={input.end_time}
                isDisabled={loading}
                onTimeChange={handleTimeChange}
              />
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleRPC} isDisabled={isSubmitDisabled}>
              {t('common:actions:save')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EditorModal
