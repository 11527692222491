import * as React from 'react'

import {Box, Heading, useDisclosure, Tag} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {User} from '@/api/models'
import DataTable from '@/common/data-table'
import {Column} from '@/common/data-table/types'
import useTableState from '@/common/data-table/use-table-state'
import {editAction} from '@/common/data-table/utils'
import {FilterField} from '@/common/filter-builder/types'
import {useSupabaseQuery} from '@/common/hooks'

import {emptyUser, userRoleItems} from './constants'
import UserRoleEditorModal from './editor-modal'

const defaultSelectedColumns = ['email', 'role']

const Users = () => {
  const {t} = useTranslation()
  const tableState = useTableState({sortBy: 'email'})
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const [currentUser, setCurrentUser] = React.useState<User>(emptyUser)
  const {loading, data, fetch, rows} = useSupabaseQuery<User>({
    fields: '*',
    table: 'users',
    order: tableState.sortBy.column as keyof User,
    itemsPerPage: tableState.rowsPerPage,
    pageNumber: tableState.page,
    descending: tableState.descending,
    filter: tableState.filter,
  })

  const usersData = React.useMemo(() => data ?? [], [data])

  const columns: Column<User>[] = React.useMemo(
    () => [
      {
        Header: t('common:fields:id').toString(),
        id: 'id',
        renderAs: 'code',
        sortable: false,
      },
      {
        Header: t('user:fields:email').toString(),
        id: 'email',
      },
      {
        Header: t('user:fields:role').toString(),
        id: 'role',
        renderer: ({role}: User) => <Tag>{role}</Tag>,
      },
    ],
    [t]
  )

  const filterFields: FilterField[] = React.useMemo(
    () => [
      {
        label: t('user:fields:email'),
        name: 'email',
        variant: 'text',
      },
      {
        label: t('user:fields:role'),
        name: 'role',
        variant: 'select',
        values: userRoleItems,
      },
    ],
    [t]
  )

  const handleEdit = React.useCallback(
    (item: User) => {
      setCurrentUser(item)
      onEditorOpen()
    },
    [setCurrentUser, onEditorOpen]
  )

  const actions = React.useMemo(() => [editAction(handleEdit)], [handleEdit])

  return (
    <Box>
      <Heading fontSize="xl" mb={4}>
        {t('admin:menu:users')}
      </Heading>
      <DataTable<User>
        columns={columns}
        defaultSelectedColumns={defaultSelectedColumns}
        data={usersData}
        loading={loading}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        filterFields={filterFields}
      />
      <UserRoleEditorModal
        item={currentUser}
        onClose={onEditorClose}
        open={isEditorOpen}
        onComplete={fetch}
      />
    </Box>
  )
}

export default Users
