import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

import {supabase} from '@/api'
import {EventJoined} from '@/api/models'
import {RootState} from '@/store'

export interface State {
  loading: boolean
  event?: EventJoined
}

const initialState: State = {
  loading: true,
  event: undefined,
}

export const fetchEvent = createAsyncThunk('event/fetchEvent', async (id: string, {dispatch, getState}) => {
  const loading = (getState() as RootState).event.loading
  if (!loading) {
    dispatch(setLoading(true))
  }

  const {data, error} = await supabase
    .from<EventJoined>('public_event_joined')
    .select('*')
    .match({id})
    .single()
  if (error) throw error

  return data
})

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setEvent: (state, action: PayloadAction<EventJoined | undefined>) => {
      state.event = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEvent.fulfilled, (state, action) => {
      state.loading = false
      state.event = action.payload ?? undefined
    })
    builder.addCase(fetchEvent.rejected, (state) => {
      state.loading = false
      state.event = undefined
    })
  },
})

export const {setLoading, setEvent} = eventSlice.actions

export const selectEvent = (state: RootState) => state.event.event
export const selectEventOrganizer = (state: RootState) => state.event.event?.organizer
export const selectEventLayout = (state: RootState) => state.event.event?.track_layout
export const selectEventClasses = (state: RootState) => state.event.event?.classes ?? []
export const selectEventParticipants = (state: RootState) => state.event.event?.participants ?? []
export const selectEventLoading = (state: RootState) => state.event.loading

export default eventSlice.reducer
