import * as React from 'react'

import {useToast} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {EditorMode} from '@/admin/types'
import {supabase} from '@/api'
import {RPC, SupabaseRPCResponse} from '@/api/models'

type Props = {
  fnName: RPC
  params: any
  mode: EditorMode
  onComplete?: () => void
  onClose?: () => void
}

const useSupabaseRPC = ({fnName, params, mode, onClose, onComplete}: Props) => {
  const {t} = useTranslation()
  const toast = useToast()
  const [loading, setLoading] = React.useState(false)

  // this function can be used in two ways:
  // 1) directly as a callback to element's `onClick` event - `params` will be used
  // 2) 'normally', the caller can specify the argument - `paramsInner`
  const handleRPC = React.useCallback(
    async (paramsInner?: any) => {
      setLoading(true)
      try {
        // check if paramsInner is not a button event
        const input = paramsInner && !('nativeEvent' in paramsInner) ? paramsInner : params
        const {data, error} = await supabase.rpc(fnName, input)
        if (error) throw error

        const response = data as unknown as SupabaseRPCResponse
        if ('error' in response) throw new Error(response.error)

        toast({
          isClosable: true,
          status: 'success',
          // t(`common:dataTable:addSuccess`)
          // t(`common:dataTable:updateSuccess`)
          title: t(`common:dataTable:${mode}Success`),
        })
        onComplete && onComplete()
        onClose && onClose()
      } catch (e) {
        toast({
          description: (e as Error).message,
          isClosable: true,
          status: 'error',
          // t(`common:dataTable:addFail`)
          // t(`common:dataTable:updateFail`)
          title: t(`common:dataTable:${mode}Fail`),
        })
      } finally {
        setLoading(false)
      }
    },
    [fnName, mode, params, onComplete, onClose, t, toast]
  )

  return {handleRPC, loading}
}

export default useSupabaseRPC
