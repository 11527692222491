import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

import {supabase} from '@/api'
import {OrganizerJoined} from '@/api/models'
import {RootState} from '@/store'

export interface State {
  loading: boolean
  organizer?: OrganizerJoined
}

const initialState: State = {
  loading: true,
  organizer: undefined,
}

export const fetchOrganizer = createAsyncThunk(
  'organizer/fetchOrganizer',
  async (id: string, {dispatch, getState}) => {
    const loading = (getState() as RootState).organizer.loading
    if (!loading) {
      dispatch(setLoading(true))
    }

    const {data, error} = await supabase
      .from<OrganizerJoined>('organizer_joined')
      .select('*')
      .match({id})
      .single()
    if (error) throw error

    return data
  }
)

export const organizerSlice = createSlice({
  name: 'organizer',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setOrganizer: (state, action: PayloadAction<OrganizerJoined | undefined>) => {
      state.organizer = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizer.fulfilled, (state, action) => {
      state.loading = false
      state.organizer = action.payload ?? undefined
    })
    builder.addCase(fetchOrganizer.rejected, (state) => {
      state.loading = false
      state.organizer = undefined
    })
  },
})

export const {setLoading, setOrganizer} = organizerSlice.actions

export const selectOrganizer = (state: RootState) => state.organizer.organizer
export const selectOrganizerLoading = (state: RootState) => state.organizer.loading

export default organizerSlice.reducer
