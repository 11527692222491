import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import {ParticipantAssignedClass} from '@/api/models'
import {CommonModalProps} from '@/utils/types'

import ClassItem from '../../editor-modal/classes-manager/class-item'

type Props = {
  item: ParticipantAssignedClass
  participantID: string
}

const AddModal = ({item, participantID, open, onClose, onComplete}: CommonModalProps & Props) => {
  const handleComplete = React.useCallback(() => {
    onComplete && onComplete()
    onClose()
  }, [onComplete, onClose])

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader />
        <ModalBody>
          <Stack spacing={4} align="stretch">
            <ClassItem item={item} participantID={participantID} onComplete={handleComplete} />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AddModal
