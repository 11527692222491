import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Input,
  Text,
  FormErrorMessage,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  useToast,
} from '@chakra-ui/react'
import {AlertTriangle as AlertIcon} from 'react-feather'
import {useTranslation} from 'react-i18next'
import isURL from 'validator/lib/isURL'

import {supabase} from '@/api'
import {REACT_APP_API_URL} from '@/api/constants'
import {useLoadingState} from '@/common/hooks'
import {CommonModalProps} from '@/utils/types'

const emptyInput = {
  csvSource: '',
  apiKey: '',
  sheetID: '',
  eventID: '',
}

const ImportParticipantsModal = ({id, open, onClose}: CommonModalProps & {id: string}) => {
  const {t} = useTranslation()
  const toast = useToast()
  const [source, setSource] = React.useState('csv')
  const [input, setInput] = React.useState(emptyInput)

  const importParticipants = React.useCallback(async () => {
    try {
      const data =
        source === 'csv'
          ? {csv_source: input.csvSource, event_id: id}
          : {api_key: input.apiKey, sheet_id: input.sheetID, event_id: id}

      const session = supabase.auth.session()
      if (!session) throw new Error('Log in to perform this action')

      const response = await fetch(REACT_APP_API_URL + '/import-drivers-' + source, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + session.access_token,
          'X-Consumer-Username': session.user?.email ?? '',
        },
        body: JSON.stringify(data),
      })
      const body = await response.json()

      if (!response.ok) {
        throw new Error(body.message)
      }

      setInput(emptyInput)
      toast({
        isClosable: true,
        status: 'success',
        title: t('admin:events:importParticipants:modal:importSuccess'),
      })
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        status: 'error',
        title: t('admin:events:importParticipants:modal:importFail'),
      })
    }
  }, [t, toast, source, id, input])
  const {loading, handleSubmit} = useLoadingState(importParticipants)

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handleSourceChangeCSV = React.useCallback(() => {
    setSource('csv')
  }, [])

  const handleSourceChangeSpreadsheet = React.useCallback(() => {
    setSource('spreadsheet')
  }, [])

  const isSubmitDisabled = React.useMemo(
    () => (source === 'csv' ? !input.csvSource || !isURL(input.csvSource) : !input.apiKey || !input.sheetID),
    [input, source]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('events:importParticipants')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs variant="soft-rounded">
            <TabList>
              <HStack>
                <Text>{t('admin:events:importParticipants:modal:source')}:</Text>
                <Tab onClick={handleSourceChangeCSV}>CSV</Tab>
                <Tab onClick={handleSourceChangeSpreadsheet}>Google Spreadsheet</Tab>
              </HStack>
            </TabList>
            <TabPanels>
              <TabPanel p="0">
                <Stack px={2} py={2}>
                  <Stack spacing={4} align="center" width="100%">
                    <FormControl
                      id="csvSource"
                      isDisabled={loading}
                      isRequired={true}
                      isInvalid={!!input.csvSource && !isURL(input.csvSource)}
                    >
                      <FormLabel>{t('admin:events:importParticipants:modal:sourceCSV')}</FormLabel>
                      <Input value={input.csvSource ?? ''} onChange={handleInputChange} />
                      <FormErrorMessage>{t('errors:invalidURL')}</FormErrorMessage>
                    </FormControl>
                  </Stack>
                  <HStack justifyContent="space-evenly"></HStack>
                </Stack>
              </TabPanel>
              <TabPanel p="0">
                <Stack px={2} py={2}>
                  <Stack spacing={4} align="center" width="100%">
                    <FormControl id="sheetID" isDisabled={loading} isRequired={true}>
                      <FormLabel>Spreadsheet ID</FormLabel>
                      <Input value={input.sheetID ?? ''} onChange={handleInputChange} />
                    </FormControl>
                    <FormControl id="apiKey" isDisabled={loading} isRequired={true}>
                      <FormLabel>Google API key</FormLabel>
                      <Input value={input.apiKey ?? ''} onChange={handleInputChange} />
                    </FormControl>
                  </Stack>
                  <HStack justifyContent="space-evenly"></HStack>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-evenly">
            <AlertIcon size="1rem" />
            <Text>{t('admin:events:importParticipants:modal:mightTakeAWhile')}</Text>
            <Button isLoading={loading} onClick={handleSubmit} isDisabled={isSubmitDisabled}>
              Import
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ImportParticipantsModal
