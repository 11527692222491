import * as React from 'react'

import {ColorModeScript, ChakraProvider, extendTheme} from '@chakra-ui/react'
import type {ThemeConfig} from '@chakra-ui/react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'

import {supabase} from '@/api'
import {AbilityContext, defineAbilitiesFor} from '@/auth/abilities'
import {selectProfile, fetchUser} from '@/auth/state'
import '@/i18n'
import Router from '@/router'
import {store, useAppSelector} from '@/store'
import theme from '@/theme'

import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/raleway'

const App = () => {
  const user = useAppSelector(selectProfile)
  const context = React.useMemo(() => defineAbilitiesFor(user), [user])

  React.useEffect(() => {
    const user = supabase.auth.user()
    !user && store.dispatch(fetchUser(undefined))
  }, [])

  return (
    <AbilityContext.Provider value={context}>
      <Router />
    </AbilityContext.Provider>
  )
}
const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
)
