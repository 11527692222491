import * as React from 'react'

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  type FormControlProps,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {readOnlyStyles} from '@/theme/components/input'
import {dateToInput} from '@/utils/string'

type Props = FormControlProps & {
  startTime: Date
  endTime: Date
  onTimeChange: (id: string, value: Date) => void
}

const TimeRangeInput = ({startTime, endTime, onTimeChange, ...rest}: Props) => {
  const {t} = useTranslation()

  const handleChange = React.useCallback(
    ({target: {name, value}}: React.ChangeEvent<HTMLInputElement>) => {
      onTimeChange(name, value ? new Date(value) : new Date())
    },
    [onTimeChange]
  )

  const startTimeInput = React.useMemo(() => dateToInput(new Date(startTime)), [startTime])
  const endTimeInput = React.useMemo(() => dateToInput(new Date(endTime)), [endTime])
  const isTimeValid = React.useMemo(() => new Date(startTime) < new Date(endTime), [startTime, endTime])

  return (
    <>
      <FormControl {...rest}>
        <FormLabel>{t('common:fields:startTime')}</FormLabel>
        <Input name="start_time" value={startTimeInput} onChange={handleChange} type="datetime-local" />
      </FormControl>
      <FormControl {...rest} isInvalid={!isTimeValid || rest.isInvalid}>
        <FormLabel>{t('common:fields:endTime')}</FormLabel>
        <Input name="end_time" value={endTimeInput} onChange={handleChange} type="datetime-local" />
        <FormErrorMessage>{t('errors:endTime')}</FormErrorMessage>
      </FormControl>
    </>
  )
}

export default TimeRangeInput
