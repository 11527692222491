import {EditorMode} from '@/admin/types'
import {ParticipantTag} from '@/api/models'

export const inputToUpsertArgs = (input: ParticipantTag, mode: EditorMode) => ({
  participant_tag_id: mode === 'update' ? input.id : undefined,
  epc: input.epc,
  event_id: input.event_id,
  participant_number: input.participant_number,
  start_time: input.start_time,
  end_time: input.end_time,
  description: input.description || undefined,
})
