import * as React from 'react'

import {Flex, Spinner} from '@chakra-ui/react'
import {Navigate, Outlet, useLocation, useParams, type Params} from 'react-router-dom'

import {AbilityContext} from '@/auth/abilities'
import {selectProfileLoading} from '@/auth/state'
import {useAppSelector} from '@/store'

type ProtectedProps = {
  route?: string | ((params: Readonly<Params<string>>) => string)
}

const RequireAuth = ({route}: ProtectedProps) => {
  const loading = useAppSelector(selectProfileLoading)
  const ability = React.useContext(AbilityContext)

  const {pathname} = useLocation()
  const params = useParams()

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" mt={8} mb={8}>
        <Spinner size="lg" />
      </Flex>
    )
  }

  // if user is logged out redirect him to the sign in page,
  // he will be redirected back to the page he tried to access (considering he has the privilege) after signing in.
  if (ability.cannot('access', 'LoggedInRoute')) {
    return <Navigate to={{pathname: '/auth/signin', search: `?redirectTo=${pathname}`}} />
  }

  // if a logged in user tries to access a route he shouldn't redirect him to the homepage
  if (
    typeof route === 'string'
      ? ability.cannot('access', route)
      : route && params && ability.cannot('access', route(params))
  ) {
    return <Navigate to={{pathname: '/'}} replace={true} />
  }

  return <Outlet />
}

export default RequireAuth
