import React, {useCallback, useMemo} from 'react'

import {DeleteIcon} from '@chakra-ui/icons'
import {Avatar, Box, Button, Flex, Stack} from '@chakra-ui/react'
import type {AvatarProps} from '@chakra-ui/react'
import {Image as ImageIcon} from 'react-feather'
import ImageUploading, {ImageListType, ImageType} from 'react-images-uploading'

const onHoverCSS = {'#image-overlay': {opacity: 1}}
const defaultAvatarSize = '70px'

type Props = {
  onChange: (value: ImageType | undefined) => void
  value?: ImageType
  readonly?: boolean
  size?: AvatarProps['boxSize']
}

// TODO: loading state
const AvatarUpload = ({value, onChange, readonly, size}: Props) => {
  const onImageChange = useCallback(
    (imageList: ImageListType) => {
      imageList.length && onChange(imageList[0])
    },
    [onChange]
  )

  const onImageRemove = useCallback(() => {
    onChange(undefined)
  }, [onChange])

  const values = useMemo(() => (value ? [value] : []), [value])

  return (
    <ImageUploading multiple={false} value={values} onChange={onImageChange} maxNumber={1}>
      {({onImageUpload, isDragging, dragProps}) => (
        <Flex justifyContent="flex-start">
          {value?.dataURL ? (
            <Stack align="center">
              <Box position="relative" _hover={onHoverCSS} borderRadius="50%">
                <Avatar src={value.dataURL} boxSize={size || defaultAvatarSize} />
                {!readonly && (
                  <Flex
                    id="image-overlay"
                    transition="100ms"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    top={0}
                    opacity={0}
                    backgroundColor="blackAlpha.600"
                    height={size || defaultAvatarSize}
                    width={size || defaultAvatarSize}
                    borderRadius="50%"
                    onClick={onImageRemove}
                  >
                    <DeleteIcon color="white" w={5} h={5} />
                  </Flex>
                )}
              </Box>
            </Stack>
          ) : !readonly ? (
            <Button
              colorScheme={isDragging ? 'teal' : 'gray'}
              onClick={onImageUpload}
              {...dragProps}
              height={size || defaultAvatarSize}
              width={size || defaultAvatarSize}
              borderRadius="50%"
            >
              <ImageIcon />
            </Button>
          ) : (
            <Avatar boxSize={size || defaultAvatarSize} />
          )}
        </Flex>
      )}
    </ImageUploading>
  )
}

export default AvatarUpload
