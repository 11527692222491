import * as React from 'react'

import {Flex, Text, Image, Link, useColorModeValue, useBreakpointValue} from '@chakra-ui/react'
import {Link as RouterLink} from 'react-router-dom'

import logo from './logo.svg'

const Logo = () => {
  return (
    <Link as={RouterLink} to="/" _hover={{textDecoration: 'none'}}>
      <Flex alignItems="center" direction="row" fontSize="xl" mr={20}>
        <Image src={logo} alt="Logo" maxHeight="32px" />
        <Text
          textAlign={useBreakpointValue({base: 'center', md: 'left'})}
          fontFamily="Raleway"
          fontWeight="700"
          pl={2}
          color={useColorModeValue('gray.800', 'white')}
        >
          hotlap&nbsp;club
        </Text>
      </Flex>
    </Link>
  )
}

export default Logo
