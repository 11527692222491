import * as React from 'react'

import {Clock as ClockIcon, DollarSign as DollarSignIcon} from 'react-feather'

import {Device} from '@/api/models'
import {DataTableAction} from '@/common/data-table/types'
import i18n from '@/i18n'

export const eventAssignmentsAction: DataTableAction<Device> = {
  icon: <ClockIcon size="1rem" />,
  id: 'event_assignments',
  to: (item: Device) => `/admin/device-assignments?filter=and(device_id.eq.${item.id})`,
  tooltip: i18n.t('admin:devices:eventAssignments'),
}

export const rentsAction: DataTableAction<Device> = {
  icon: <DollarSignIcon size="1rem" />,
  id: 'rents',
  to: (item: Device) => `/admin/rented-devices?filter=and(device_id.eq.${item.id})`,
  tooltip: i18n.t('admin:devices:rents'),
}
