import * as React from 'react'

import {EditIcon, DeleteIcon} from '@chakra-ui/icons'
import {HStack, Text, GridItem, Grid, Button, Flex, Tooltip} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {Participant} from '@/api/models'

type Props = {
  p: Participant
  onEdit: (p: Participant) => void
  onDelete: (p: Participant) => void
}

const ParticipantListItem = ({p, onEdit, onDelete}: Props) => {
  const {t} = useTranslation()

  const handleDelete = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      onDelete && onDelete(p)
    },
    [onDelete, p]
  )

  const handleEdit = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      onEdit && onEdit(p)
    },
    [onEdit, p]
  )

  return (
    <Flex direction="column" position="sticky" top={28}>
      <Grid
        p={2}
        w="100%"
        templateColumns="80px repeat(3, 1fr) 90px"
        gap={2}
        bgColor="gray.900"
        borderRadius="xl"
        zIndex="base"
      >
        <Flex align="center" justify="center">
          <Text fontWeight="bold" fontSize="lg">
            {p.number}
          </Text>
        </Flex>
        <Flex align="center">
          <Text>{p.name}</Text>
        </Flex>
        <Flex align="center">
          <Text>{p.first_name}</Text>
        </Flex>
        <Flex align="center">
          <Text color={p.user_email ? '#fff' : 'whiteAlpha.300'}>
            {p.user_email ? p.user_email : t('common:notProvided')}
          </Text>
        </Flex>
        <GridItem>
          <HStack w="100%" justify="flex-end">
            <Tooltip label={t('common:actions:edit')}>
              <Button onClick={handleEdit} size="sm" p={1}>
                <EditIcon />
              </Button>
            </Tooltip>
            <Tooltip label={t('common:actions:delete')}>
              <Button onClick={handleDelete} size="sm" p={1}>
                <DeleteIcon />
              </Button>
            </Tooltip>
          </HStack>
        </GridItem>
      </Grid>
    </Flex>
  )
}

export default ParticipantListItem
