import {TrackLayout} from '@/api/models'

export const emptyTrackLayout: TrackLayout = {
  id: '',
  track_id: '',
  created_at: new Date(),
  updated_at: new Date(),
  name: '',
  length: 0,
  description: '',
  image: undefined,
}
