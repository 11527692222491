import {EditorMode} from '@/admin/types'
import {BridgeSubscription, BridgeSubscriptionSource, CouchDBSubscription} from '@/api/models'

export const inputToUpsertArgs = (input: BridgeSubscription, mode: EditorMode) => ({
  bridge_subscription_id: mode === 'update' ? input.id : undefined,
  name: input.name,
  device_id:
    input.source === BridgeSubscriptionSource.CouchDB ||
    input.source === BridgeSubscriptionSource.Materialized
      ? input.device_id
      : undefined,
  event_id: input.event_id,
  source: input.source,
  source_params: pickSourceParams(input),
  source_state: (input.source === BridgeSubscriptionSource.CouchDB && input.source_state) || {},
  status: input.status,
})

export const pickSourceParams = (sub: BridgeSubscription) => {
  switch (sub.source) {
    case BridgeSubscriptionSource.Materialized:
      return {url: sub.source_params.url}
    case BridgeSubscriptionSource.CouchDB:
      return {
        url: sub.source_params.url,
        database: sub.source_params.database ?? '',
        poll: !!sub.source_params.poll,
      }
    case BridgeSubscriptionSource.CSV:
      return {csv_source: sub.source_params.csv_source}
    case BridgeSubscriptionSource.Spreadsheet:
      return {api_key: sub.source_params.api_key, sheet_id: sub.source_params.sheet_id}
    default:
      return undefined
  }
}
