import React from 'react'

import {type ValueEditorProps} from 'react-querybuilder'

import DatetimeInput from '@/common/components/datetime-input'

const DatetimeValueEditor = ({handleOnChange}: ValueEditorProps) => {
  const handleChangeDatetime = React.useCallback(
    (dt: Date | null) => handleOnChange(dt ? dt.toISOString() : ''),
    [handleOnChange]
  )

  const now = React.useMemo(() => new Date(), [])

  React.useEffect(() => {
    handleOnChange(new Date().toISOString())
  }, []) // eslint-disable-line

  return (
    <DatetimeInput
      showTimeSelect={true}
      defaultValue={now}
      onChange={handleChangeDatetime}
      borderRadius="lg"
      mr={2}
      size="sm"
    />
  )
}

export default DatetimeValueEditor
