import * as React from 'react'

import {Tr, Td, useDisclosure, Stack, Text, Tag, Flex} from '@chakra-ui/react'

import {EventViewParticipant} from '@/api/models'
import {formatMilliseconds, formatTimeOfDay} from '@/utils/string'

import {mcPositionToPoints} from './utils'

const ParticipantRow = ({participant, index}: {participant: EventViewParticipant; index: number}) => {
  const {getDisclosureProps, getButtonProps} = useDisclosure()

  const buttonProps = getButtonProps()
  const disclosureProps = getDisclosureProps()
  return (
    <>
      <Tr {...buttonProps} cursor="pointer">
        <Td>{index}.</Td>
        <Td fontWeight="bold" fontSize="2xl">
          {participant.number}
        </Td>
        <Td>{participant.name}</Td>
        <Td>
          <Text fontWeight="bold" color="green.300">
            Ø {formatMilliseconds(participant.avg)}
          </Text>
        </Td>
        <Td>
          {participant.best3?.map((lt) => (
            <Tag key={lt} mr={2} py={2} px={4} borderRadius="full" variant="subtle">
              {formatMilliseconds(lt)}
            </Tag>
          ))}
        </Td>
        <Td>{mcPositionToPoints(index)}</Td>
      </Tr>
      <Tr {...disclosureProps}>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td>
          <Stack>
            {participant.lap_times?.map((lt, i) => (
              <Flex key={lt.lap_time + i}>
                <Text mr={2}>{formatMilliseconds(lt.lap_time)}</Text>
                <Text color="whiteAlpha.500">{`at ${formatTimeOfDay(lt.created_at)}`}</Text>
              </Flex>
            ))}
          </Stack>
        </Td>
        <Td></Td>
      </Tr>
    </>
  )
}

export default ParticipantRow
