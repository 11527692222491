import * as React from 'react'

import {AddIcon, CloseIcon, RepeatIcon, CheckIcon} from '@chakra-ui/icons'
import {
  HStack,
  IconButton,
  Heading,
  useDisclosure,
  Collapse,
  Flex,
  Skeleton,
  Checkbox,
  Tooltip,
  Text,
  RadioGroup,
  Stack,
  Radio,
  Box,
} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {GitMerge as MergeIcon, AlertTriangle as AlertIcon} from 'react-feather'
import {useTranslation} from 'react-i18next'

import {emptyCar} from '@/admin/cars/constants'
import {Car, RPC} from '@/api/models'
import {useSupabaseQuery, useSupabaseRPC} from '@/common/hooks'

import CarItem from './car-item'

const CarsManager = ({participantID}: {participantID: string}) => {
  const {t} = useTranslation()
  const {isOpen, onToggle, onClose} = useDisclosure()
  const [merging, setMerging] = React.useState(false)
  const [carsToMerge, setCarsToMerge] = React.useState<string[]>([])
  const [mergeInto, setMergeInto] = React.useState('')

  const {
    loading,
    data: cars,
    fetch,
  } = useSupabaseQuery<Car>({
    fields: '*',
    table: 'cars',
    order: 'created_at',
    descending: true,
    filter: React.useCallback(
      (b: PostgrestFilterBuilder<Car>) => b.eq('participant', participantID),
      [participantID]
    ),
  })

  const onMergeComplete = React.useCallback(() => {
    setMerging(false)
    setMergeInto('')
    setCarsToMerge([])
    fetch()
  }, []) // eslint-disable-line

  const {handleRPC: handleMerge, loading: mergeLoading} = useSupabaseRPC({
    fnName: RPC.MergeCars,
    params: {merge_into: mergeInto, cars_to_merge: carsToMerge},
    mode: 'update',
    onComplete: onMergeComplete,
  })

  const toggleMerging = React.useCallback(() => {
    if (merging) {
      setCarsToMerge([])
      setMergeInto('')
    }
    setMerging((prev) => !prev)
  }, [merging])

  const handleCarCheck = React.useCallback(
    ({target: {name, checked}}: React.ChangeEvent<HTMLInputElement>) => {
      setCarsToMerge((prev) => (checked ? prev.concat(name) : prev.filter((s) => s !== name)))
    },
    []
  )

  const handleMergeIntoChange = React.useCallback((id: string) => {
    setMergeInto(id)
    setCarsToMerge((prev) => prev.filter((s) => s !== id))
  }, [])

  const isMergeDisabled = React.useMemo(() => !mergeInto || !carsToMerge.length, [mergeInto, carsToMerge])

  return (
    <Flex direction="column" w="100%">
      <HStack>
        <Heading size="md">{t('events:participants:form:manageCars')}</Heading>
        {!merging ? (
          <>
            {' '}
            <IconButton
              aria-label="add-car"
              icon={isOpen ? <CloseIcon /> : <AddIcon />}
              onClick={onToggle}
              size="sm"
              isDisabled={loading}
            />
            <IconButton
              aria-label="refetch-car"
              icon={<RepeatIcon />}
              onClick={fetch}
              size="sm"
              isLoading={loading}
            />
            <Tooltip label={t('participants:cars:merge')}>
              <IconButton
                aria-label="merge-cars"
                size="sm"
                icon={<MergeIcon size="16px" />}
                onClick={toggleMerging}
                isDisabled={loading}
              />
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip label={t('participants:cars:merge')}>
              <IconButton
                aria-label="merge"
                icon={<CheckIcon />}
                size="sm"
                onClick={handleMerge}
                isDisabled={isMergeDisabled}
                isLoading={mergeLoading}
              />
            </Tooltip>
            <Tooltip label={t('common:actions:cancel')}>
              <IconButton
                aria-label="cancel-merge"
                icon={<CloseIcon />}
                size="sm"
                onClick={toggleMerging}
                isDisabled={mergeLoading}
              />
            </Tooltip>
          </>
        )}
      </HStack>
      {loading ? (
        <>
          <Skeleton h="90px" mt={2} borderRadius="lg" />
          <Skeleton h="90px" mt={2} borderRadius="lg" />
        </>
      ) : (
        <>
          {/* MERGING TOOLTIP */}
          <Collapse in={merging}>
            <HStack mt={2} spacing={4}>
              <HStack spacing={2}>
                <Radio isChecked={true} isDisabled={true} />
                <Text>{t('participants:cars:mergeInto')}</Text>
              </HStack>
              <HStack spacing={2}>
                <Checkbox isChecked={true} isDisabled={true} />
                <Text>{t('participants:cars:toBeMerged')}</Text>
              </HStack>
            </HStack>
          </Collapse>
          {/* ADD NEW CAR */}
          <Collapse in={isOpen} unmountOnExit={true}>
            <Box mt={2}>
              <CarItem car={emptyCar} participantID={participantID} onComplete={fetch} onClose={onClose} />
            </Box>
          </Collapse>
          {/* CARS LIST */}
          <RadioGroup onChange={handleMergeIntoChange} value={mergeInto}>
            {cars?.map((c, i) => (
              <HStack key={i} mt={2}>
                {merging && (
                  <Stack>
                    <Radio value={c.id} />
                    {mergeInto !== c.id && (
                      <Checkbox
                        name={c.id}
                        isChecked={!!carsToMerge.find((s) => s === c.id)}
                        onChange={handleCarCheck}
                      />
                    )}
                  </Stack>
                )}
                <CarItem car={c} participantID={participantID} onComplete={fetch} />
              </HStack>
            ))}
          </RadioGroup>
        </>
      )}
    </Flex>
  )
}

export default CarsManager
