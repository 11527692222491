import * as React from 'react'

import {AddIcon, CloseIcon} from '@chakra-ui/icons'
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  Text,
  useDisclosure,
  Flex,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'

import FilterBuilder from '.'
import AppliedFilters from './applied-filters'
import {FilterField} from './types'
import {buildFilterFromQueryString, buildQueryFilter, removeFieldFromFilter} from './utils'

type Props = {
  filterFields: FilterField[]
}

const FilterMenu = ({filterFields}: Props) => {
  const {t} = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const {isOpen, onOpen, onClose} = useDisclosure()

  const appliedFilters = React.useMemo(
    () => buildFilterFromQueryString(searchParams.get('filter') ?? ''),
    [searchParams]
  )

  const handleClearFilter = React.useCallback(() => {
    setSearchParams('')
  }, [setSearchParams])

  const handleRemoveFilter = React.useCallback(
    (path: number[]) => {
      if (!appliedFilters) return

      const newQuery = buildQueryFilter(removeFieldFromFilter(appliedFilters, path))
      setSearchParams(newQuery ? {filter: newQuery} : '')
    },
    [appliedFilters, setSearchParams]
  )

  return (
    <>
      <Tooltip label={t('common:filter:addFilters')}>
        <Button
          aria-label="add-filters"
          size="sm"
          variant={appliedFilters ? 'outline' : 'ghost'}
          onClick={onOpen}
          colorScheme="blue"
          p={1}
        >
          <HStack>
            <AddIcon />
            {!appliedFilters && <Text fontWeight="light">{t('common:filter:addFilters')}</Text>}{' '}
          </HStack>
        </Button>
      </Tooltip>
      {appliedFilters && (
        <HStack>
          <Tooltip label={t('common:filter:clearAll')}>
            <Button aria-label="clear-filter" size="sm" variant="ghost" p={1} onClick={handleClearFilter}>
              <CloseIcon />
            </Button>
          </Tooltip>
          <Flex gap={1} wrap="wrap" align="center" color="blue.200">
            <AppliedFilters
              value={appliedFilters}
              onRemove={handleRemoveFilter}
              filterFields={filterFields}
            />
          </Flex>
        </HStack>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('common:filter:addFilters')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FilterBuilder fields={filterFields} onSubmit={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default FilterMenu
