import * as React from 'react'

import {Flex, Grid, GridItem, Heading, Stack, useBreakpointValue} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import SerieClasses from '@/admin/serie-classes'
import {OrganizerSerie} from '@/api/models'
import {useSupabaseQuery} from '@/common/hooks'
import LoadingView from '@/common/loading-view'
import EventTile from '@/organizer/events/event-tile'

import SerieForm from './form'
import ParticipantsList from './participants'

const SeriePage = () => {
  const {t} = useTranslation()
  const {serieID} = useParams()

  const {loading, data, fetch, rows} = useSupabaseQuery<OrganizerSerie>({
    fields: '*',
    table: 'organizer_series',
    itemsPerPage: 1,
    filter: React.useCallback(
      (b: PostgrestFilterBuilder<OrganizerSerie>) => b.eq('id', serieID ?? ''),
      [serieID]
    ),
  })

  const serie = React.useMemo(() => (!data?.length ? undefined : data[0]), [data])

  const templateColumns = useBreakpointValue({
    sm: 'repeat(4, 1fr)',
    md: 'repeat(8, 1fr)',
    lg: 'repeat(12, 1fr)',
  })

  if (loading) {
    return <LoadingView />
  }

  if (!serie) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Heading>{t('series:notFound')}</Heading>
      </Flex>
    )
  }

  return (
    <Stack spacing={6}>
      <SerieForm item={serie} refetch={fetch} />
      <Grid templateColumns={templateColumns} gap={4}>
        {serie.events?.map((e) => (
          <GridItem key={e.id} colSpan={4}>
            <EventTile event={e} />
          </GridItem>
        ))}
      </Grid>
      <Stack p={4} bgColor="gray.900" borderRadius="2xl" position="relative" boxShadow="lg">
        <SerieClasses serieID={serieID} />
      </Stack>
      <ParticipantsList />
    </Stack>
  )
}

export default SeriePage
