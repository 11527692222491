import {add} from 'date-fns'

import {emptySupabaseQueryResult} from '@/common/hooks/use-supabase-query'
import {EventRentedDevice, RentedDeviceAssignemt} from '@/organizer/types'

import {Context} from './types'

export const emptyAssignment: RentedDeviceAssignemt = {
  id: '',
  type: '',
  start_time: new Date(),
  end_time: add(new Date(), {hours: 2}),
}

const emptyRentedDevice: EventRentedDevice = {
  event_id: '',
  created_at: new Date(),
  updated_at: new Date(),
  id: '',
  name: '',
  description: '',
  virtual: false,
  start_time: new Date(),
  end_time: new Date(),
  username: '',
  password: '',
}

export const emptyContext: Context = {
  device: emptyRentedDevice,
  isCurrentlyRented: false,
}
