import * as React from 'react'

import {MoonIcon, SunIcon} from '@chakra-ui/icons'
import {Flex, IconButton, useColorMode, useColorModeValue} from '@chakra-ui/react'

import LanguageSelector from '@/layout/header/language-selector'
import Menu from '@/layout/header/menu'

const Header = () => {
  const {colorMode, toggleColorMode} = useColorMode()

  return (
    <Flex
      color={useColorModeValue('gray.600', 'white')}
      bgColor={useColorModeValue('gray.400', 'gray.900')}
      minH="60px"
      py={2}
      px={4}
      mx={4}
      align="center"
      justify="flex-end"
      borderRadius="xl"
      boxShadow="lg"
    >
      <Flex flex={{base: 1, md: 0}} justify="flex-end" alignItems="center" direction="row" gap={4}>
        <LanguageSelector />
        {colorMode === 'light' ? (
          <IconButton
            aria-label="Switch to dark mode"
            icon={<MoonIcon />}
            onClick={toggleColorMode}
            bg="none"
          />
        ) : (
          <IconButton
            aria-label="Switch to light mode"
            icon={<SunIcon />}
            onClick={toggleColorMode}
            bg="none"
          />
        )}
        <Menu />
      </Flex>
    </Flex>
  )
}

export default Header
