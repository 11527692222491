import {add} from 'date-fns'

import {EventParticipant} from '@/api/models'

export const emptyEventParticipant: EventParticipant = {
  created_at: new Date(),
  updated_at: new Date(),
  event_id: '',
  event_name: '',
  participant_id: '',
  participant_number: 0,
  participant_name: '',
  classes: [],
}
