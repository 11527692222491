import * as React from 'react'

import {Box, Heading} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

const Dashboard = () => {
  const {t} = useTranslation()

  return (
    <Box>
      <Heading fontSize="xl" mb={4}>
        Podsumowanie
      </Heading>
      insert dashboard here
    </Box>
  )
}

export default Dashboard
