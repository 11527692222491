import * as React from 'react'

import {Avatar, Button, Container, Flex, Spinner, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {supabase} from '@/api'
import {selectProfileLoading, selectProfile, fetchUser} from '@/auth/state'
import {useAppSelector, useAppDispatch} from '@/store'

import AvatarUploadModal from '../avatar-upload-modal'

const UserProfile = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectProfile)
  const loading = useAppSelector(selectProfileLoading)
  const {
    isOpen: isAvatarUploadOpen,
    onOpen: onAvatarUploadOpen,
    onClose: onAvatarUploadClose,
  } = useDisclosure()

  const refetch = React.useCallback(() => {
    const user = supabase.auth.user()
    dispatch(fetchUser(user || undefined))
  }, [dispatch])

  return (
    <Container maxW="container.xl" mt={4} mb={4}>
      <Flex align="center" justify="center">
        {loading || !user ? (
          <Spinner />
        ) : (
          <Stack align="center" spacing={4} m={3}>
            {user.avatar_url ? <Avatar size="2xl" src={user.avatar_url} /> : <Avatar size="2xl" />}
            <Button onClick={onAvatarUploadOpen} size="sm" variant="link" color="white">
              {t('user:changeProfilePicture')}
            </Button>
            <Text>{user.email}</Text>
            <AvatarUploadModal
              user={user}
              open={isAvatarUploadOpen}
              onClose={onAvatarUploadClose}
              onComplete={refetch}
            />
          </Stack>
        )}
      </Flex>
    </Container>
  )
}

export default UserProfile
