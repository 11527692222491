import * as React from 'react'

import {ViewIcon, ViewOffIcon} from '@chakra-ui/icons'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import type {InputProps} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

type Props = {
  inputProps: InputProps
  id?: string
  label?: boolean
  errorMsg?: string
}

const PasswordInput = ({inputProps, id, label, errorMsg}: Props) => {
  const {t} = useTranslation()
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  return (
    <FormControl
      id={id}
      isRequired={inputProps.isRequired}
      isDisabled={inputProps.isDisabled}
      isInvalid={inputProps.isInvalid}
    >
      {label && <FormLabel>{t('auth:signin:password')}</FormLabel>}
      <InputGroup size="md">
        <Input {...inputProps} pr="4rem" type={show ? 'text' : 'password'} />
        <InputRightElement width="4rem">
          <IconButton
            variant="ghost"
            aria-label="Call Sage"
            fontSize="20px"
            width="100%"
            borderRadius="0 0.375rem 0.375rem 0"
            icon={show ? <ViewIcon /> : <ViewOffIcon />}
            onClick={handleClick}
          />
        </InputRightElement>
      </InputGroup>
      {errorMsg && <FormErrorMessage>{errorMsg}</FormErrorMessage>}
    </FormControl>
  )
}

export default PasswordInput
