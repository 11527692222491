import React from 'react'

import {Divider, Flex, Spacer} from '@chakra-ui/layout'
import {Button, Fade, useColorModeValue} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {
  QueryBuilder,
  transformQuery,
  type OptionGroup,
  type Field,
  type RuleGroupType,
  type RuleType,
  type Translations,
  type ValueEditorType,
} from 'react-querybuilder'
import {useSearchParams} from 'react-router-dom'

import {
  allLabeledOperators as _allLabeledOperators,
  combinators as _combinators,
  emptyQuery,
  filterInputVariantToInputType,
  filterInputVariantToOperators,
  filterInputVariantToSelectPresets,
  filterInputVariantToValueEditorType,
} from './constants'
import controlElements from './control-elements'
import './default.css'
import {FilterField, Operator, RuleTypeExtended} from './types'
import {buildFilterFromQueryString, buildQueryFilter} from './utils'

type Props = {
  fields: FilterField[]
  loading?: boolean
  onSubmit?: () => void
}

const FilterBuilder = ({fields: _fields, loading, onSubmit}: Props) => {
  const {t} = useTranslation()

  const [query, setQuery] = React.useState<RuleGroupType>(emptyQuery)
  const handleQueryChange = React.useCallback((query: RuleGroupType) => {
    setQuery(query)
  }, [])
  const fields: Field[] | OptionGroup<Field>[] | Record<string, Field> = React.useMemo(
    () =>
      _fields.map((field) => ({
        ...field,
        inputType: filterInputVariantToInputType[field.variant],
        operators: field.operators ?? filterInputVariantToOperators(t)[field.variant],
        valueEditorType: filterInputVariantToValueEditorType[field.variant] as ValueEditorType,
        values: field.values ?? filterInputVariantToSelectPresets(t)[field.variant],
      })),
    [_fields, t]
  )

  const [searchParams, setSearchParams] = useSearchParams()
  // update query state on search params change
  React.useEffect(() => {
    setQuery(buildFilterFromQueryString(searchParams.get('filter') ?? '') ?? emptyQuery)
  }, [searchParams])

  // Add properties to query rules.
  // https://react-querybuilder.js.org/docs/tips/adding-removing-query-properties#adding-properties
  const ruleProcessor = React.useCallback(
    (r: RuleType): RuleTypeExtended => ({
      ...r,
      operator: r.operator as Operator,
      variant: fields.find((f) => f.name === r.field)?.variant,
    }),
    [fields]
  )

  const handleSubmit = React.useCallback(() => {
    setSearchParams(
      query?.rules.length ? {filter: buildQueryFilter(transformQuery(query, {ruleProcessor}))} : ''
    )
    onSubmit && onSubmit()
  }, [setSearchParams, ruleProcessor, onSubmit, query])

  const handleClearAll = React.useCallback(() => {
    setQuery(emptyQuery)
  }, [setQuery])

  const allLabeledOperators = React.useMemo(() => _allLabeledOperators(t), [t])

  const combinators = React.useMemo(() => _combinators(t), [t])

  const translations: Partial<Translations> = React.useMemo(
    () => ({
      addGroup: {
        label: t('common:filter:addGroup'),
        title: t('common:filter:addGroup'),
      },
      addRule: {
        label: t('common:filter:addRule'),
        title: t('common:filter:addRule'),
      },
      combinators: {
        title: t('common:filter:combinators'),
      },
      fields: {
        title: t('common:filter:fields'),
      },
      notToggle: {
        label: t('common:filter:not'),
        title: t('common:filter:not'),
      },
      operators: {
        title: t('common:filter:operators'),
      },
      value: {
        title: t('common:filter:value'),
      },
    }),
    [t]
  )

  return (
    <Flex
      direction="column"
      borderRadius="lg"
      border="1px solid"
      borderColor={useColorModeValue('gray.200', 'whiteAlpha.300')}
      mb={2}
    >
      <QueryBuilder
        fields={fields}
        onQueryChange={handleQueryChange}
        controlElements={controlElements}
        operators={allLabeledOperators}
        translations={translations}
        combinators={combinators}
        query={query}
      />
      <Divider />
      <Flex direction="row">
        <Fade in={!!query?.rules.length}>
          <Button size="sm" my={1} ml={2} onClick={handleClearAll} variant="ghost">
            {t('common:filter:clearAll')}
          </Button>
        </Fade>
        <Spacer />
        <Button size="sm" my={1} mr={2} onClick={handleSubmit} isLoading={loading}>
          {t('common:actions:submit')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default FilterBuilder
