import * as React from 'react'

import {AddIcon, CloseIcon, RepeatIcon} from '@chakra-ui/icons'
import {HStack, IconButton, Heading, useDisclosure, Collapse, Skeleton, Flex} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {emptyParticipantTag} from '@/admin/participant-tags/constants'
import {ParticipantTag, Event} from '@/api/models'
import {useSupabaseQuery} from '@/common/hooks'

import TagItem from './tag-item'

const TagsManager = ({participantID}: {participantID: string}) => {
  const {t} = useTranslation()
  const {eventID} = useParams()
  const {isOpen, onToggle, onClose} = useDisclosure()

  const {
    loading,
    data: tags,
    fetch,
  } = useSupabaseQuery<ParticipantTag>({
    fields: '*',
    table: 'admin_participant_tags',
    order: 'created_at',
    descending: true,
    filter: React.useCallback(
      (b: PostgrestFilterBuilder<ParticipantTag>) =>
        b.match(
          eventID ? {participant_id: participantID, event_id: eventID} : {participant_id: participantID}
        ),
      [participantID, eventID]
    ),
  })

  const events = useSupabaseQuery<Partial<Event>>({
    fields: '*',
    table: 'participant_events',
    order: 'name',
    autoFetch: false,
    filter: React.useCallback(
      (b: PostgrestFilterBuilder<Partial<Event>>) => b.match({participant_id: participantID}),
      [participantID]
    ),
  })
  React.useEffect(() => {
    !eventID && events.fetch()
  }, [eventID]) // eslint-disable-line

  return (
    <Flex w="100%" direction="column">
      <HStack>
        <Heading size="md">{t('events:participants:form:manageTags')}</Heading>
        <IconButton
          aria-label="add-car"
          icon={isOpen ? <CloseIcon /> : <AddIcon />}
          onClick={onToggle}
          size="sm"
          isDisabled={loading}
        />
        <IconButton
          aria-label="refetch-car"
          icon={<RepeatIcon />}
          onClick={fetch}
          size="sm"
          isLoading={loading}
        />
      </HStack>
      {loading || events.loading ? (
        <>
          <Skeleton h="254px" mt={2} borderRadius="lg" />
          <Skeleton h="254px" mt={2} borderRadius="lg" />
        </>
      ) : (
        <>
          <Collapse in={isOpen} unmountOnExit={true}>
            <TagItem
              tag={emptyParticipantTag}
              events={events.data ?? []}
              participantID={participantID}
              onComplete={fetch}
              onClose={onClose}
            />
          </Collapse>

          {tags?.map((c, i) => (
            <TagItem
              key={i}
              tag={c}
              events={events.data ?? []}
              participantID={participantID}
              onComplete={fetch}
            />
          ))}
        </>
      )}
    </Flex>
  )
}

export default TagsManager
