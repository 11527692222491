import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Input,
  Textarea,
  Checkbox,
  Box,
  useToast,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {ImageType} from 'react-images-uploading'
import {v4 as uuidv4} from 'uuid'

import {EditorMode} from '@/admin/types'
import {Location, Track, RPC} from '@/api/models'
import {uploadFile} from '@/api/utils'
import {ImageUpload, LocationInput} from '@/common/components'
import {isLocationValid} from '@/common/components/location-input/utils'
import {useLoadingState, useSupabaseRPC} from '@/common/hooks'
import {SUPABASE_TRACKS_BUCKET} from '@/constants'
import {CommonModalProps} from '@/utils/types'

import {emptyTrack} from '../constants'
import {inputToUpsertArgs} from './utils'

const TrackEditorModal = ({
  onClose,
  open,
  item,
  onComplete,
  mode,
}: CommonModalProps & {item: Track; mode: EditorMode}) => {
  const {t} = useTranslation()
  const toast = useToast()
  const [input, setInput] = React.useState(emptyTrack)
  const [image, setImage] = React.useState<ImageType>()

  const {handleRPC} = useSupabaseRPC({
    fnName: RPC.UpsertTrack,
    params: {},
    mode,
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
    item.picture_url ? setImage({dataURL: item.picture_url}) : setImage(undefined)
  }, [item, open])

  const _handleSubmit = React.useCallback(async () => {
    try {
      const id = input.id || uuidv4()
      const filepath = image?.file
        ? await uploadFile(SUPABASE_TRACKS_BUCKET, id, image?.file)
        : image?.dataURL
        ? input.picture
        : undefined
      await handleRPC(inputToUpsertArgs({...input, id, picture: filepath}))
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        status: 'error',
        title: t(`common:dataTable:${mode}Fail`),
      })
    }
  }, [handleRPC, image, input, mode, toast, t])
  const {handleSubmit, loading} = useLoadingState(_handleSubmit)

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handleLocationChange = React.useCallback(
    (location: Location) => setInput((input) => ({...input, location})),
    []
  )

  const handlePublicChange = React.useCallback(
    ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) =>
      setInput((input) => ({...input, public: checked})),
    []
  )

  const isSubmitDisabled = React.useMemo(
    () => !input.name || !input.website || !input.description || !isLocationValid(input.location),
    [input]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`tracks:form:addTrack`)
            // t(`tracks:form:updateTrack`)
            t(`tracks:form:${mode}Track`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack px={2} py={2}>
            <Stack spacing={4} align="center" width="100%">
              <FormControl id="name" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:name')}</FormLabel>
                <Input value={input.name ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl id="website" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:website')}</FormLabel>
                <Input value={input.website ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl id="description" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:description')}</FormLabel>
                <Textarea resize="vertical" value={input.description ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl id="public" isDisabled={loading}>
                <Checkbox isChecked={input.public ?? false} onChange={handlePublicChange}>
                  {t('common:fields:public')}
                </Checkbox>
              </FormControl>
              <LocationInput location={input.location} onChange={handleLocationChange} isDisabled={loading} />
              <Box w="100%">
                <ImageUpload onChange={setImage} value={image} editing={true} />
              </Box>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleSubmit} isDisabled={isSubmitDisabled}>
              {t('common:actions:save')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TrackEditorModal
