import * as React from 'react'

import {ExternalLinkIcon} from '@chakra-ui/icons'
import {Container, Flex, Heading, Stack, Text, Link, HStack, Image} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {Organizer} from '@/api/models'

const OrganizerTab = ({organizer}: {organizer?: Organizer}) => {
  const {t} = useTranslation()

  if (!organizer) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Heading>404 Organizer Not Found</Heading>
      </Flex>
    )
  }

  return (
    <Container maxW="container.xl" px={8}>
      <Flex p={4} justifyContent="center">
        <Stack spacing={4} mr={4}>
          <HStack as={Link} href={organizer.website} target="_blank" spacing={4}>
            {organizer.logo_url && <Image src={organizer.logo_url} h="3rem" />}
            <Heading mr={2}>{organizer.name}</Heading>
            <ExternalLinkIcon h="1.5rem" w="1.5rem" />
          </HStack>
          <Text>{organizer.description}</Text>
        </Stack>
      </Flex>
    </Container>
  )
}

export default OrganizerTab
