import * as React from 'react'

import {Box, Heading, useDisclosure} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {Participant} from '@/api/models'
import {DeleteResourceModal} from '@/common/components'
import DataTable from '@/common/data-table'
import {Column} from '@/common/data-table/types'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import {FilterField} from '@/common/filter-builder/types'
import {useSupabaseQuery} from '@/common/hooks'

import {EditorMode} from '../types'
import {manageCarsAction, manageTagsAction} from './actions'
import {emptyParticipant} from './constants'
import ParticipantEditorModal from './editor-modal'

const defaultSelectedColumns = ['serie_name', 'number', 'name', 'first_name']

const Participants = () => {
  const {t} = useTranslation()
  const tableState = useTableState({sortBy: 'created_at'})
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [currentParticipant, setCurrentParticipant] = React.useState<Participant>(emptyParticipant)
  const [currentMode, setCurrentMode] = React.useState<EditorMode>('add')
  const {loading, data, fetch, rows} = useSupabaseQuery<Participant>({
    fields: '*',
    table: 'participants_view',
    order: tableState.sortBy.column as keyof Participant,
    itemsPerPage: tableState.rowsPerPage,
    pageNumber: tableState.page,
    descending: tableState.descending,
    filter: tableState.filter,
  })

  const columns: Column<Participant>[] = React.useMemo(
    () => [
      {
        Header: t('common:fields:id').toString(),
        id: 'id',
        renderAs: 'code',
        sortable: false,
      },
      {
        Header: t('common:entities:serie').toString(),
        id: 'serie_name',
      },
      {
        Header: t('participants:fields:number').toString(),
        id: 'number',
      },
      {
        Header: t('common:fields:name').toString(),
        id: 'name',
      },
      {
        Header: t('participants:fields:firstName').toString(),
        id: 'first_name',
      },
      {
        Header: t('participants:fields:user').toString(),
        id: 'user_email',
      },
      {
        Header: t('common:fields:createdAt').toString(),
        id: 'created_at',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:updatedAt').toString(),
        id: 'updated_at',
        renderAs: 'timestamp',
      },
    ],
    [t]
  )

  const filterFields: FilterField[] = React.useMemo(
    () => [
      {
        label: t('common:entities:serie'),
        name: 'serie_name',
        variant: 'text',
      },
      {
        label: t('participants:fields:number'),
        name: 'number',
        variant: 'number',
      },
      {
        label: t('common:fields:name'),
        name: 'name',
        variant: 'text',
      },
      {
        label: t('participants:fields:firstName'),
        name: 'first_name',
        variant: 'text',
      },
      {
        label: t('participants:fields:user'),
        name: 'user_email',
        variant: 'text',
      },
      {
        label: t('common:fields:createdAt'),
        name: 'created_at',
        variant: 'datetime',
      },
      {
        label: t('common:fields:updatedAt'),
        name: 'updated_at',
        variant: 'datetime',
      },
    ],
    [t]
  )

  const handleAdd = React.useCallback(() => {
    setCurrentParticipant(emptyParticipant)
    setCurrentMode('add')
    onEditorOpen()
  }, [onEditorOpen])

  const handleEdit = React.useCallback(
    (item: Participant) => {
      setCurrentParticipant(item)
      setCurrentMode('update')
      onEditorOpen()
    },
    [onEditorOpen]
  )

  const handleDelete = React.useCallback(
    (item: Participant) => {
      setCurrentParticipant(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  const actions = React.useMemo(
    () => [manageCarsAction, manageTagsAction, editAction(handleEdit), deleteAction(handleDelete)],
    [handleEdit, handleDelete]
  )

  return (
    <Box>
      <Heading fontSize="xl" mb={4}>
        {t('admin:menu:participants')}
      </Heading>
      <DataTable<Participant>
        columns={columns}
        defaultSelectedColumns={defaultSelectedColumns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        loading={loading}
        onRefresh={fetch}
        onAdd={handleAdd}
        filterFields={filterFields}
      />
      <ParticipantEditorModal
        item={currentParticipant}
        onClose={onEditorClose}
        open={isEditorOpen}
        onComplete={fetch}
        mode={currentMode}
      />
      <DeleteResourceModal
        table="participants"
        id={currentParticipant.id}
        onClose={onDeleteClose}
        open={isDeleteOpen}
        onComplete={fetch}
      />
    </Box>
  )
}

export default Participants
