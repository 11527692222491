import {AdminOrganizer, AdminOrganizerUser, OrganizerMember, AdminSerie} from '@/api/models'
import i18n from '@/i18n'

import {UsersOrganizersRoleItem} from './types'

export const userRoleItems: UsersOrganizersRoleItem[] = [
  {
    label: i18n.t('organizer:members:role:admin'),
    value: 'admin',
  },
  {
    label: i18n.t('organizer:members:role:basic'),
    value: 'basic',
  },
]

export const emptyEmailRole: AdminOrganizerUser = {
  email: '',
  role: 'basic',
}

export const emptyMember: OrganizerMember = {
  organizer_id: '',
  user_id: '',
  created_at: new Date(),
  updated_at: new Date(),
  email: '',
  role: 'basic',
  avatar_url: '',
}

export const emptyOrganizer: AdminOrganizer = {
  id: '',
  created_at: new Date(),
  updated_at: new Date(),
  name: '',
  description: '',
  website: '',
  public: false,
  logo: undefined,
}

export const emptySerie: AdminSerie = {
  id: '',
  created_at: new Date(),
  updated_at: new Date(),
  organizer_id: '',
  name: '',
  public: false,
  logo: undefined,
  logo_url: undefined,
  start_date: '',
  end_date: '',
}
