import {Car} from '@/api/models'

export const emptyCar: Car = {
  id: '',
  participant: '',
  created_at: new Date(),
  updated_at: new Date(),
  name: '',
  public: false,
}
