import {AdminEvent} from '@/api/models'

export const inputToUpsertArgs = (input: AdminEvent) => ({
  event_id: input.id,
  name: input.name,
  serie_id: input.serie?.id,
  layout_id: input.track_layout?.id,
  description: input.description,
  public: input.public,
  picture: input.picture,
  start_time: input.start_time,
  end_time: input.end_time,
  min_lap_time: input.min_lap_time,
  max_lap_time: input.max_lap_time,
})
