import * as React from 'react'

import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {useAppSelector} from '@/store'

import {selectEventClasses, selectEventParticipants} from '../state'
import ParticipantRow from './participant-row'
import {isInClass} from './utils'

const EventLapTimes = () => {
  const {t} = useTranslation()
  const classes = useAppSelector(selectEventClasses)
  const participants = useAppSelector(selectEventParticipants)

  const sorted = React.useMemo(() => [...participants].sort((a, b) => a.avg - b.avg), [participants])

  return (
    <Flex justifyContent="center">
      <Tabs w="100%" size="md" variant="soft-rounded" colorScheme="red">
        <TabList p={4} overflow="scroll hidden">
          {classes?.map((c) => (
            <Tab key={c.id} mr={2}>
              {c.name}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {classes?.map((c) => {
            let index = 0
            return (
              <TabPanel key={c.id} p={0}>
                <TableContainer>
                  <Table size="lg">
                    <Thead>
                      <Tr>
                        <Th w="1%">#</Th>
                        <Th w="1%">{t('events:table:number')}</Th>
                        <Th>{t('events:table:participant')}</Th>
                        <Th>{t('events:table:average')}</Th>
                        <Th>{t('events:table:best3')}</Th>
                        <Th>{t('events:table:points')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {sorted?.map((p) =>
                        isInClass(p, c.id) ? (
                          <ParticipantRow key={p.id} participant={p} index={++index} />
                        ) : null
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TabPanel>
            )
          })}
        </TabPanels>
      </Tabs>
    </Flex>
  )
}

export default EventLapTimes
