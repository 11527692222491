import * as React from 'react'

import {MoonIcon, SunIcon} from '@chakra-ui/icons'
import {Container, Flex, IconButton, Stack, useColorMode, useColorModeValue} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import LanguageSelector from './language-selector'
import Logo from './logo'
import Menu from './menu'
import NavLink from './navlink'

const Header = () => {
  const {t} = useTranslation()

  const {colorMode, toggleColorMode} = useColorMode()

  return (
    <Container maxW="container.xl" mt={4} mb={4}>
      <Flex
        color={useColorModeValue('gray.600', 'white')}
        minH="60px"
        py={{base: 2}}
        px={{base: 4}}
        borderStyle="solid"
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align="center"
      >
        <Flex flex={{base: 1}} justify={{base: 'center', md: 'start'}}>
          <Logo />
          <Stack
            direction="row"
            as="nav"
            spacing={4}
            ml={8}
            display={{base: 'none', md: 'flex'}}
            alignItems="center"
            justifyContent="flex-start"
          >
            <NavLink to="/series">{t('navbar:series')}</NavLink>
            <NavLink to="/events">{t('navbar:events')}</NavLink>
            <NavLink to="/tracks">{t('navbar:tracks')}</NavLink>
          </Stack>
        </Flex>

        <Flex flex={{base: 1, md: 0}} justify="flex-end" alignItems="center" direction="row" gap={4}>
          <LanguageSelector />
          {colorMode === 'light' ? (
            <IconButton
              aria-label="Switch to dark mode"
              icon={<MoonIcon />}
              onClick={toggleColorMode}
              bg="none"
            />
          ) : (
            <IconButton
              aria-label="Switch to light mode"
              icon={<SunIcon />}
              onClick={toggleColorMode}
              bg="none"
            />
          )}
          <Menu />
        </Flex>
      </Flex>
    </Container>
  )
}

export default Header
