import {OrganizerEventParticipant} from '@/api/models'

import {ParticipantFilter} from './types'

export const filterParticipants = (p: OrganizerEventParticipant, filter: ParticipantFilter) => {
  // Number
  if (filter.number > 0 && p.number !== filter.number) {
    return false
  }
  // Name - case insensitive
  if (filter.name && !p.name?.toLowerCase().includes(filter.name.toLowerCase())) {
    return false
  }
  // Class
  if (filter.classes.length) {
    if (p.classes.length === 0 && filter.classes.includes('empty')) return true
    if (p.classes.length && filter.classes.includes('any')) return true

    for (const c of p.classes) {
      if (filter.classes.includes(c.class_id)) {
        return true
      }
    }
    return false
  }

  return true
}
