import * as React from 'react'

import {TriangleDownIcon, TriangleUpIcon} from '@chakra-ui/icons'
import {Button, type ButtonProps} from '@chakra-ui/react'

type Props = ButtonProps & {
  column: string
  current: string
  descending: boolean
  onSortChange: (column: string) => void
  iconSize?: number
}

const SortByButton = ({column, current, descending, onSortChange, iconSize = 4, ...rest}: Props) => {
  const handleChange = React.useCallback(() => {
    onSortChange(column)
  }, [column, onSortChange])

  return (
    <Button
      size="sm"
      variant="ghost"
      p={1}
      opacity={column === current ? '1' : '0'}
      _hover={{opacity: '1'}}
      onClick={handleChange}
      {...rest}
    >
      {descending ? <TriangleUpIcon boxSize={iconSize} /> : <TriangleDownIcon boxSize={iconSize} />}
    </Button>
  )
}

export default SortByButton
