import * as React from 'react'

import {Route} from 'react-router-dom'

import Dashboard from './dashboard'
import Details from './details'
import EventPage from './events/page'
import Layout from './layout'
import Members from './members'
import Series from './series'
import SeriePage from './series/page'

const Router = () => (
  <Route element={<Layout />}>
    <Route index={true} element={<Dashboard />} />
    <Route path="members" element={<Members />} />
    <Route path="details" element={<Details />} />
    <Route path="series" element={<Series />} />
    <Route path="serie/:serieID" element={<SeriePage />} />
    <Route path="event/:eventID" element={<EventPage />} />
  </Route>
)

export default Router
