import * as React from 'react'

import {Stack, HStack, FormControl, FormLabel, Input, Select, Button} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {Serie, Participant, RPC} from '@/api/models'
import {useSupabaseQuery, useSupabaseRPC} from '@/common/hooks'
import {CommonModalProps} from '@/utils/types'

import {emptyParticipant} from '../constants'
import {inputToUpsertArgs} from './utils'

type Props = CommonModalProps & {
  item: Participant
  serieID?: string
}

const ParticipantForm = ({item, serieID, open, onClose, onComplete}: Props) => {
  const {t} = useTranslation()
  const [input, setInput] = React.useState(emptyParticipant)
  const {
    loading: seriesLoading,
    data: series,
    fetch: fetchSeries,
  } = useSupabaseQuery<Serie>({
    fields: '*',
    table: 'series',
    order: 'name',
    autoFetch: false,
  })
  React.useEffect(() => {
    !serieID && fetchSeries()
  }, [serieID, fetchSeries])

  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertParticipant,
    params: inputToUpsertArgs({...input, serie_id: serieID || input.serie_id}),
    mode: item.id ? 'update' : 'add',
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
  }, [item, open])

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const isSubmitDisabled = React.useMemo(
    () => !input.name || !input.first_name || (!input.serie_id && !serieID),
    [input, serieID]
  )
  return (
    <Stack
      spacing={4}
      p={2}
      align="stretch"
      width="100%"
      bgColor="gray.800"
      border="1px solid"
      borderColor="whiteAlpha.300"
      borderRadius="lg"
    >
      <HStack>
        <FormControl id="number" isDisabled={loading} isRequired={true} maxW="96px">
          <FormLabel>{t('participants:fields:number')}</FormLabel>
          <Input value={input.number ?? ''} type="number" onChange={handleInputChange} />
        </FormControl>
        <FormControl id="name" isDisabled={loading} isRequired={true} flex="1">
          <FormLabel>{t('participants:fields:name')}</FormLabel>
          <Input value={input.name ?? ''} onChange={handleInputChange} />
        </FormControl>
      </HStack>
      <FormControl id="first_name" isDisabled={loading} isRequired={true}>
        <FormLabel>{t('participants:fields:firstName')}</FormLabel>
        <Input value={input.first_name ?? ''} onChange={handleInputChange} />
      </FormControl>
      {!serieID && (
        <FormControl id="serie_id" isDisabled={seriesLoading || loading} isRequired={true}>
          <FormLabel>{t('common:entities:serie')}</FormLabel>
          <Select value={input.serie_id ?? ''} onChange={handleInputChange}>
            <option value="">{t('participants:form:selectSerie')}</option>
            {!seriesLoading &&
              series?.map((s) => (
                <option value={s.id} key={s.id}>
                  {s.name}
                </option>
              ))}
          </Select>
        </FormControl>
      )}
      <FormControl id="user_email" isDisabled={loading}>
        <FormLabel>{t('participants:fields:user')}</FormLabel>
        <Input
          value={input.user_email ?? ''}
          onChange={handleInputChange}
          placeholder={t('participants:fields:userEmail')}
        />
      </FormControl>
      <HStack py={2} justifyContent="flex-end">
        <Button isLoading={loading} onClick={handleRPC} isDisabled={isSubmitDisabled}>
          {t('common:actions:save')}
        </Button>
        <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
          {t('common:actions:cancel')}
        </Button>
      </HStack>
    </Stack>
  )
}

export default ParticipantForm
