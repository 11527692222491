import {format} from 'date-fns'
import _ from 'lodash'

export const sentenceCase = (input: string) => _.startCase(_.toLower(input))

export const formatTimestamp = (value: Date | string) => format(new Date(value), 'dd/MM/yyyy HH:mm:ss')
export const formatTimeOfDay = (value: Date | string) => format(new Date(value), 'HH:mm:ss')

export const formatDate = (value: Date) => format(value, 'dd/MM/yyyy')

// dateToInput formats the date to match the <input type="datetime-local"> standard
export const dateToInput = (value: Date) => format(value, "yyyy-MM-dd'T'HH:mm")

export const formatMilliseconds = (ms: number) => {
  const milliseconds = ('000' + (ms % 1000)).slice(-3)
  const seconds = ('00' + (Math.floor(ms / 1000) % 60)).slice(-2)
  const minutes = ('00' + Math.floor(ms / 1000 / 60)).slice(-2)
  return `${minutes}:${seconds}.${milliseconds}`
}
