import * as React from 'react'

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {CommonModalProps} from '@/utils/types'

import ParticipantsSourceForm from './participants-form'
import ReadoutsSourceForm from './readouts-form'

const ConfigModal = ({eventID, open, onClose}: CommonModalProps & {eventID: string}) => {
  const {t} = useTranslation()

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('organizer:participants:configureDataSources')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <ParticipantsSourceForm eventID={eventID} />
            <ReadoutsSourceForm eventID={eventID} />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ConfigModal
