import {EditorMode} from '@/admin/types'
import {RentedDevice} from '@/api/models'

export const inputToUpsertArgs = (input: RentedDevice, mode: EditorMode) => ({
  rented_device_id: mode === 'update' ? input.id : undefined,
  device_id: input.device_id,
  event_id: input.event_id,
  start_time: input.start_time,
  end_time: input.end_time,
})
