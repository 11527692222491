import * as React from 'react'

import {HStack, Tag, TagCloseButton, Text, type TagCloseButtonProps} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {labelTranslations} from './constants'
import {FilterField, RuleGroupTypeExtended} from './types'

type Props = {
  value: RuleGroupTypeExtended
  filterFields: FilterField[]
  path?: number[]
  onRemove?: (path: number[]) => void
}

const AppliedFilters = ({value, filterFields, path = [], onRemove}: Props) => {
  const {t} = useTranslation()

  const handleRemove = React.useCallback(
    (index: number) => {
      onRemove && onRemove([...path, index])
    },
    [path, onRemove]
  )

  return (
    <>
      {value.rules?.map((r, i) => (
        <React.Fragment key={i}>
          {i ? (
            <Text fontSize="sm">
              {value.combinator !== 'and' && value.combinator !== 'or'
                ? 'UNDEFINED OP'
                : labelTranslations(t)[value.combinator]}
            </Text>
          ) : null}
          {'rules' in r ? (
            <>
              <Text>(</Text>
              <AppliedFilters
                key={i}
                value={r}
                path={[...path, i]}
                onRemove={onRemove}
                filterFields={filterFields}
              />
              <Text>)</Text>
            </>
          ) : (
            <Tag key={i} borderRadius="full">
              <HStack spacing={1}>
                <Text>{filterFields.find((f) => f.name === r.field)?.label || r.field}</Text>
                <Text color="blue.200">{labelTranslations(t)[r.operator]}</Text>
                {r.value && <Text>{r.value}</Text>}
              </HStack>
              {onRemove && <RemoveFilterButton index={i} onRemove={handleRemove} />}
            </Tag>
          )}
        </React.Fragment>
      ))}
    </>
  )
}

const RemoveFilterButton = ({
  index,
  onRemove,
  ...rest
}: TagCloseButtonProps & {index: number; onRemove: (index: number) => void}) => {
  const handleClick = React.useCallback(() => {
    onRemove(index)
  }, [index, onRemove])
  return <TagCloseButton onClick={handleClick} {...rest} />
}

export default AppliedFilters
