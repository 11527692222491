import {ParticipantAssignedClass} from '@/api/models'

export const inputToUpsertArgs = (
  input: ParticipantAssignedClass,
  participantID: string,
  eventID: string
) => ({
  class_assignment_id: input.class_assignment_id || undefined,
  event_id: eventID,
  participant_id: participantID,
  class_id: input.class_id,
  car_id: input.car_id || undefined,
  start_time: input.start_time,
  end_time: input.end_time,
})
