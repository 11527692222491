import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Input,
  Checkbox,
  Select,
  Textarea,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {EditorMode} from '@/admin/types'
import {Serie, AdminSerieClass, RPC} from '@/api/models'
import {useSupabaseQuery, useSupabaseRPC} from '@/common/hooks'
import {CommonModalProps} from '@/utils/types'

import {emptySerieClass} from '../constants'
import {inputToUpsertArgs} from './utils'

type Props = CommonModalProps & {
  item: AdminSerieClass
  mode: EditorMode
  serieID?: string
}

const SerieClassEditorModal = ({item, serieID, mode, open, onComplete, onClose}: Props) => {
  const {t} = useTranslation()
  const [input, setInput] = React.useState(emptySerieClass)
  const {loading: seriesLoading, data: series} = useSupabaseQuery<Serie>({
    fields: '*',
    table: 'series',
    order: 'name',
  })
  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertSerieClass,
    params: inputToUpsertArgs({...input, serie_id: serieID || input.serie_id}),
    mode,
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
  }, [item, open])

  const handleInputChange = React.useCallback(
    ({
      target: {id, value},
    }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handleHiddenChange = React.useCallback(
    ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) =>
      setInput((input) => ({...input, hidden: checked})),
    []
  )

  const isSubmitDisabled = React.useMemo(
    () =>
      !input.name ||
      !input.description ||
      !input.short_description ||
      !input.priority ||
      !(input.serie_id || serieID),
    [input, serieID]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`admin:serieClasses:form:addClass`)
            // t(`admin:serieClasses:form:updateClass`)
            t(`admin:serieClasses:form:${mode}Class`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack px={2} py={2}>
            <Stack spacing={4} align="center" width="100%">
              {!serieID && (
                <FormControl id="serie_id" isDisabled={seriesLoading || loading} isRequired={true}>
                  <FormLabel>{t('common:entities:serie')}</FormLabel>
                  <Select value={input.serie_id ?? ''} onChange={handleInputChange}>
                    <option value="">{t('admin:serieClasses:form:selectSerie')}</option>
                    {!seriesLoading &&
                      series?.map((s) => (
                        <option value={s.id} key={s.id}>
                          {s.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              )}
              <FormControl id="name" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:name')}</FormLabel>
                <Input value={input.name ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl id="short_description" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:shortDescription')}</FormLabel>
                <Textarea
                  resize="vertical"
                  value={input.short_description ?? ''}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl id="description" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:description')}</FormLabel>
                <Textarea resize="vertical" value={input.description ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl id="priority" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('series:classes:fields:priority')}</FormLabel>
                <Input value={input.priority ?? ''} type="number" onChange={handleInputChange} />
              </FormControl>
              <FormControl id="hidden" isDisabled={loading}>
                <Checkbox isChecked={input.hidden ?? false} onChange={handleHiddenChange}>
                  {t('series:classes:fields:hidden')}
                </Checkbox>
              </FormControl>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleRPC} isDisabled={isSubmitDisabled}>
              {t('common:actions:save')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SerieClassEditorModal
