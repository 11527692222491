import * as React from 'react'

import {CheckIcon, CloseIcon} from '@chakra-ui/icons'
import {Checkbox, Link, Tooltip, Text} from '@chakra-ui/react'

import i18n from '@/i18n'
import {formatTimestamp, formatDate, formatMilliseconds} from '@/utils/string'

import {RenderPreset} from './types'

export const renderPresetToRenderer: Record<RenderPreset, (value: any) => any> = {
  boolean: (value: any) => (value ? <CheckIcon /> : <CloseIcon />),
  checkbox: (value: any) => <Checkbox checked={value} isDisabled={true} />,
  code: (value: any) => <code>{value}</code>,
  longString: (value: any) => (
    <Tooltip label={value}>
      <Text overflow="hidden" maxWidth={100} textOverflow="ellipsis">
        {value || i18n.t('common:dataTable:notApplicable')}
      </Text>
    </Tooltip>
  ),
  timestamp: (value: any) =>
    value ? formatTimestamp(new Date(value)) : i18n.t('common:dataTable:notApplicable'),
  time: (value: any) => (value ? formatMilliseconds(value) : i18n.t('common:dataTable:notApplicable')),
  date: (value: any) => (value ? formatDate(new Date(value)) : i18n.t('common:dataTable:notApplicable')),
  url: (value: any) => (
    <Link href={value} isExternal={true}>
      {value}
    </Link>
  ),
}

export const defaultRowsPerPagePresets = [5, 10, 25, 50, 100]
