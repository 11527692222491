import * as React from 'react'

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import {Map as MapIcon} from 'react-feather'
import {useTranslation} from 'react-i18next'
import {FaClock} from 'react-icons/fa'
import {ImUserTie} from 'react-icons/im'
import {useParams, Link} from 'react-router-dom'

import LoadingView from '@/common/loading-view'
import OrganizerTab from '@/series/season-rankings/organizer'
import {useAppDispatch, useAppSelector} from '@/store'

import EventLapTimes from './lap-times'
import EventLayout from './layout'
import {fetchEvent, selectEvent, selectEventLoading, selectEventOrganizer} from './state'

const EventPage = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const event = useAppSelector(selectEvent)
  const loading = useAppSelector(selectEventLoading)
  const organizer = useAppSelector(selectEventOrganizer)

  const {id} = useParams()
  React.useEffect(() => {
    id && dispatch(fetchEvent(id))
  }, [id, dispatch])

  if (loading) {
    return <LoadingView />
  }

  if (!event) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Heading>{t('events:eventNotFound')}</Heading>
      </Flex>
    )
  }

  return (
    <Stack>
      <Breadcrumb fontSize="2xl" fontWeight="bold" mx={8} my={4}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={`/season-rankings/${event.serie?.id}`}>
            {event.serie?.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage={true}>
          <BreadcrumbLink>{event.name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Tabs w="100%" size="md" colorScheme="red">
        <TabList>
          <Tab ml={8}>
            <FaClock />
            <Text ml={2}>{t('events:lapTimes')}</Text>
          </Tab>
          <Tab ml={8}>
            <MapIcon size="1rem" />
            <Text ml={2}>{t('events:trackLayout')}</Text>
          </Tab>
          <Tab ml={8}>
            <ImUserTie />
            <Text ml={2}>{t('events:organizer')}</Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            <EventLapTimes />
          </TabPanel>
          <TabPanel>
            <EventLayout />
          </TabPanel>
          <TabPanel>
            <OrganizerTab organizer={organizer} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}

export default EventPage
