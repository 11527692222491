import * as React from 'react'

import {CloseIcon, EditIcon, CheckIcon} from '@chakra-ui/icons'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  Button,
  Heading,
  useToast,
  Spinner,
  Tooltip,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import isURL from 'validator/lib/isURL'

import {supabase} from '@/api'
import {BridgeSubscriptionSource, ParticipantsSubscription, RPC} from '@/api/models'
import {useLoadingState, useSupabaseRPC} from '@/common/hooks'

import {emptyCSVSubscription, emptySpreadsheetSubscription, SourceButton} from './constants'
import {inputToUpsertArgs} from './utils'

const ParticipantsSourceForm = ({eventID}: {eventID: string}) => {
  const {t} = useTranslation()
  const toast = useToast()
  const [editing, setEditing] = React.useState(false)
  const [input, setInput] = React.useState(emptyCSVSubscription)
  const [subscription, setSubscription] = React.useState(emptyCSVSubscription)

  const _fetch = React.useCallback(async () => {
    try {
      const {data, error} = await supabase
        .from<ParticipantsSubscription>('bridge_subscriptions')
        .select('*')
        .match({event: eventID})
        .in('source', [BridgeSubscriptionSource.CSV, BridgeSubscriptionSource.Spreadsheet])

      if (error) throw error
      if (!data.length) {
        setEditing(true)
        setSubscription(emptyCSVSubscription)
        return
      }

      setSubscription(data[0])
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        title: t('organizer:syncing:failedToFetchParticipantsSource'),
        status: 'error',
      })
    }
  }, [eventID, t, toast])
  const {handleSubmit: fetch, loading: fetching} = useLoadingState(_fetch)

  React.useEffect(() => {
    fetch()
  }, [fetch])

  React.useEffect(() => {
    setInput(subscription)
  }, [subscription])

  const onComplete = React.useCallback(() => {
    fetch()
    setEditing(false)
  }, [fetch])

  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertBridgeSubscription,
    params: inputToUpsertArgs(input, eventID),
    mode: input.id ? 'update' : 'add',
    onComplete,
  })

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handleSourceParamsChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement>) => {
      setInput({...input, source_params: {...input.source_params, [id]: value}} as ParticipantsSubscription)
    },
    [input]
  )

  const handleSourceChange = React.useCallback((source: BridgeSubscriptionSource) => {
    let emptySubscription: ParticipantsSubscription
    switch (source) {
      case BridgeSubscriptionSource.CSV:
        emptySubscription = emptyCSVSubscription
        break
      case BridgeSubscriptionSource.Spreadsheet:
        emptySubscription = emptySpreadsheetSubscription
        break
    }
    setInput((prev) => ({...emptySubscription, name: prev.name, id: prev.id}))
  }, [])

  const handleEditingChange = React.useCallback(() => {
    editing && setInput(subscription)
    setEditing((prev) => !prev)
  }, [editing, subscription])

  const isURLValid = React.useMemo(() => {
    if (input.source === BridgeSubscriptionSource.CSV)
      return !!input.source_params.csv_source && isURL(input.source_params.csv_source)
    return true
  }, [input])

  const areSourceParamsValid = React.useMemo(() => {
    switch (input.source) {
      case BridgeSubscriptionSource.CSV:
        return isURLValid
      case BridgeSubscriptionSource.Spreadsheet:
        return !!input.source_params.api_key && !!input.source_params.sheet_id
    }
  }, [input, isURLValid])

  const isSubmitDisabled = React.useMemo(
    () => !input.name || !eventID || !input.source || !input.status || !areSourceParamsValid,
    [input, areSourceParamsValid, eventID]
  )

  return (
    <Stack border="1px solid" borderColor="whiteAlpha.300" borderRadius="lg" overflow="hidden" spacing="0">
      <HStack
        justify="space-between"
        p={2}
        pl={4}
        bgColor="gray.900"
        borderBottom="1px solid"
        borderColor="whiteAlpha.300"
      >
        <HStack spacing={4}>
          <Heading size="md">{t('organizer:syncing:participantsSource')}</Heading>
          {fetching ? (
            <Spinner />
          ) : (
            <HStack>
              <SourceButton
                source={BridgeSubscriptionSource.CSV}
                currentSource={input.source}
                onClick={handleSourceChange}
                isDisabled={!editing || loading}
              >
                CSV
              </SourceButton>
              <SourceButton
                source={BridgeSubscriptionSource.Spreadsheet}
                currentSource={input.source}
                onClick={handleSourceChange}
                isDisabled={!editing || loading}
              >
                Google Spreadsheet
              </SourceButton>
            </HStack>
          )}
        </HStack>
        {editing ? (
          <HStack>
            <Tooltip label={t('common:actions:save')}>
              <Button p={1} isLoading={loading} onClick={handleRPC} isDisabled={isSubmitDisabled || fetching}>
                <CheckIcon />
              </Button>
            </Tooltip>
            <Tooltip label={t('common:actions:cancel')}>
              <Button p={1} isDisabled={loading} onClick={handleEditingChange}>
                <CloseIcon />
              </Button>
            </Tooltip>
          </HStack>
        ) : (
          <Tooltip label={t('common:actions:edit')}>
            <Button p={1} onClick={handleEditingChange} isDisabled={fetching || loading}>
              <EditIcon />
            </Button>
          </Tooltip>
        )}
      </HStack>
      <Stack p={4} bgColor="gray.800">
        {fetching ? (
          <Spinner />
        ) : (
          <>
            <FormControl id="name" isDisabled={loading} isRequired={editing} isReadOnly={!editing}>
              <FormLabel>{t('common:fields:name')}</FormLabel>
              <Input value={input.name ?? ''} onChange={handleInputChange} />
            </FormControl>
            {input.source === BridgeSubscriptionSource.CSV && (
              <Stack>
                <Stack spacing={4} align="center" width="100%">
                  <FormControl
                    id="csv_source"
                    isDisabled={loading}
                    isRequired={editing}
                    isInvalid={!!input.source_params.csv_source && !isURLValid}
                    isReadOnly={!editing}
                  >
                    <FormLabel>{t('admin:events:importParticipants:modal:sourceCSV')}</FormLabel>
                    <Input value={input.source_params.csv_source ?? ''} onChange={handleSourceParamsChange} />
                    <FormErrorMessage>{t('errors:invalidURL')}</FormErrorMessage>
                  </FormControl>
                </Stack>
              </Stack>
            )}
            {input.source === BridgeSubscriptionSource.Spreadsheet && (
              <Stack>
                <Stack spacing={4} align="center" width="100%">
                  <FormControl id="sheet_id" isDisabled={loading} isRequired={editing} isReadOnly={!editing}>
                    <FormLabel>Spreadsheet ID</FormLabel>
                    <Input value={input.source_params.sheet_id ?? ''} onChange={handleSourceParamsChange} />
                  </FormControl>
                  <FormControl id="api_key" isDisabled={loading} isRequired={editing} isReadOnly={!editing}>
                    <FormLabel>Google API key</FormLabel>
                    <Input value={input.source_params.api_key ?? ''} onChange={handleSourceParamsChange} />
                  </FormControl>
                </Stack>
              </Stack>
            )}
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default ParticipantsSourceForm
