import * as React from 'react'

import {Box, Heading, useDisclosure} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {EPCTag} from '@/api/models'
import DataTable from '@/common/data-table'
import {Column} from '@/common/data-table/types'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import {FilterField} from '@/common/filter-builder/types'
import {useSupabaseQuery} from '@/common/hooks'

import {EditorMode} from '../types'
import {emptyEPCTag} from './constants'
import DeleteEPCTagModal from './delete-modal'
import EPCTagEditorModal from './editor-modal'

const EPCTags = () => {
  const {t} = useTranslation()
  const tableState = useTableState({sortBy: 'created_at'})
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [currentEPCTag, setCurrentEPCTag] = React.useState<EPCTag>(emptyEPCTag)
  const [currentMode, setCurrentMode] = React.useState<EditorMode>('add')
  const {loading, data, fetch, rows} = useSupabaseQuery<EPCTag>({
    fields: '*',
    table: 'admin_epc_tags',
    order: tableState.sortBy.column as keyof EPCTag,
    itemsPerPage: tableState.rowsPerPage,
    pageNumber: tableState.page,
    descending: tableState.descending,
    filter: tableState.filter,
  })

  const columns: Column<EPCTag>[] = React.useMemo(
    () => [
      {
        Header: 'EPC',
        id: 'epc',
        renderAs: 'code',
        sortable: false,
      },
      {
        Header: t('common:entities:organizer').toString(),
        id: 'organizer_name',
      },
      {
        Header: t('common:fields:description').toString(),
        id: 'description',
        renderAs: 'longString',
      },
      {
        Header: t('common:fields:createdAt').toString(),
        id: 'created_at',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:updatedAt').toString(),
        id: 'updated_at',
        renderAs: 'timestamp',
      },
    ],
    [t]
  )

  const filterFields: FilterField[] = React.useMemo(
    () => [
      {
        label: t('common:entities:organizer'),
        name: 'organizer_name',
        variant: 'text',
      },
      {
        label: t('common:fields:createdAt'),
        name: 'created_at',
        variant: 'datetime',
      },
      {
        label: t('common:fields:updatedAt'),
        name: 'updated_at',
        variant: 'datetime',
      },
    ],
    [t]
  )

  const handleAdd = React.useCallback(() => {
    setCurrentEPCTag(emptyEPCTag)
    setCurrentMode('add')
    onEditorOpen()
  }, [onEditorOpen])

  const handleEdit = React.useCallback(
    (item: EPCTag) => {
      setCurrentEPCTag(item)
      setCurrentMode('update')
      onEditorOpen()
    },
    [onEditorOpen]
  )

  const handleDelete = React.useCallback(
    (item: EPCTag) => {
      setCurrentEPCTag(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  const actions = React.useMemo(
    () => [editAction(handleEdit), deleteAction(handleDelete)],
    [handleEdit, handleDelete]
  )

  return (
    <Box>
      <Heading fontSize="xl" mb={4}>
        {t('admin:menu:epcTags')}
      </Heading>
      <DataTable<EPCTag>
        columns={columns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        loading={loading}
        onRefresh={fetch}
        onAdd={handleAdd}
        filterFields={filterFields}
      />
      <EPCTagEditorModal
        item={currentEPCTag}
        open={isEditorOpen}
        onClose={onEditorClose}
        onComplete={fetch}
        mode={currentMode}
      />
      <DeleteEPCTagModal
        epc={currentEPCTag.epc}
        open={isDeleteOpen}
        onClose={onDeleteClose}
        onComplete={fetch}
      />
    </Box>
  )
}

export default EPCTags
