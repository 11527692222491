import * as React from 'react'

import {AddIcon, CloseIcon, RepeatIcon} from '@chakra-ui/icons'
import {HStack, IconButton, Heading, useDisclosure, Collapse, Flex, Skeleton} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'

import {ParticipantAssignedClass} from '@/api/models'
import {useSupabaseQuery} from '@/common/hooks'
import {EventContext} from '@/organizer/events/page'

import ClassItem from './class-item'
import {emptyAssignedClass} from './constants'

const ClassesManager = ({participantID}: {participantID: string}) => {
  const {t} = useTranslation()
  const {event} = React.useContext(EventContext)
  const {isOpen, onToggle, onClose} = useDisclosure()

  const {
    loading,
    data: assignments,
    fetch,
  } = useSupabaseQuery<ParticipantAssignedClass>({
    fields: '*',
    table: 'event_participant_classes_joined',
    order: 'end_time',
    descending: true,
    filter: React.useCallback(
      (b: PostgrestFilterBuilder<ParticipantAssignedClass>) =>
        b.match({participant_id: participantID, event_id: event.id}),
      [participantID, event]
    ),
  })

  return (
    <Flex direction="column" w="100%">
      <HStack>
        <Heading size="md">{t('participants:assignedClasses')}</Heading>
        <IconButton
          aria-label="add-car"
          icon={isOpen ? <CloseIcon /> : <AddIcon />}
          onClick={onToggle}
          size="sm"
          isDisabled={loading}
        />
        <IconButton
          aria-label="refetch-car"
          icon={<RepeatIcon />}
          onClick={fetch}
          size="sm"
          isLoading={loading}
        />
      </HStack>
      {loading ? (
        <>
          <Skeleton h="146px" mt={2} borderRadius="lg" />
          <Skeleton h="146px" mt={2} borderRadius="lg" />
        </>
      ) : (
        <>
          <Collapse in={isOpen} unmountOnExit={true}>
            <ClassItem
              item={emptyAssignedClass}
              participantID={participantID}
              onComplete={fetch}
              onClose={onClose}
            />
          </Collapse>

          {assignments?.map((a, i) => (
            <ClassItem key={i} item={a} participantID={participantID} onComplete={fetch} />
          ))}
        </>
      )}
    </Flex>
  )
}

export default ClassesManager
