import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Input,
  Checkbox,
  Select,
  Textarea,
  FormHelperText,
  Box,
  useToast,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {ImageType} from 'react-images-uploading'
import {v4 as uuidv4} from 'uuid'

import {EditorMode} from '@/admin/types'
import {AdminEvent, TrackLayout, Serie, RPC} from '@/api/models'
import {uploadFile} from '@/api/utils'
import {TimeRangeInput, ImageUpload} from '@/common/components'
import {useSupabaseQuery, useSupabaseRPC, useLoadingState} from '@/common/hooks'
import {SUPABASE_EVENTS_BUCKET} from '@/constants'
import {CommonModalProps} from '@/utils/types'

import {emptyEvent} from '../constants'
import {inputToUpsertArgs} from './utils'

const EventEditorModal = ({
  onClose,
  open,
  item,
  onComplete,
  mode,
}: CommonModalProps & {item: AdminEvent; mode: EditorMode}) => {
  const {t} = useTranslation()
  const toast = useToast()
  const [input, setInput] = React.useState(emptyEvent)
  const [image, setImage] = React.useState<ImageType>()

  const {loading: seriesLoading, data: series} = useSupabaseQuery<Serie>({
    fields: 'id, name',
    table: 'series',
    order: 'name',
  })
  const {loading: layoutsLoading, data: layouts} = useSupabaseQuery<TrackLayout>({
    fields: 'id, name',
    table: 'track_layouts',
    order: 'name',
  })
  const {handleRPC} = useSupabaseRPC({
    fnName: RPC.UpsertEvent,
    params: {},
    mode,
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
    item.picture_url ? setImage({dataURL: item.picture_url}) : setImage(undefined)
  }, [item, open])

  const _handleSubmit = React.useCallback(async () => {
    try {
      const id = input.id || uuidv4()
      const filepath = image?.file
        ? await uploadFile(SUPABASE_EVENTS_BUCKET, id, image?.file)
        : image?.dataURL
        ? input.picture
        : undefined
      await handleRPC(inputToUpsertArgs({...input, id, picture: filepath}))
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        status: 'error',
        title: t(`common:dataTable:${mode}Fail`),
      })
    }
  }, [handleRPC, image, input, mode, toast, t])
  const {handleSubmit, loading} = useLoadingState(_handleSubmit)

  const handleInputChange = React.useCallback(
    ({
      target: {id, value},
    }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handlePublicChange = React.useCallback(
    ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) =>
      setInput((input) => ({...input, public: checked})),
    []
  )

  const handleTimeChange = React.useCallback((id: string, value: Date) => {
    setInput((input) => ({...input, [id]: value}))
  }, [])

  const handleLapTimeChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement>) => {
      setInput((input) => ({...input, [id]: +value * 1000}))
    },
    []
  )

  const minLapTimeInput = React.useMemo(
    () => Math.floor(input.min_lap_time / 1000) || '',
    [input.min_lap_time]
  )
  const maxLapTimeInput = React.useMemo(
    () => Math.floor(input.max_lap_time / 1000) || '',
    [input.max_lap_time]
  )
  const isTimeValid = React.useMemo(
    () => new Date(input.start_time) < new Date(input.end_time),
    [input.start_time, input.end_time]
  )

  const isSubmitDisabled = React.useMemo(
    () =>
      !input.name ||
      !input.description ||
      !input.serie_id ||
      !input.layout_id ||
      !input.start_time ||
      !input.end_time ||
      !isTimeValid,
    [input, isTimeValid]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`admin:events:form:addEvent`)
            // t(`admin:events:form:updateEvent`)
            t(`admin:events:form:${mode}Event`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack px={2} py={2}>
            <Stack spacing={4} align="center" width="100%">
              <FormControl id="serie_id" isDisabled={seriesLoading || loading} isRequired={true}>
                <FormLabel>{t('common:entities:serie')}</FormLabel>
                <Select value={input.serie_id ?? ''} onChange={handleInputChange}>
                  <option value="">{t('admin:events:form:selectSerie')}</option>
                  {!seriesLoading &&
                    series?.map((s) => (
                      <option value={s.id} key={s.id}>
                        {s.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <FormControl id="layout_id" isDisabled={layoutsLoading || loading} isRequired={true}>
                <FormLabel>{t('common:entities:trackLayout')}</FormLabel>
                <Select value={input.layout_id ?? ''} onChange={handleInputChange}>
                  <option value="">{t('admin:events:form:selectLayout')}</option>
                  {!layoutsLoading &&
                    layouts?.map((l) => (
                      <option value={l.id} key={l.id}>
                        {l.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <FormControl id="name" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:name')}</FormLabel>
                <Input value={input.name ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl id="description" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:description')}</FormLabel>
                <Textarea
                  value={input.description ?? ''}
                  onChange={handleInputChange}
                  required={true}
                  resize="vertical"
                />
              </FormControl>
              <TimeRangeInput
                startTime={input.start_time}
                endTime={input.end_time}
                isDisabled={loading}
                onTimeChange={handleTimeChange}
              />
              <HStack w="100%">
                <FormControl id="min_lap_time" isDisabled={loading} isRequired={true}>
                  <FormLabel>{t('events:fields:minLapTime')}</FormLabel>
                  <Input value={minLapTimeInput} onChange={handleLapTimeChange} type="number" />
                  <FormHelperText>{t('events:form:inSeconds')}</FormHelperText>
                </FormControl>
                <FormControl id="max_lap_time" isDisabled={loading} isRequired={true}>
                  <FormLabel>{t('events:fields:maxLapTime')}</FormLabel>
                  <Input value={maxLapTimeInput} onChange={handleLapTimeChange} type="number" />
                  <FormHelperText>{t('events:form:inSeconds')}</FormHelperText>
                </FormControl>
              </HStack>
              <FormControl id="public" isDisabled={loading}>
                <Checkbox isChecked={input.public ?? false} onChange={handlePublicChange}>
                  {t('common:fields:public')}
                </Checkbox>
              </FormControl>
              <Box w="100%">
                <ImageUpload onChange={setImage} value={image} editing={true} />
              </Box>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleSubmit} isDisabled={isSubmitDisabled}>
              {t('common:actions:save')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EventEditorModal
