import * as React from 'react'

import {CloseIcon} from '@chakra-ui/icons'
import {HStack, FormControl, FormLabel, Input, Select, IconButton, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {userRoleItems} from '@/admin/organizers/constants'
import {AdminOrganizerUser} from '@/api/models'

import {isOrganizerUserRole} from '../utils'

type Props = {
  user: AdminOrganizerUser
  index: number
  loading: boolean
  onDelete: (index: number) => void
  onChange: (value: AdminOrganizerUser, index: number) => void
}

const UserItem = ({user, index, loading, onChange, onDelete}: Props) => {
  const {t} = useTranslation()

  const handleEmailChange = React.useCallback(
    ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange({...user, email: value}, index)
    },
    [onChange, index, user]
  )

  const handleRoleChange = React.useCallback(
    ({target: {value}}: React.ChangeEvent<HTMLSelectElement>) => {
      if (!isOrganizerUserRole(value)) return
      onChange && onChange({...user, role: value}, index)
    },
    [onChange, index, user]
  )

  const handleDelete = React.useCallback(() => {
    onDelete && onDelete(index)
  }, [onDelete, index])

  return (
    <HStack w="100%" align="flex-end">
      <FormControl isDisabled={loading}>
        <FormLabel>{t('organizer:member')}</FormLabel>
        <Input
          placeholder={t('common:forms:emailPlaceholder')}
          value={user.email}
          onChange={handleEmailChange}
        />
      </FormControl>
      <FormControl isDisabled={loading}>
        <FormLabel>{t('common:fields:role')}</FormLabel>
        <Select value={user.role} onChange={handleRoleChange}>
          {userRoleItems.map((r) => (
            <option key={r.value} value={r.value}>
              {r.label}
            </option>
          ))}
        </Select>
      </FormControl>
      <IconButton
        aria-label="delete-member"
        onClick={handleDelete}
        icon={<CloseIcon />}
        isDisabled={loading}
      />
    </HStack>
  )
}

export default UserItem
