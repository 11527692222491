import * as React from 'react'

import {AspectRatio, Box, Flex, Image, Text, useColorModeValue} from '@chakra-ui/react'
import {FaLocationArrow, FaClock, FaTrophy, FaChevronRight} from 'react-icons/fa'
import {Link} from 'react-router-dom'

import {Event} from '@/api/models'
import lodz from '@/common/imgs/lodz.png'
import {formatTimestamp} from '@/utils/string'

const EventTile = ({event}: {event: Event}) => {
  return (
    <Flex w="full" alignItems="center" justifyContent="center" as={Link} to={`/events/${event.id}`}>
      <Box
        w="100%"
        borderWidth="1px"
        rounded="lg"
        shadow="lg"
        position="relative"
        transition="box-shadow 0.3s"
        _hover={{
          cursor: 'pointer',
          shadow: 'dark-lg',
        }}
      >
        <Flex flex={1} justify="center" align="center" position="relative" w="full">
          <AspectRatio w="100%" ratio={16 / 9} roundedTop="lg" overflow="hidden">
            {event.picture_url ? (
              <Image src={event.picture_url} alt="Event thumbnail" />
            ) : (
              <Flex bgColor="blue.900" w="100%" h="100%">
                <Text fontSize="xl" fontWeight="semibold" as="h4" lineHeight="tight" noOfLines={1}>
                  {event.name}
                </Text>
              </Flex>
            )}
          </AspectRatio>
          {event.serie?.logo_url && (
            <Image
              src={event.serie?.logo_url}
              maxWidth={20}
              maxHeight={20}
              position="absolute"
              top={4}
              right={4}
            />
          )}
          {event.track_layout?.image_url && (
            <Image
              src={event.track_layout?.image_url}
              maxWidth="100px"
              maxHeight="50px"
              position="absolute"
              bottom={4}
              left={4}
            />
          )}
        </Flex>

        <Flex p={4} direction="row">
          <Box flexGrow={1}>
            <Flex justifyContent="space-between" alignContent="center">
              <Text fontSize="md" fontWeight="semibold" as="h4" lineHeight="tight" noOfLines={1}>
                {event.name}
              </Text>
            </Flex>

            <Flex
              fontSize="sm"
              lineHeight="tight"
              alignItems="center"
              color={useColorModeValue('gray.500', 'whiteAlpha.600')}
            >
              <FaLocationArrow />
              <Text ml={2}>
                {event.track_layout?.name ?? 'Nieznany'}
                {!!event.track_layout?.length && ` (${event.track_layout?.length}m)`}
              </Text>
            </Flex>

            <Flex
              fontSize="sm"
              lineHeight="tight"
              alignItems="center"
              color={useColorModeValue('gray.500', 'whiteAlpha.600')}
            >
              <FaTrophy />
              <Text ml={2}>{event.serie?.name ?? 'Wydarzenie niezależne'}</Text>
            </Flex>

            <Flex
              fontSize="sm"
              lineHeight="tight"
              alignItems="center"
              color={useColorModeValue('gray.500', 'whiteAlpha.600')}
            >
              <FaClock />
              <Text ml={2}>{formatTimestamp(event.start_time)}</Text>
            </Flex>
          </Box>
          <Flex alignItems="center" justifyContent="center" flexGrow={0} ml={4}>
            <FaChevronRight size={20} color={useColorModeValue('#A0AEC0', 'rgba(255, 255, 255, 0.5)')} />
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )
}

export default EventTile
