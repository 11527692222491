// Recharts brush operates on indexes, so in order to zoom in we need some fake timestamps
export const generateTimestamps = (from: Date, to: Date, interval: number) => {
  // don't generate if interval is less than 1s
  if (interval < 60 * 1000) return []

  const start = new Date(from).getTime()
  const end = new Date(to).getTime()

  const res: number[] = []
  const n = Math.floor((end - start) / interval)
  for (let i = 0; i < n; i++) {
    res.push(start + i * interval)
  }
  res.push(end)

  return res
}
