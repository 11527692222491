/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'

import en from './translations/en'
import pl from './translations/pl'

const resources = {
  en,
  pl,
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: {
      escapeValue: false, // redundant with react
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    lng: localStorage.getItem('i18nextLng') ?? 'pl',
    resources,
  })

export default i18n
