import * as React from 'react'

import {Heading, Stack} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'

import {OrganizerSerie} from '@/api/models'
import {useSupabaseQuery} from '@/common/hooks'
import LoadingView from '@/common/loading-view'
import SerieTile from '@/series/serie-tile'
import {useAppSelector} from '@/store'

import {selectOrganizer} from '../state'

const Series = () => {
  const {t} = useTranslation()
  const organizer = useAppSelector(selectOrganizer)

  const {loading, data} = useSupabaseQuery<OrganizerSerie>({
    fields: '*',
    table: 'organizer_series',
    order: 'start_date',
    descending: true,
    filter: React.useCallback(
      (b: PostgrestFilterBuilder<OrganizerSerie>) => b.eq('organizer_id', organizer?.id ?? ''),
      [organizer]
    ),
  })

  if (loading) {
    return <LoadingView />
  }

  return (
    <Stack spacing={4}>
      <Heading>{t('organizer:series:manageSeries')}</Heading>
      {data
        ?.filter((s) => !!s.events.length)
        .map((s) => (
          <SerieTile key={s.id} serie={s} organizerID={organizer?.id} />
        ))}
    </Stack>
  )
}

export default Series
