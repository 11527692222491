import {Participant} from '@/api/models'

export const inputToUpsertArgs = (input: Participant) => ({
  participant_id: input.id || undefined,
  name: input.name,
  first_name: input.first_name,
  number: input.number,
  serie_id: input.serie_id,
  user_email: input.user_email || undefined,
})
