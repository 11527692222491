import * as React from 'react'

import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {ImageType} from 'react-images-uploading'

import {OrganizerSerie, RPC} from '@/api/models'
import {uploadFile} from '@/api/utils'
import {selectSelectedIdentity} from '@/auth/state'
import {ImageUpload} from '@/common/components'
import {useSupabaseRPC, useLoadingState} from '@/common/hooks'
import {SUPABASE_SERIES_BUCKET} from '@/constants'
import {emptySerie} from '@/organizer/constants'
import {useAppSelector} from '@/store'
import {readOnlyStyles} from '@/theme/components/input'
import {formatTimestamp} from '@/utils/string'

import {inputToUpsertArgs} from './utils'

const SerieForm = ({item, refetch}: {item: OrganizerSerie; refetch: () => Promise<void>}) => {
  const {t} = useTranslation()
  const toast = useToast()
  const selectedIdentity = useAppSelector(selectSelectedIdentity)
  const [editing, setEditing] = React.useState(false)
  const [input, setInput] = React.useState(emptySerie)
  const [image, setImage] = React.useState<ImageType>()

  const onComplete = React.useCallback(() => {
    setEditing(false)
    refetch()
  }, [refetch])

  const {handleRPC} = useSupabaseRPC({
    fnName: RPC.UpsertSerie,
    params: {},
    mode: 'update',
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
    item.logo_url ? setImage({dataURL: item.logo_url}) : setImage(undefined)
  }, [item])

  const _handleSubmit = React.useCallback(async () => {
    try {
      const filepath = image?.file
        ? await uploadFile(SUPABASE_SERIES_BUCKET, input.id, image?.file)
        : image?.dataURL
        ? input.logo
        : undefined
      await handleRPC(inputToUpsertArgs({...input, logo: filepath}))
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        status: 'error',
        title: t('common:dataTable:updateFail'),
      })
    }
  }, [handleRPC, image, input, toast, t])
  const {handleSubmit, loading} = useLoadingState(_handleSubmit)

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handlePublicChange = React.useCallback(
    ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) =>
      setInput((input) => ({...input, public: checked})),
    []
  )

  const handleModeChange = React.useCallback(() => {
    if (editing) {
      item && setInput(item)
      item.logo_url ? setImage({dataURL: item.logo_url}) : setImage(undefined)
    }
    setEditing(!editing)
  }, [editing, item])

  const isDateValid = React.useMemo(() => new Date(input.start_date) < new Date(input.end_date), [input])

  const isSubmitDisabled = React.useMemo(
    () => !input.name || !input.start_date || !input.end_date || !isDateValid,
    [input, isDateValid]
  )

  return (
    <Stack p={4} bgColor="gray.900" borderRadius="2xl" position="relative" boxShadow="lg">
      {selectedIdentity?.role === 'admin' && (
        <Flex gap={4} position="absolute" top={4} right={4} zIndex="modal">
          {editing && (
            <Button
              variant="solid"
              colorScheme="blue"
              onClick={handleSubmit}
              isDisabled={isSubmitDisabled}
              isLoading={loading}
            >
              {t('common:actions:save')}
            </Button>
          )}
          <Button
            variant={editing ? 'ghost' : 'solid'}
            colorScheme="blue"
            onClick={handleModeChange}
            isDisabled={loading}
          >
            {editing ? t('common:actions:cancel') : t('common:actions:edit')}
          </Button>
        </Flex>
      )}
      <HStack spacing={4}>
        <Box w="100%">
          <ImageUpload onChange={setImage} value={image} editing={editing} />
        </Box>
        <Stack spacing={4} align="center" width="100%">
          <FormControl id="public" isDisabled={loading || !editing}>
            <Checkbox isChecked={input.public ?? false} onChange={handlePublicChange}>
              {t('common:fields:public')}
            </Checkbox>
          </FormControl>
          <FormControl id="name" isDisabled={loading} isRequired={editing} isReadOnly={!editing}>
            <FormLabel color="whiteAlpha.600">{t('common:fields:name')}</FormLabel>
            <Input value={input.name ?? ''} onChange={handleInputChange} _readOnly={readOnlyStyles} />
          </FormControl>
          <FormControl id="start_date" isDisabled={loading} isRequired={editing} isReadOnly={!editing}>
            <FormLabel color="whiteAlpha.600">{t('common:fields:startDate')}</FormLabel>
            <Input
              value={input.start_date}
              onChange={handleInputChange}
              type="date"
              _readOnly={readOnlyStyles}
            />
          </FormControl>
          <FormControl
            id="end_date"
            isDisabled={loading}
            isRequired={editing}
            isInvalid={!!input.end_date && !isDateValid}
            isReadOnly={!editing}
          >
            <FormLabel color="whiteAlpha.600">{t('common:fields:endDate')}</FormLabel>
            <Input
              value={input.end_date}
              onChange={handleInputChange}
              type="date"
              _readOnly={readOnlyStyles}
            />
            <FormErrorMessage>{t('errors:endDate')}</FormErrorMessage>
          </FormControl>
        </Stack>
      </HStack>
      <HStack color="whiteAlpha.600" w="100%" justify="flex-end">
        <Text>{`${t('common:fields:createdAt')}: ${formatTimestamp(item.created_at)}`}</Text>
        <Text>{`${t('common:fields:updatedAt')}: ${formatTimestamp(item.updated_at)}`}</Text>
      </HStack>
    </Stack>
  )
}

export default SerieForm
