import * as React from 'react'

import {Box, Heading, useDisclosure} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {AdminEvent} from '@/api/models'
import {DeleteResourceModal} from '@/common/components'
import DataTable from '@/common/data-table'
import {Column} from '@/common/data-table/types'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import {FilterField} from '@/common/filter-builder/types'
import {useSupabaseQuery} from '@/common/hooks'

import {EditorMode} from '../types'
import {classAssignmentsAction, importParticipantsAction} from './actions'
import {emptyEvent} from './constants'
import EventEditorModal from './editor-modal'
import ImportParticipantsModal from './import-participants-modal'

const defaultSelectedColumns = ['name', 'serie_name', 'public', 'start_time']

const Events = () => {
  const {t} = useTranslation()
  const tableState = useTableState({sortBy: 'created_at'})
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const {isOpen: isImportOpen, onOpen: onImportOpen, onClose: onImportClose} = useDisclosure()
  const [currentEvent, setCurrentEvent] = React.useState<AdminEvent>(emptyEvent)
  const [currentMode, setCurrentMode] = React.useState<EditorMode>('add')
  const {loading, data, fetch, rows} = useSupabaseQuery<AdminEvent>({
    fields: '*',
    table: 'admin_events',
    order: tableState.sortBy.column as keyof AdminEvent,
    itemsPerPage: tableState.rowsPerPage,
    pageNumber: tableState.page,
    descending: tableState.descending,
    filter: tableState.filter,
  })

  const columns: Column<AdminEvent>[] = React.useMemo(
    () => [
      {
        Header: t('common:fields:id').toString(),
        id: 'id',
        renderAs: 'code',
        sortable: false,
      },
      {
        Header: t('common:fields:name').toString(),
        id: 'name',
      },
      {
        Header: t('common:entities:serie').toString(),
        id: 'serie_name',
      },
      {
        Header: t('common:entities:trackLayout').toString(),
        id: 'layout_name',
      },
      {
        Header: t('common:fields:description').toString(),
        id: 'description',
        renderAs: 'longString',
      },
      {
        Header: t('common:fields:public').toString(),
        id: 'public',
        renderAs: 'boolean',
      },
      {
        Header: t('common:fields:startTime').toString(),
        id: 'start_time',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:endTime').toString(),
        id: 'end_time',
        renderAs: 'timestamp',
      },
      {
        Header: t('events:fields:maxLapTime').toString(),
        id: 'max_lap_time',
        renderAs: 'time',
      },
      {
        Header: t('events:fields:minLapTime').toString(),
        id: 'min_lap_time',
        renderAs: 'time',
      },
      {
        Header: t('common:fields:createdAt').toString(),
        id: 'created_at',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:updatedAt').toString(),
        id: 'updated_at',
        renderAs: 'timestamp',
      },
    ],
    [t]
  )

  const filterFields: FilterField[] = React.useMemo(
    () => [
      {
        label: t('common:fields:name'),
        name: 'name',
        variant: 'text',
      },
      {
        label: t('common:entities:serie'),
        name: 'serie_name',
        variant: 'text',
      },
      {
        label: t('common:entities:trackLayout'),
        name: 'layout_name',
        variant: 'text',
      },
      {
        label: t('common:fields:public'),
        name: 'public',
        variant: 'boolean',
      },
      {
        label: t('common:fields:startTime'),
        name: 'start_time',
        variant: 'datetime',
      },
      {
        label: t('common:fields:endTime'),
        name: 'end_time',
        variant: 'datetime',
      },
      {
        label: t('events:fields:maxLapTime'),
        name: 'max_lap_time',
        variant: 'datetime',
      },
      {
        label: t('events:fields:minLapTime'),
        name: 'min_lap_time',
        variant: 'datetime',
      },
      {
        label: t('common:fields:createdAt'),
        name: 'created_at',
        variant: 'datetime',
      },
      {
        label: t('common:fields:updatedAt'),
        name: 'updated_at',
        variant: 'datetime',
      },
    ],
    [t]
  )

  const handleAdd = React.useCallback(() => {
    setCurrentEvent(emptyEvent)
    setCurrentMode('add')
    onEditorOpen()
  }, [onEditorOpen])

  const handleEdit = React.useCallback(
    (item: AdminEvent) => {
      setCurrentEvent(item)
      setCurrentMode('update')
      onEditorOpen()
    },
    [onEditorOpen]
  )

  const handleDelete = React.useCallback(
    (item: AdminEvent) => {
      setCurrentEvent(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  const handleImportParticipants = React.useCallback(
    (item: AdminEvent) => {
      setCurrentEvent(item)
      onImportOpen()
    },
    [onImportOpen]
  )

  const actions = React.useMemo(
    () => [
      classAssignmentsAction,
      importParticipantsAction(handleImportParticipants),
      editAction(handleEdit),
      deleteAction(handleDelete),
    ],
    [handleEdit, handleDelete, handleImportParticipants]
  )

  return (
    <Box>
      <Heading fontSize="xl" mb={4}>
        {t('admin:menu:events')}
      </Heading>
      <DataTable<AdminEvent>
        columns={columns}
        defaultSelectedColumns={defaultSelectedColumns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        loading={loading}
        onRefresh={fetch}
        onAdd={handleAdd}
        filterFields={filterFields}
      />
      <EventEditorModal
        item={currentEvent}
        onClose={onEditorClose}
        open={isEditorOpen}
        onComplete={fetch}
        mode={currentMode}
      />
      <ImportParticipantsModal id={currentEvent.id} onClose={onImportClose} open={isImportOpen} />
      <DeleteResourceModal
        table="events"
        id={currentEvent.id}
        onClose={onDeleteClose}
        open={isDeleteOpen}
        onComplete={fetch}
      />
    </Box>
  )
}

export default Events
