import {add} from 'date-fns'

import {ParticipantAssignedClass} from '@/api/models'

export const emptyAssignedClass: ParticipantAssignedClass = {
  class_assignment_id: '',
  class_id: '',
  name: '',
  start_time: new Date(),
  end_time: add(new Date(), {hours: 2}),
}
