import * as React from 'react'

import {Container, Grid, GridItem, useBreakpointValue} from '@chakra-ui/react'

import {Event} from '@/api/models'
import {useSupabaseQuery} from '@/common/hooks'

import EventTile from './event-tile'

const Events = () => {
  const {loading, data, fetch, rows} = useSupabaseQuery<Event>({
    fields: '*',
    table: 'public_events',
  })

  return (
    <Container maxW="container.xl" px={8}>
      <Grid
        templateColumns={useBreakpointValue({
          md: 'repeat(8, 1fr)',
          sm: 'repeat(4, 1fr)',
          lg: 'repeat(12, 1fr)',
        })}
        gap={4}
        mb={8}
      >
        {data?.map((e) => (
          <GridItem key={e.id} colSpan={4}>
            <EventTile event={e} />
          </GridItem>
        ))}
      </Grid>
    </Container>
  )
}

export default Events
