import * as React from 'react'

import {Box, Heading, useDisclosure} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {DeviceAssignment} from '@/api/models'
import {DeleteResourceModal} from '@/common/components'
import DataTable from '@/common/data-table'
import {Column} from '@/common/data-table/types'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import {FilterField} from '@/common/filter-builder/types'
import {useSupabaseQuery} from '@/common/hooks'

import {EditorMode} from '../types'
import {emptyDeviceAssignment} from './constants'
import DeviceAssignmentEditorModal from './editor-modal'

const defaultSelectedColumns = ['event_name', 'device_name', 'type', 'start_time', 'end_time']

const DeviceAssignments = () => {
  const {t} = useTranslation()
  const tableState = useTableState({sortBy: 'created_at'})
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [currentDeviceAssignment, setCurrentDeviceAssignment] =
    React.useState<DeviceAssignment>(emptyDeviceAssignment)
  const [currentMode, setCurrentMode] = React.useState<EditorMode>('add')
  const {loading, data, fetch, rows} = useSupabaseQuery<DeviceAssignment>({
    fields: '*',
    table: 'admin_device_assignments',
    order: tableState.sortBy.column as keyof DeviceAssignment,
    itemsPerPage: tableState.rowsPerPage,
    pageNumber: tableState.page,
    descending: tableState.descending,
    filter: tableState.filter,
  })

  const columns: Column<DeviceAssignment>[] = React.useMemo(
    () => [
      {
        Header: t('common:fields:id').toString(),
        id: 'id',
        renderAs: 'code',
        sortable: false,
      },
      {
        Header: t('common:entities:event').toString(),
        id: 'event_name',
      },
      {
        Header: t('common:entities:device').toString(),
        id: 'device_name',
      },
      {
        Header: t('admin:deviceAssignments:fields:type').toString(),
        id: 'type',
      },
      {
        Header: t('common:fields:startTime').toString(),
        id: 'start_time',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:endTime').toString(),
        id: 'end_time',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:createdAt').toString(),
        id: 'created_at',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:updatedAt').toString(),
        id: 'updated_at',
        renderAs: 'timestamp',
      },
    ],
    [t]
  )

  const filterFields: FilterField[] = React.useMemo(
    () => [
      {
        label: t('common:entities:event'),
        name: 'event_name',
        variant: 'text',
      },
      {
        label: t('common:entities:device'),
        name: 'device_name',
        variant: 'text',
      },
      {
        label: t('common:fields:type'),
        name: 'type',
        variant: 'text',
      },
      {
        label: t('common:fields:startTime'),
        name: 'start_time',
        variant: 'datetime',
      },
      {
        label: t('common:fields:endTime'),
        name: 'end_time',
        variant: 'datetime',
      },
      {
        label: t('common:fields:createdAt'),
        name: 'created_at',
        variant: 'datetime',
      },
      {
        label: t('common:fields:updatedAt'),
        name: 'updated_at',
        variant: 'datetime',
      },
    ],
    [t]
  )

  const handleAdd = React.useCallback(() => {
    setCurrentDeviceAssignment(emptyDeviceAssignment)
    setCurrentMode('add')
    onEditorOpen()
  }, [onEditorOpen])

  const handleEdit = React.useCallback(
    (item: DeviceAssignment) => {
      setCurrentDeviceAssignment(item)
      setCurrentMode('update')
      onEditorOpen()
    },
    [onEditorOpen]
  )

  const handleDelete = React.useCallback(
    (item: DeviceAssignment) => {
      setCurrentDeviceAssignment(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  const actions = React.useMemo(
    () => [editAction(handleEdit), deleteAction(handleDelete)],
    [handleEdit, handleDelete]
  )

  return (
    <Box>
      <Heading fontSize="xl" mb={4}>
        {t('admin:menu:deviceAssignments')}
      </Heading>
      <DataTable<DeviceAssignment>
        columns={columns}
        defaultSelectedColumns={defaultSelectedColumns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        loading={loading}
        onRefresh={fetch}
        onAdd={handleAdd}
        filterFields={filterFields}
      />
      <DeviceAssignmentEditorModal
        item={currentDeviceAssignment}
        onClose={onEditorClose}
        open={isEditorOpen}
        onComplete={fetch}
        mode={currentMode}
      />
      <DeleteResourceModal
        table="device_assignments"
        id={currentDeviceAssignment.id}
        onClose={onDeleteClose}
        open={isDeleteOpen}
        onComplete={fetch}
      />
    </Box>
  )
}

export default DeviceAssignments
