import * as React from 'react'

import {CloseIcon, CheckIcon, EditIcon, DeleteIcon} from '@chakra-ui/icons'
import {
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {emptyParticipantTag} from '@/admin/participant-tags/constants'
import {ParticipantTag, RPC, Event} from '@/api/models'
import {DeleteResourceModal, TimeRangeInput} from '@/common/components'
import {useSupabaseRPC} from '@/common/hooks'

import {inputToUpsertArgs} from './utils'

type Props = {
  tag: ParticipantTag
  events: Partial<Event>[]
  participantID: string
  onClose?: () => void
  onComplete?: () => void
}

const TagItem = ({tag, events, participantID, onClose, onComplete}: Props) => {
  const {t} = useTranslation()
  const {eventID} = useParams()

  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [input, setInput] = React.useState<ParticipantTag>(emptyParticipantTag)
  const [editing, setEditing] = React.useState(true)

  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertParticipantTag,
    params: inputToUpsertArgs(input, participantID, eventID),
    mode: tag.id ? 'update' : 'add',
    onComplete,
    onClose,
  })

  React.useEffect(() => {
    tag && setInput(tag)
    tag.id && setEditing(false)
  }, [tag])

  const handleModeChange = React.useCallback(() => {
    if (editing) {
      tag && setInput(tag)
      !tag.id && onClose && onClose()
    }
    setEditing((prev) => !prev)
  }, [tag, editing, onClose])

  const handleInputChange = React.useCallback(
    ({
      target: {name, value},
    }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      setInput((input) => ({...input, [name]: value}))
    },
    []
  )

  const handleTimeChange = React.useCallback((id: string, value: Date) => {
    setInput((input) => ({...input, [id]: value}))
  }, [])

  const isTimeValid = React.useMemo(() => new Date(input.start_time) < new Date(input.end_time), [input])

  const isSubmitDisabled = React.useMemo(
    () => !input.epc || !participantID || !(input.event_id || eventID) || !isTimeValid,
    [input, isTimeValid, participantID, eventID]
  )

  return (
    <>
      <Stack
        w="100%"
        mt={2}
        spacing={0}
        border="1px solid"
        borderColor="whiteAlpha.300"
        borderRadius="lg"
        overflow="hidden"
        bgColor="gray.800"
      >
        <HStack bgColor="gray.900" p={2} borderBottom="1px solid" borderColor="whiteAlpha.300">
          <InputGroup>
            <InputLeftAddon>EPC</InputLeftAddon>
            <Input
              name="epc"
              value={input.epc}
              onChange={handleInputChange}
              isDisabled={loading}
              isRequired={editing}
              bgColor="whiteAlpha.200"
              isReadOnly={!editing}
            />
          </InputGroup>
          {editing ? (
            <>
              <IconButton
                aria-label="save"
                icon={<CheckIcon />}
                onClick={handleRPC}
                isDisabled={isSubmitDisabled}
                isLoading={loading}
              />
              <IconButton
                aria-label="cancel"
                icon={<CloseIcon />}
                onClick={handleModeChange}
                isDisabled={loading}
              />
            </>
          ) : (
            <>
              <IconButton aria-label="edit-participant-tag" icon={<EditIcon />} onClick={handleModeChange} />
              <IconButton aria-label="delete-participant-tag" icon={<DeleteIcon />} onClick={onDeleteOpen} />
            </>
          )}
        </HStack>
        <Stack p={2} spacing={1}>
          <HStack>
            <TimeRangeInput
              startTime={input.start_time}
              endTime={input.end_time}
              isReadOnly={!editing}
              isRequired={editing}
              onTimeChange={handleTimeChange}
            />
          </HStack>
          <HStack align="flex-start">
            {!eventID && (
              <FormControl isDisabled={loading || !editing} isRequired={editing}>
                <FormLabel _disabled={{opacity: 1}}>{t('common:entities:event')}</FormLabel>
                <Select
                  name="event_id"
                  value={input.event_id ?? ''}
                  onChange={handleInputChange}
                  _disabled={{opacity: 1}}
                  iconSize={editing ? 'xl' : '0'}
                >
                  <option value="">{t('participants:tags:form:selectEvent')}</option>
                  {events?.map((e) => (
                    <option value={e.id} key={e.id}>
                      {e.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl isDisabled={loading} isReadOnly={!editing}>
              <FormLabel>{t('common:fields:description')}</FormLabel>
              <Textarea
                rows={1}
                name="description"
                resize="vertical"
                overflow="hidden"
                value={input.description ?? ''}
                onChange={handleInputChange}
              />
            </FormControl>
          </HStack>
        </Stack>
      </Stack>
      <DeleteResourceModal
        id={tag.id}
        table="participant_tags"
        open={isDeleteOpen}
        onComplete={onComplete}
        onClose={onDeleteClose}
      />
    </>
  )
}

export default TagItem
