import * as React from 'react'

import {
  Stack,
  FormControl,
  Checkbox,
  FormLabel,
  Input,
  Textarea,
  useToast,
  Flex,
  Button,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {ImageType} from 'react-images-uploading'

import {supabase} from '@/api'
import {uploadFile} from '@/api/utils'
import {selectSelectedIdentity} from '@/auth/state'
import {ImageUpload} from '@/common/components'
import {useLoadingState} from '@/common/hooks'
import {SUPABASE_ORGANIZERS_BUCKET} from '@/constants'
import {fetchOrganizer, selectOrganizer} from '@/organizer/state'
import {useAppSelector, useAppDispatch} from '@/store'
import {readOnlyStyles} from '@/theme/components/input'

import {emptyOrganizer} from '../constants'
import {inputToUpdateArgs} from './utils'

const Details = () => {
  const {t} = useTranslation()
  const toast = useToast()
  const organizer = useAppSelector(selectOrganizer)
  const selectedIdentity = useAppSelector(selectSelectedIdentity)
  const dispatch = useAppDispatch()
  const [editing, setEditing] = React.useState(false)
  const [input, setInput] = React.useState(emptyOrganizer)
  const [image, setImage] = React.useState<ImageType>()

  React.useEffect(() => {
    organizer && setInput(organizer)
    organizer?.logo_url ? setImage({dataURL: organizer?.logo_url}) : setImage(undefined)
  }, [organizer])

  const _handleSubmit = React.useCallback(async () => {
    if (!organizer) return
    try {
      const filepath = image?.file
        ? await uploadFile(SUPABASE_ORGANIZERS_BUCKET, organizer.id, image?.file)
        : image?.dataURL
        ? input.logo
        : undefined

      await supabase
        .from('organizers')
        .update(inputToUpdateArgs({...input, logo: filepath}))
        .eq('id', organizer.id)

      dispatch(fetchOrganizer(organizer.id))
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        status: 'error',
        title: t('organizer:details:editFail'),
      })
    }
  }, [organizer, image, input, toast, t, dispatch])
  const {handleSubmit, loading} = useLoadingState(_handleSubmit)

  const handleModeChange = React.useCallback(() => {
    if (editing && organizer) {
      setInput(organizer)
      organizer.logo_url ? setImage({dataURL: organizer.logo_url}) : setImage(undefined)
    }
    setEditing(!editing)
  }, [editing, organizer])

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handlePublicChange = React.useCallback(
    ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) =>
      setInput((input) => ({...input, public: checked})),
    []
  )

  const isSubmitDisabled = React.useMemo(() => !input.name || !input.website || !input.description, [input])

  return (
    <Stack align="center">
      <Stack spacing={4} w="100%" maxW="container.md">
        {selectedIdentity?.role === 'admin' && (
          <Flex w="100%" justify="flex-end" gap={4}>
            {editing && (
              <Button
                variant="solid"
                colorScheme="blue"
                onClick={handleSubmit}
                isDisabled={isSubmitDisabled}
                isLoading={loading}
              >
                {t('common:actions:save')}
              </Button>
            )}
            <Button
              variant={editing ? 'ghost' : 'solid'}
              colorScheme="blue"
              onClick={handleModeChange}
              isDisabled={loading}
            >
              {editing ? t('common:actions:cancel') : t('common:actions:edit')}
            </Button>
          </Flex>
        )}
        <ImageUpload onChange={setImage} value={image} editing={editing} />
        <FormControl id="name" isDisabled={loading} isRequired={editing} w="100%" isReadOnly={!editing}>
          <FormLabel color="whiteAlpha.600">{t('common:fields:name')}</FormLabel>
          <Input value={input.name ?? ''} onChange={handleInputChange} _readOnly={readOnlyStyles} />
        </FormControl>
        <FormControl id="website" isDisabled={loading} isRequired={editing} isReadOnly={!editing}>
          <FormLabel color="whiteAlpha.600">{t('common:fields:website')}</FormLabel>
          <Input value={input.website ?? ''} onChange={handleInputChange} _readOnly={readOnlyStyles} />
        </FormControl>
        <FormControl id="description" isDisabled={loading} isRequired={editing} isReadOnly={!editing}>
          <FormLabel color="whiteAlpha.600">{t('common:fields:description')}</FormLabel>
          <Textarea
            resize="vertical"
            minH="20vh"
            value={input.description ?? ''}
            onChange={handleInputChange}
            _readOnly={readOnlyStyles}
          />
        </FormControl>
        <FormControl id="public" isDisabled={loading || !editing}>
          <Checkbox isChecked={input.public ?? false} onChange={handlePublicChange}>
            {t('common:fields:public')}
          </Checkbox>
        </FormControl>
      </Stack>
    </Stack>
  )
}

export default Details
