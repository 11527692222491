import {add} from 'date-fns'

import {AdminEvent} from '@/api/models'

export const emptyEvent: AdminEvent = {
  id: '',
  created_at: new Date(),
  updated_at: new Date(),
  name: '',
  description: '',
  public: false,
  picture: undefined,
  start_time: new Date(),
  end_time: add(new Date(), {hours: 2}),
  serie: {id: ''},
  track_layout: {id: ''},
  max_lap_time: 600000,
  min_lap_time: 30000,
  serie_id: '',
  layout_id: '',
}
