import * as React from 'react'

import {CloseIcon, EditIcon, CheckIcon} from '@chakra-ui/icons'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  Button,
  Heading,
  useToast,
  Spinner,
  Tooltip,
  Checkbox,
  Select,
} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'
import isURL from 'validator/lib/isURL'

import {supabase} from '@/api'
import {BridgeSubscriptionSource, ReadoutsSubscription, RPC} from '@/api/models'
import {useLoadingState, useSupabaseQuery, useSupabaseRPC} from '@/common/hooks'
import {EventRentedDevice} from '@/organizer/types'

import {emptyCouchDBSubscription, emptyMaterializedSubscription, SourceButton} from './constants'
import {inputToUpsertArgs} from './utils'

const ReadoutsSourceForm = ({eventID}: {eventID: string}) => {
  const {t} = useTranslation()
  const toast = useToast()
  const [editing, setEditing] = React.useState(false)
  const [input, setInput] = React.useState(emptyMaterializedSubscription)
  const [subscription, setSubscription] = React.useState(emptyMaterializedSubscription)

  const devices = useSupabaseQuery<EventRentedDevice>({
    fields: '*',
    table: 'event_rented_devices',
    filter: React.useCallback(
      (b: PostgrestFilterBuilder<EventRentedDevice>) => b.match({event_id: eventID, virtual: true}),
      [eventID]
    ),
  })

  const _fetch = React.useCallback(async () => {
    try {
      const {data, error} = await supabase
        .from<ReadoutsSubscription>('admin_bridge_subscriptions')
        .select('*')
        .match({event_id: eventID})
        .in('source', [BridgeSubscriptionSource.CouchDB, BridgeSubscriptionSource.Materialized])

      if (error) throw error
      if (!data.length) {
        setEditing(true)
        setSubscription(emptyMaterializedSubscription)
        return
      }

      setSubscription(data[0])
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        title: t('organizer:syncing:failedToFetchReadoutsSource'),
        status: 'error',
      })
    }
  }, [eventID, t, toast])
  const {handleSubmit: fetch, loading: fetching} = useLoadingState(_fetch)

  React.useEffect(() => {
    fetch()
  }, [fetch])

  React.useEffect(() => {
    setInput(subscription)
  }, [subscription])

  const onComplete = React.useCallback(() => {
    fetch()
    setEditing(false)
  }, [fetch])

  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertBridgeSubscription,
    params: inputToUpsertArgs(input, eventID),
    mode: input.id ? 'update' : 'add',
    onComplete,
  })

  const handleInputChange = React.useCallback(
    ({target: {name, value}}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setInput((input) => ({...input, [name]: value}))
    },
    []
  )

  const handleSourceParamsChange = React.useCallback(
    ({target: {name, value}}: React.ChangeEvent<HTMLInputElement>) => {
      return setInput({
        ...input,
        source_params: {...input.source_params, [name]: value},
      } as ReadoutsSubscription)
    },
    [input]
  )

  const handleSourceChange = React.useCallback((source: BridgeSubscriptionSource) => {
    let emptySubscription: ReadoutsSubscription
    switch (source) {
      case BridgeSubscriptionSource.CouchDB:
        emptySubscription = emptyCouchDBSubscription
        break
      case BridgeSubscriptionSource.Materialized:
        emptySubscription = emptyMaterializedSubscription
        break
    }
    setInput((prev) => ({...emptySubscription, name: prev.name, id: prev.id}))
  }, [])

  const handleEditingChange = React.useCallback(() => {
    editing && setInput(subscription)
    setEditing((prev) => !prev)
  }, [editing, subscription])

  const handlePollChange = React.useCallback(
    ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) => {
      if (input.source !== BridgeSubscriptionSource.CouchDB) return
      setInput({...input, source_params: {...input.source_params, poll: checked}})
    },
    [input]
  )

  const isURLValid = React.useMemo(() => {
    return !!input.source_params.url && isURL(input.source_params.url)
  }, [input])

  const areSourceParamsValid = React.useMemo(() => {
    switch (input.source) {
      case BridgeSubscriptionSource.CouchDB:
        return !!input.source_params.database && isURLValid && !!input.device_id
      case BridgeSubscriptionSource.Materialized:
        return isURLValid && !!input.device_id
    }
  }, [input, isURLValid])

  const isSubmitDisabled = React.useMemo(
    () => !input.name || !eventID || !input.source || !input.status || !areSourceParamsValid,
    [input, areSourceParamsValid, eventID]
  )

  return (
    <Stack border="1px solid" borderColor="whiteAlpha.300" borderRadius="lg" overflow="hidden" spacing="0">
      <HStack
        justify="space-between"
        p={2}
        pl={4}
        bgColor="gray.900"
        borderBottom="1px solid"
        borderColor="whiteAlpha.300"
      >
        <HStack spacing={4}>
          <Heading size="md" mr={2}>
            {t('organizer:syncing:readoutsSource')}
          </Heading>
          {fetching ? (
            <Spinner />
          ) : (
            <HStack>
              <SourceButton
                source={BridgeSubscriptionSource.CouchDB}
                currentSource={input.source}
                onClick={handleSourceChange}
                isDisabled={!editing || loading}
              >
                CouchDB
              </SourceButton>
              <SourceButton
                source={BridgeSubscriptionSource.Materialized}
                currentSource={input.source}
                onClick={handleSourceChange}
                isDisabled={!editing || loading}
              >
                Materialized
              </SourceButton>
            </HStack>
          )}
        </HStack>
        {editing ? (
          <HStack>
            <Tooltip label={t('common:actions:save')}>
              <Button p={1} isLoading={loading} onClick={handleRPC} isDisabled={isSubmitDisabled || fetching}>
                <CheckIcon />
              </Button>
            </Tooltip>
            <Tooltip label={t('common:actions:cancel')}>
              <Button p={1} isDisabled={loading} onClick={handleEditingChange}>
                <CloseIcon />
              </Button>
            </Tooltip>
          </HStack>
        ) : (
          <Tooltip label={t('common:actions:edit')}>
            <Button p={1} onClick={handleEditingChange} isDisabled={fetching || loading}>
              <EditIcon />
            </Button>
          </Tooltip>
        )}
      </HStack>
      <Stack p={4} bgColor="gray.800">
        {fetching ? (
          <Spinner />
        ) : (
          <>
            <Stack>
              <FormControl isDisabled={loading} isRequired={editing} isReadOnly={!editing}>
                <FormLabel>{t('common:fields:name')}</FormLabel>
                <Input name="name" value={input.name ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl isDisabled={devices.loading || loading || !editing} isRequired={editing}>
                <FormLabel _disabled={{opacity: 1}}>{t('common:entities:device')}</FormLabel>
                <Select
                  name="device_id"
                  value={input.device_id ?? ''}
                  onChange={handleInputChange}
                  _disabled={{opacity: 1}}
                  iconSize={editing ? 'xl' : '0'}
                >
                  <option value="">{t('admin:bridgeSubscriptions:form:selectDevice')}</option>
                  {devices.data?.map((d) => (
                    <option value={d.id} key={d.id}>
                      {d.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                isDisabled={loading}
                isRequired={editing}
                isInvalid={!!input.source_params.url && !isURLValid}
                isReadOnly={!editing}
              >
                <FormLabel>URL</FormLabel>
                <Input name="url" value={input.source_params.url ?? ''} onChange={handleSourceParamsChange} />
                <FormErrorMessage>{t('errors:invalidURL')}</FormErrorMessage>
              </FormControl>
              {input.source === BridgeSubscriptionSource.CouchDB && (
                <>
                  <FormControl isDisabled={loading} isRequired={editing} isReadOnly={!editing}>
                    <FormLabel>{t('admin:bridgeSubscriptions:fields:database')}</FormLabel>
                    <Input
                      name="database"
                      value={input.source_params.database ?? ''}
                      onChange={handleSourceParamsChange}
                    />
                  </FormControl>
                  <FormControl isDisabled={loading || !editing}>
                    <Checkbox name="poll" isChecked={input.source_params.poll} onChange={handlePollChange}>
                      Poll
                    </Checkbox>
                  </FormControl>
                </>
              )}
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default ReadoutsSourceForm
