import * as React from 'react'

import {CheckIcon, CloseIcon, DeleteIcon, EditIcon} from '@chakra-ui/icons'
import {
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  useDisclosure,
  Text,
} from '@chakra-ui/react'
import {areIntervalsOverlapping} from 'date-fns'
import {useTranslation} from 'react-i18next'

import {RPC} from '@/api/models'
import {DeleteResourceModal} from '@/common/components'
import {useSupabaseRPC} from '@/common/hooks'
import {RentedDeviceAssignemt} from '@/organizer/types'
import {dateToInput} from '@/utils/string'
import {isDurationActive} from '@/utils/time'

import {DeviceContext} from '..'
import {emptyAssignment} from '../constants'
import {inputToUpsertArgs} from './utils'

type Props = {
  assignment: RentedDeviceAssignemt
  onCancel?: () => void
}

const AssignementItem = ({assignment, onCancel}: Props) => {
  const {t} = useTranslation()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [input, setInput] = React.useState<RentedDeviceAssignemt>(emptyAssignment)
  const [editing, setEditing] = React.useState(true)
  const {device, isCurrentlyRented, assignments} = React.useContext(DeviceContext)

  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertDeviceAssignment,
    params: inputToUpsertArgs(input, device),
    mode: assignment.id ? 'update' : 'add',
    onComplete: assignments?.fetch,
    onClose: onCancel,
  })

  React.useEffect(() => {
    assignment && setInput(assignment)
    assignment.id && setEditing(false)
  }, [assignment])

  const handleModeChange = React.useCallback(() => {
    if (editing) {
      assignment && setInput(assignment)
      !assignment.id && onCancel && onCancel()
    }
    setEditing((prev) => !prev)
  }, [assignment, editing, onCancel])

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handleDateChange = React.useCallback(({target: {id, value}}: React.ChangeEvent<HTMLInputElement>) => {
    setInput((input) => ({...input, [id]: value ? new Date(value) : new Date()}))
  }, [])

  const startTimeInput = React.useMemo(() => dateToInput(new Date(input.start_time)), [input.start_time])
  const endTimeInput = React.useMemo(() => dateToInput(new Date(input.end_time)), [input.end_time])
  const isEndTimeValid = React.useMemo(() => new Date(input.start_time) < new Date(input.end_time), [input])

  const timeOverlaps = React.useMemo(
    () =>
      isEndTimeValid
        ? (assignments?.data?.findIndex(
            (a) =>
              areIntervalsOverlapping(
                {start: new Date(a.start_time), end: new Date(a.end_time)},
                {start: new Date(input.start_time), end: new Date(input.end_time)}
              ) && a.id !== input.id
          ) ?? 0) >= 0
        : false,
    [assignments, input, isEndTimeValid]
  )

  const timeInRentDuration = React.useMemo(
    () =>
      isEndTimeValid
        ? new Date(device.start_time) <= new Date(input.start_time) &&
          new Date(device.end_time) >= new Date(input.end_time)
        : true,
    [device, input, isEndTimeValid]
  )

  const isSubmitDisabled = React.useMemo(
    () => !input.type || !isEndTimeValid || timeOverlaps || !timeInRentDuration,
    [input, isEndTimeValid, timeOverlaps, timeInRentDuration]
  )

  const isActive = React.useMemo(
    () => assignment.id && isDurationActive(assignment.start_time, assignment.end_time),
    [assignment]
  )

  return (
    <Stack
      mt={2}
      border="1px solid "
      borderColor="whiteAlpha.300"
      p={2}
      borderRadius="lg"
      boxShadow={isActive ? '0px 0px 4px 1px #48BB78' : 'none'}
    >
      <HStack align="flex-end">
        <FormControl id="type" isDisabled={loading} isRequired={true} isReadOnly={!editing}>
          <InputGroup size="sm">
            <InputLeftAddon borderRadius="lg">{t('admin:deviceAssignments:fields:type')}</InputLeftAddon>
            <Input value={input.type ?? ''} onChange={handleInputChange} borderRadius="lg" />
          </InputGroup>
        </FormControl>
        {isCurrentlyRented && (
          <>
            {editing ? (
              <>
                <IconButton
                  aria-label="edit-assignment"
                  icon={<CheckIcon />}
                  onClick={handleRPC}
                  isDisabled={isSubmitDisabled}
                  isLoading={loading}
                  size="sm"
                />
                <IconButton
                  aria-label="delete-assignment"
                  icon={<CloseIcon />}
                  onClick={handleModeChange}
                  size="sm"
                  isDisabled={loading}
                />
              </>
            ) : (
              <>
                <IconButton
                  aria-label="edit-assignment"
                  icon={<EditIcon />}
                  onClick={handleModeChange}
                  size="sm"
                />
                <IconButton
                  aria-label="delete-assignment"
                  icon={<DeleteIcon />}
                  onClick={onDeleteOpen}
                  size="sm"
                />
              </>
            )}
          </>
        )}
      </HStack>
      <HStack w="100%">
        <FormControl id="start_time" isReadOnly={!editing} isDisabled={loading}>
          <InputGroup size="sm">
            <InputLeftAddon borderRadius="lg">{t('common:from')}</InputLeftAddon>
            <Input
              value={startTimeInput}
              onChange={handleDateChange}
              type="datetime-local"
              borderRadius="lg"
            />
          </InputGroup>
        </FormControl>
        <FormControl id="end_time" isInvalid={!isEndTimeValid} isReadOnly={!editing} isDisabled={loading}>
          <InputGroup size="sm">
            <InputLeftAddon borderRadius="lg">{t('common:to')}</InputLeftAddon>
            <Input value={endTimeInput} onChange={handleDateChange} type="datetime-local" borderRadius="lg" />
          </InputGroup>
          <FormErrorMessage>{t('errors:endTime')}</FormErrorMessage>
        </FormControl>
      </HStack>
      {timeOverlaps && (
        <Text color="red.300">{t('organizer:devices:timeRangeOverlapsWithAnExistingAssignment')}</Text>
      )}
      {!timeInRentDuration && (
        <Text color="red.300">{t('organizer:devices:timeRangeMustBeContainedInTheRentDuration')}</Text>
      )}
      <DeleteResourceModal
        id={assignment.id}
        table="device_assignments"
        open={isDeleteOpen}
        onComplete={assignments?.fetch}
        onClose={onDeleteClose}
      />
    </Stack>
  )
}

export default AssignementItem
