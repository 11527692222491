import * as React from 'react'

import {EditIcon, DeleteIcon} from '@chakra-ui/icons'
import {
  HStack,
  Tag,
  Text,
  GridItem,
  Grid,
  Button,
  Flex,
  Tooltip,
  useDisclosure,
  Collapse,
  Stack,
} from '@chakra-ui/react'
import {AlertTriangle as AlertIcon} from 'react-feather'
import {useTranslation} from 'react-i18next'

import {OrganizerEventParticipant} from '@/api/models'
import {formatMilliseconds} from '@/utils/string'
import {isDurationActive} from '@/utils/time'

import ParticipantSummaryChart from './chart'

type Props = {
  item: OrganizerEventParticipant
  onEdit: (p: OrganizerEventParticipant) => void
  onDelete: (p: OrganizerEventParticipant) => void
}

const ParticipantListItem = ({item, onEdit, onDelete}: Props) => {
  const {t} = useTranslation()
  const {isOpen, onToggle, onClose} = useDisclosure()

  // when filtering the collapse would stay open on a wrong item
  // maybe there's a better solution
  React.useEffect(() => {
    onClose()
  }, [item]) // eslint-disable-line

  const handleDelete = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      onDelete && onDelete(item)
    },
    [onDelete, item]
  )

  const handleEdit = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      onEdit && onEdit(item)
    },
    [onEdit, item]
  )

  return (
    <Flex direction="column" position="sticky" top={28}>
      <Grid
        p={2}
        w="100%"
        templateColumns="80px 200px 100px 1fr"
        gap={2}
        bgColor="gray.900"
        borderRadius="xl"
        zIndex="base"
        onClick={onToggle}
        _hover={{
          transform: 'scale(1.01)',
          cursor: 'pointer',
        }}
        _active={{
          transform: 'scale(0.99)',
        }}
        transition="all 150ms ease"
      >
        <Flex align="center" justify="center">
          <Text fontWeight="bold" fontSize="lg">
            {item.number}
          </Text>
        </Flex>
        <Flex align="center">
          <Text>{item.name}</Text>
        </Flex>
        <Flex align="center">
          <Text fontWeight="bold" color="green.300">
            Ø {formatMilliseconds(item.avg)}
          </Text>
        </Flex>
        <GridItem>
          <HStack justifyContent="space-between">
            {!item.classes?.length ? (
              <HStack color="whiteAlpha.600">
                <AlertIcon size="1rem" />
                <Text fontSize="sm">{t('organizer:participants:noClassesAssigned')}</Text>
              </HStack>
            ) : (
              <HStack>
                {item.classes?.map((c, i) => (
                  <Tag key={i} colorScheme={isDurationActive(c.start_time, c.end_time) ? 'green' : 'gray'}>
                    {c.name}
                  </Tag>
                ))}
              </HStack>
            )}
            <HStack>
              <Tooltip label={t('common:actions:edit')}>
                <Button onClick={handleEdit} size="sm" p={1}>
                  <EditIcon />
                </Button>
              </Tooltip>
              <Tooltip label={t('common:actions:delete')}>
                <Button onClick={handleDelete} size="sm" p={1}>
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </HStack>
          </HStack>
        </GridItem>
      </Grid>
      {item.classes?.length ? (
        <Collapse in={isOpen} animateOpacity={true} unmountOnExit={true}>
          <Flex w="100%" px={6} justify="center">
            <Stack p={2} w="100%" bgColor="blackAlpha.500" borderRadius="0 0 0.75rem 0.75rem">
              <HStack spacing={2} w="100%" justify="center">
                {item.best3?.map((lt) => (
                  <Tag key={lt} py={1} px={3} size="md" borderRadius="full" variant="subtle">
                    {formatMilliseconds(lt)}
                  </Tag>
                ))}
              </HStack>
              <Stack flex="1" align="flex-end" alignSelf="stretch" spacing={0}>
                <ParticipantSummaryChart participant={item} />
              </Stack>
            </Stack>
          </Flex>
        </Collapse>
      ) : null}
    </Flex>
  )
}

export default ParticipantListItem
