import * as React from 'react'

import {Box, Heading, useDisclosure} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {ParticipantTag} from '@/api/models'
import {DeleteResourceModal} from '@/common/components'
import DataTable from '@/common/data-table'
import {Column} from '@/common/data-table/types'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import {FilterField} from '@/common/filter-builder/types'
import {useSupabaseQuery} from '@/common/hooks'

import {EditorMode} from '../types'
import {emptyParticipantTag} from './constants'
import ParticipantTagEditorModal from './editor-modal'

const defaultSelectedColumns = [
  'event_name',
  'participant_number',
  'participant_name',
  'epc',
  'start_time',
  'end_time',
]

const ParticipantTags = () => {
  const {t} = useTranslation()
  const tableState = useTableState({sortBy: 'created_at'})
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [currentParticipantTag, setCurrentParticipantTag] =
    React.useState<ParticipantTag>(emptyParticipantTag)
  const [currentMode, setCurrentMode] = React.useState<EditorMode>('add')
  const {loading, data, fetch, rows} = useSupabaseQuery<ParticipantTag>({
    fields: '*',
    table: 'admin_participant_tags',
    order: tableState.sortBy.column as keyof ParticipantTag,
    itemsPerPage: tableState.rowsPerPage,
    pageNumber: tableState.page,
    descending: tableState.descending,
    filter: tableState.filter,
  })

  const columns: Column<ParticipantTag>[] = React.useMemo(
    () => [
      {
        Header: t('common:fields:id').toString(),
        id: 'id',
        renderAs: 'code',
        sortable: false,
      },
      {
        Header: t('common:entities:event').toString(),
        id: 'event_name',
      },
      {
        Header: t('events:participants:participantNumber').toString(),
        id: 'participant_number',
      },
      {
        Header: t('events:participants:participantName').toString(),
        id: 'participant_name',
      },
      {
        Header: 'EPC',
        id: 'epc',
        renderAs: 'code',
        sortable: false,
      },
      {
        Header: t('common:fields:description').toString(),
        id: 'description',
        renderAs: 'longString',
      },
      {
        Header: t('common:fields:startTime').toString(),
        id: 'start_time',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:endTime').toString(),
        id: 'end_time',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:createdAt').toString(),
        id: 'created_at',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:updatedAt').toString(),
        id: 'updated_at',
        renderAs: 'timestamp',
      },
    ],
    [t]
  )

  const filterFields: FilterField[] = React.useMemo(
    () => [
      {
        label: t('common:entities:event'),
        name: 'event_name',
        variant: 'text',
      },
      {
        label: t('events:participants:participantNumber'),
        name: 'participant_number',
        variant: 'number',
      },
      {
        label: t('events:participants:participantName'),
        name: 'participant_name',
        variant: 'text',
      },
      {
        label: t('common:fields:startTime'),
        name: 'start_time',
        variant: 'datetime',
      },
      {
        label: t('common:fields:endTime'),
        name: 'end_time',
        variant: 'datetime',
      },
      {
        label: t('common:fields:createdAt'),
        name: 'created_at',
        variant: 'datetime',
      },
      {
        label: t('common:fields:updatedAt'),
        name: 'updated_at',
        variant: 'datetime',
      },
    ],
    [t]
  )

  const handleAdd = React.useCallback(() => {
    setCurrentParticipantTag(emptyParticipantTag)
    setCurrentMode('add')
    onEditorOpen()
  }, [onEditorOpen])

  const handleEdit = React.useCallback(
    (item: ParticipantTag) => {
      setCurrentParticipantTag(item)
      setCurrentMode('update')
      onEditorOpen()
    },
    [onEditorOpen]
  )

  const handleDelete = React.useCallback(
    (item: ParticipantTag) => {
      setCurrentParticipantTag(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  const actions = React.useMemo(
    () => [editAction(handleEdit), deleteAction(handleDelete)],
    [handleEdit, handleDelete]
  )

  return (
    <Box>
      <Heading fontSize="xl" mb={4}>
        {t('admin:menu:participantTags')}
      </Heading>
      <DataTable<ParticipantTag>
        columns={columns}
        defaultSelectedColumns={defaultSelectedColumns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        loading={loading}
        onRefresh={fetch}
        onAdd={handleAdd}
        filterFields={filterFields}
      />
      <ParticipantTagEditorModal
        item={currentParticipantTag}
        open={isEditorOpen}
        onClose={onEditorClose}
        onComplete={fetch}
        mode={currentMode}
      />
      <DeleteResourceModal
        table="participant_tags"
        id={currentParticipantTag.id}
        open={isDeleteOpen}
        onClose={onDeleteClose}
        onComplete={fetch}
      />
    </Box>
  )
}

export default ParticipantTags
