import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import CarsManager from '@/admin/participants/editor-modal/cars-manager'
import ParticipantForm from '@/admin/participants/editor-modal/form'
import TagsManager from '@/admin/participants/editor-modal/tags-manager'
import {EditorMode} from '@/admin/types'
import {OrganizerEventParticipant} from '@/api/models'
import {EventContext} from '@/organizer/events/page'
import {CommonModalProps} from '@/utils/types'

import ClassesManager from './classes-manager'

type Props = {
  item: OrganizerEventParticipant
  mode: EditorMode
}

const AddModal = ({item, mode, open, onClose, onComplete}: CommonModalProps & Props) => {
  const {t} = useTranslation()
  const {event} = React.useContext(EventContext)

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`organizer:participants:addParticipant`)
            // t(`organizer:participants:updateParticipant`)
            t(`organizer:participants:${mode}Participant`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4} align="stretch">
            <ParticipantForm
              item={item}
              serieID={event.serie?.id}
              open={open}
              onClose={onClose}
              onComplete={onComplete}
            />
            {item.id && (
              <>
                <ClassesManager participantID={item.id} />
                <CarsManager participantID={item.id} />
                <TagsManager participantID={item.id} />
              </>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AddModal
