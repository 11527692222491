import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Select,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {EditorMode} from '@/admin/types'
import {RentedDevice, Event, Device, RPC} from '@/api/models'
import {TimeRangeInput} from '@/common/components'
import {useSupabaseQuery, useSupabaseRPC} from '@/common/hooks'
import {CommonModalProps} from '@/utils/types'

import {emptyRentedDevice} from '../constants'
import {inputToUpsertArgs} from './utils'

const RentedDeviceEditorModal = ({
  item,
  mode,
  open,
  onClose,
  onComplete,
}: CommonModalProps & {item: RentedDevice; mode: EditorMode}) => {
  const {t} = useTranslation()
  const [input, setInput] = React.useState(emptyRentedDevice)
  const {loading: devicesLoading, data: devices} = useSupabaseQuery<Device>({
    fields: 'id, name',
    table: 'devices',
    order: 'name',
  })
  const {loading: eventsLoading, data: events} = useSupabaseQuery<Event>({
    fields: 'id, name',
    table: 'events',
    order: 'name',
  })
  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertRentedDevice,
    params: inputToUpsertArgs(input, mode),
    mode,
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
  }, [item, open])

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handleTimeChange = React.useCallback((id: string, value: Date) => {
    setInput((input) => ({...input, [id]: value}))
  }, [])

  const isTimeValid = React.useMemo(() => new Date(input.start_time) < new Date(input.end_time), [input])

  const isSubmitDisabled = React.useMemo(
    () => !input.event_id || !input.device_id || !input.start_time || !input.end_time || !isTimeValid,
    [input, isTimeValid]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`admin:devices:renting:form:addLabel`)
            // t(`admin:devices:renting:form:updateLabel`)
            t(`admin:devices:renting:form:${mode}Label`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack px={2} py={2}>
            <Stack spacing={4} align="center" width="100%">
              <FormControl id="device_id" isDisabled={devicesLoading || loading} isRequired={true}>
                <FormLabel>{t('common:entities:device')}</FormLabel>
                <Select value={input.device_id ?? ''} onChange={handleInputChange}>
                  <option value="">{t('admin:devices:form:selectDevice')}</option>
                  {!devicesLoading &&
                    devices?.map((d) => (
                      <option value={d.id} key={d.id}>
                        {d.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <FormControl id="event_id" isDisabled={eventsLoading || loading} isRequired={true}>
                <FormLabel>{t('common:entities:event')}</FormLabel>
                <Select value={input.event_id ?? ''} onChange={handleInputChange}>
                  <option value="">{t('admin:devices:form:selectEvent')}</option>
                  {!eventsLoading &&
                    events?.map((e) => (
                      <option value={e.id} key={e.id}>
                        {e.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <TimeRangeInput
                startTime={input.start_time}
                endTime={input.end_time}
                isDisabled={loading}
                onTimeChange={handleTimeChange}
              />
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleRPC} isDisabled={isSubmitDisabled}>
              {t('common:actions:save')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RentedDeviceEditorModal
