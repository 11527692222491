import * as React from 'react'

import {Flex, Heading, Stack, Box, Container, Grid, GridItem} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {Outlet, useParams} from 'react-router'

import {setSelectedIdentity} from '@/auth/state'
import LoadingView from '@/common/loading-view'
import {fetchOrganizer, selectOrganizerLoading, selectOrganizer} from '@/organizer/state'
import {useAppDispatch, useAppSelector} from '@/store'

import Header from './header'
import Sidebar from './sidebar'

const Layout = () => {
  const {t} = useTranslation()
  const {id} = useParams()
  const dispatch = useAppDispatch()
  const loading = useAppSelector(selectOrganizerLoading)
  const organizer = useAppSelector(selectOrganizer)

  React.useEffect(() => {
    if (!id) return
    dispatch(setSelectedIdentity({type: 'organizer', id}))
    dispatch(fetchOrganizer(id))
  }, [id, dispatch])

  if (loading) {
    return <LoadingView />
  }

  if (!organizer) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Heading>{t('organizer:notFound')}</Heading>
      </Flex>
    )
  }

  return (
    <Container maxW="container.xl" p={4} minH="100vh">
      <Grid templateColumns="repeat(5, 1fr)" gap={4}>
        <GridItem>
          <Sidebar id={id ?? ''} />
        </GridItem>
        <GridItem colSpan={4}>
          <Stack spacing={0} flex="4">
            <Header />
            <Box p={4}>
              <Outlet />
            </Box>
          </Stack>
        </GridItem>
      </Grid>
    </Container>
  )
}

export default Layout
