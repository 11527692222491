import * as React from 'react'

import {Container, Grid, GridItem} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {Outlet} from 'react-router'

import Sidebar from './sidebar'

const AdminPanel = () => {
  const {t} = useTranslation()

  return (
    <Container maxW="container.xl" px={8} pb={4}>
      <Grid templateColumns="repeat(5, 1fr)" gap={6}>
        <GridItem>
          <Sidebar />
        </GridItem>
        <GridItem colSpan={4}>
          <Outlet />
        </GridItem>
      </Grid>
    </Container>
  )
}

export default AdminPanel
