import * as React from 'react'

import {Flex, CircularProgress} from '@chakra-ui/react'

const LoadingView = () => (
  <Flex alignItems="center" justifyContent="center" height="100vh">
    <CircularProgress isIndeterminate={true} />
  </Flex>
)

export default LoadingView
