import React from 'react'

import {HStack} from '@chakra-ui/layout'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/modal'
import {Button} from '@chakra-ui/react'
import {useToast} from '@chakra-ui/toast'
import {useTranslation} from 'react-i18next'

import {supabase} from '@/api'
import {useLoadingState} from '@/common/hooks'
import {CommonModalProps} from '@/utils/types'

type Props = CommonModalProps & {
  table?: string
  id?: string
  onDelete?: () => Promise<void>
}

const DeleteResourceModal = ({onClose, open, table, id, onComplete, onDelete}: Props) => {
  const {t} = useTranslation()
  const toast = useToast()

  const handleDelete = React.useCallback(async () => {
    try {
      if (onDelete) {
        await onDelete()
      } else if (!!id && !!table) {
        const {error} = await supabase.from(table).delete().match({id})
        if (error) {
          throw error
        }
      } else {
        throw new Error('Missing item details')
      }
      toast({isClosable: true, status: 'success', title: t('common:dataTable:deleteSuccess')})
      onClose()
      onComplete && onComplete()
    } catch (e) {
      console.error('Failed to delete item', e)
      toast({
        isClosable: true,
        title: t('errors:deleteFail'),
        variant: 'error',
      })
    }
  }, [id, onClose, onComplete, onDelete, t, table, toast])
  const {loading, handleSubmit} = useLoadingState(handleDelete)

  return (
    <Modal isOpen={open} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('common:dataTable:deletePrompt')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t('common:dataTable:deleteMessage')}</ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleSubmit}>
              {t('common:actions:delete')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeleteResourceModal
