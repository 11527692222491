import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Input,
  Checkbox,
  Select,
  FormErrorMessage,
  Box,
  useToast,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {ImageType} from 'react-images-uploading'
import {v4 as uuidv4} from 'uuid'

import {EditorMode} from '@/admin/types'
import {AdminOrganizer, AdminSerie, RPC} from '@/api/models'
import {uploadFile} from '@/api/utils'
import {ImageUpload} from '@/common/components'
import {useLoadingState, useSupabaseQuery, useSupabaseRPC} from '@/common/hooks'
import {SUPABASE_SERIES_BUCKET} from '@/constants'
import {CommonModalProps} from '@/utils/types'

import {emptySerie} from '../constants'
import {inputToUpsertArgs} from './utils'

const SerieEditorModal = ({
  item,
  mode,
  open,
  onClose,
  onComplete,
}: CommonModalProps & {item: AdminSerie; mode: EditorMode}) => {
  const {t} = useTranslation()
  const toast = useToast()
  const [input, setInput] = React.useState(emptySerie)
  const [image, setImage] = React.useState<ImageType>()

  const {loading: organizersLoading, data: organizers} = useSupabaseQuery<AdminOrganizer>({
    fields: '*',
    table: 'organizers',
    order: 'name',
  })
  const {handleRPC} = useSupabaseRPC({
    fnName: RPC.UpsertSerie,
    params: {},
    mode,
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
    item.logo_url ? setImage({dataURL: item.logo_url}) : setImage(undefined)
  }, [item, open])

  const _handleSubmit = React.useCallback(async () => {
    try {
      const id = input.id || uuidv4()
      const filepath = image?.file
        ? await uploadFile(SUPABASE_SERIES_BUCKET, id, image?.file)
        : image?.dataURL
        ? input.logo
        : undefined
      await handleRPC(inputToUpsertArgs({...input, id, logo: filepath}))
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        status: 'error',
        title: t(`common:dataTable:${mode}Fail`),
      })
    }
  }, [handleRPC, image, input, mode, toast, t])
  const {handleSubmit, loading} = useLoadingState(_handleSubmit)

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handlePublicChange = React.useCallback(
    ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) =>
      setInput((input) => ({...input, public: checked})),
    []
  )

  const isDateValid = React.useMemo(() => new Date(input.start_date) < new Date(input.end_date), [input])

  const isSubmitDisabled = React.useMemo(
    () => !input.name || !input.organizer_id || !input.start_date || !input.end_date || !isDateValid,
    [input, isDateValid]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`series:form:addSerie`)
            // t(`series:form:updateSerie`)
            t(`series:form:${mode}Serie`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack px={2} py={2}>
            <Stack spacing={4} align="center" width="100%">
              <FormControl id="name" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:name')}</FormLabel>
                <Input value={input.name ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl id="organizer_id" isDisabled={organizersLoading || loading} isRequired={true}>
                <FormLabel>{t('series:fields:organizer')}</FormLabel>
                <Select value={input.organizer_id ?? ''} onChange={handleInputChange}>
                  <option value="">{t('series:form:selectOrganizer')}</option>
                  {!organizersLoading &&
                    organizers?.map((o) => (
                      <option value={o.id} key={o.id}>
                        {o.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <FormControl id="start_date" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('series:fields:startDate')}</FormLabel>
                <Input value={input.start_date} onChange={handleInputChange} type="date" />
              </FormControl>
              <FormControl
                id="end_date"
                isDisabled={loading}
                isRequired={true}
                isInvalid={!!input.end_date && !isDateValid}
              >
                <FormLabel>{t('series:fields:endDate')}</FormLabel>
                <Input value={input.end_date} onChange={handleInputChange} type="date" />
                <FormErrorMessage>{t('errors:endDate')}</FormErrorMessage>
              </FormControl>
              <FormControl id="public" isDisabled={loading}>
                <Checkbox isChecked={input.public ?? false} onChange={handlePublicChange}>
                  {t('common:fields:public')}
                </Checkbox>
              </FormControl>
              <Box w="100%">
                <ImageUpload onChange={setImage} value={image} editing={true} />
              </Box>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleSubmit} isDisabled={isSubmitDisabled}>
              {t('common:actions:save')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SerieEditorModal
