import * as React from 'react'

import {Box, useDisclosure, Tag} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'

import {EventParticipant} from '@/api/models'
import DataTable from '@/common/data-table'
import {Column} from '@/common/data-table/types'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction} from '@/common/data-table/utils'
import {useSupabaseQuery} from '@/common/hooks'
import {isDurationActive} from '@/utils/time'

import AddEventParticipantModal from './add-modal'
import {emptyEventParticipant} from './constants'
import DeleteEventParticipantModal from './delete-modal'

type Props = {
  eventID: string
}

const EventParticipants = ({eventID}: Props) => {
  const {t} = useTranslation()
  const tableState = useTableState({sortBy: 'participant_number'})
  const {isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [currentEventParticipant, setCurrentEventParticipant] =
    React.useState<EventParticipant>(emptyEventParticipant)
  const {loading, data, fetch, rows} = useSupabaseQuery<EventParticipant>({
    fields: '*',
    table: 'admin_event_participants',
    autoFetch: false,
    order: tableState.sortBy.column as keyof EventParticipant,
    itemsPerPage: tableState.rowsPerPage,
    pageNumber: tableState.page,
    descending: tableState.descending,
    filter: React.useCallback(
      (query: PostgrestFilterBuilder<EventParticipant>) => query.match({event_id: eventID}),
      [eventID]
    ),
  })

  React.useEffect(() => {
    eventID && fetch()
  }, [eventID, fetch])

  const columns: Column<EventParticipant>[] = React.useMemo(
    () => [
      {
        Header: t('events:participants:participantNumber').toString(),
        id: 'participant_number',
      },
      {
        Header: t('events:participants:participantName').toString(),
        id: 'participant_name',
      },
      {
        Header: t('admin:eventClassAssignments:fields:classes').toString(),
        id: 'classes',
        sortable: false,
        renderer: ({classes}) => (
          <>
            {!classes || !classes.length || !classes[0].id
              ? t('common:dataTable:notApplicable')
              : classes?.map((c) => (
                  <Tag
                    key={c.id}
                    mr={2}
                    colorScheme={isDurationActive(c.start_time, c.end_time) ? 'green' : 'red'}
                  >
                    {c.name}
                  </Tag>
                ))}
          </>
        ),
      },
      {
        Header: t('common:fields:createdAt').toString(),
        id: 'created_at',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:updatedAt').toString(),
        id: 'updated_at',
        renderAs: 'timestamp',
      },
    ],
    [t]
  )

  const handleAdd = React.useCallback(() => {
    setCurrentEventParticipant({...emptyEventParticipant, event_id: eventID})
    onAddOpen()
  }, [onAddOpen, eventID])

  const handleDelete = React.useCallback(
    (item: EventParticipant) => {
      setCurrentEventParticipant(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  const actions = React.useMemo(() => [deleteAction(handleDelete)], [handleDelete])

  return (
    <Box>
      <DataTable<EventParticipant>
        columns={columns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        loading={loading}
        onRefresh={fetch}
        onAdd={handleAdd}
      />
      <AddEventParticipantModal
        item={currentEventParticipant}
        open={isAddOpen}
        onClose={onAddClose}
        onComplete={fetch}
      />
      <DeleteEventParticipantModal
        eventID={currentEventParticipant.event_id}
        participantID={currentEventParticipant.participant_id}
        onClose={onDeleteClose}
        open={isDeleteOpen}
        onComplete={fetch}
      />
    </Box>
  )
}

export default EventParticipants
