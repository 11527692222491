import * as React from 'react'

import {CheckIcon, CloseIcon, EditIcon, DeleteIcon} from '@chakra-ui/icons'
import {
  Checkbox,
  FormControl,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {emptyCar} from '@/admin/cars/constants'
import {Car, RPC} from '@/api/models'
import {DeleteResourceModal} from '@/common/components'
import {useSupabaseRPC} from '@/common/hooks'

import {inputToUpsertArgs} from './utils'

type Props = {
  car: Car
  participantID: string
  onClose?: () => void
  onComplete?: () => void
}

const CarItem = ({car, participantID, onClose, onComplete}: Props) => {
  const {t} = useTranslation()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [input, setInput] = React.useState<Car>(emptyCar)
  const [editing, setEditing] = React.useState(true)

  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertCar,
    params: inputToUpsertArgs(input, participantID),
    mode: car.id ? 'update' : 'add',
    onComplete,
    onClose,
  })

  React.useEffect(() => {
    car && setInput(car)
    car.id && setEditing(false)
  }, [car])

  const handleModeChange = React.useCallback(() => {
    if (editing) {
      car && setInput(car)
      !car.id && onClose && onClose()
    }
    setEditing((prev) => !prev)
  }, [car, editing, onClose])

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handlePublicChange = React.useCallback(
    ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) =>
      setInput((input) => ({...input, public: checked})),
    []
  )

  const isSubmitDisabled = React.useMemo(() => !input.name || !participantID, [input, participantID])

  return (
    <>
      <Stack
        border="1px solid"
        borderColor="whiteAlpha.300"
        borderRadius="lg"
        bgColor="gray.800"
        spacing={0}
        overflow="hidden"
        w="100%"
      >
        <HStack
          w="100%"
          align="flex-end"
          bgColor="gray.900"
          p={2}
          borderBottom="1px solid"
          borderColor="whiteAlpha.300"
        >
          <FormControl id="name" isDisabled={loading} isRequired={true} isReadOnly={!editing}>
            <InputGroup>
              <InputLeftAddon>{t('common:fields:name')}</InputLeftAddon>
              <Input value={input.name} onChange={handleInputChange} bgColor="whiteAlpha.200" />
            </InputGroup>
          </FormControl>
          <HStack>
            {editing ? (
              <>
                <IconButton
                  aria-label="save"
                  icon={<CheckIcon />}
                  onClick={handleRPC}
                  isDisabled={isSubmitDisabled}
                  isLoading={loading}
                />
                <IconButton
                  aria-label="cancel"
                  icon={<CloseIcon />}
                  onClick={handleModeChange}
                  isDisabled={loading}
                />
              </>
            ) : (
              <>
                <IconButton aria-label="edit-car" icon={<EditIcon />} onClick={handleModeChange} />
                <IconButton aria-label="delete-car" icon={<DeleteIcon />} onClick={onDeleteOpen} />
              </>
            )}
          </HStack>
        </HStack>
        <Stack p={2}>
          <FormControl id="public" isDisabled={loading || !editing}>
            <Checkbox isChecked={input.public} onChange={handlePublicChange}>
              {t('common:fields:public')}
            </Checkbox>
          </FormControl>
        </Stack>
      </Stack>
      <DeleteResourceModal
        id={car.id}
        table="cars"
        open={isDeleteOpen}
        onComplete={onComplete}
        onClose={onDeleteClose}
      />
    </>
  )
}

export default CarItem
