import * as React from 'react'

import {Marker, useMapEvent} from 'react-leaflet'

import {Location} from '@/api/models'

import {swapLngLat} from '../utils'

type Props = {
  location: Location
  onChange: (location: Location) => void
}

const MapMarker = ({location, onChange}: Props) => {
  useMapEvent('click', (e) => {
    const {lat, lng} = e.latlng
    onChange({...location, coordinates: [lng, lat]})
  })

  return <Marker position={swapLngLat(location.coordinates)} />
}

export default MapMarker
