import {Track} from '@/api/models'

export const emptyTrack: Track = {
  id: '',
  created_at: new Date(),
  updated_at: new Date(),
  name: '',
  description: '',
  website: '',
  public: false,
  location: {type: 'Point', coordinates: [19.486371, 51.811702]},
}
