import React from 'react'

import {useToast} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {supabase} from '@/api'
import {BridgeSubscription, BridgeSubscriptionSource} from '@/api/models'

const sleep = (time: number) => {
  return new Promise<void>((res) => setTimeout(() => res(), time))
}

type Props = {
  eventID?: string
  onConfigOpen?: () => void
  onComplete?: () => void
}

const useSyncEventData = ({eventID, onConfigOpen, onComplete}: Props) => {
  const {t} = useTranslation()
  const toast = useToast()
  const [syncing, setSyncing] = React.useState(false)
  const [error, setError] = React.useState<Error | null>(null)

  const sync = React.useCallback(
    async (type: 'participants' | 'readouts') => {
      if (!eventID || syncing) return

      setSyncing(true)
      try {
        // Backend polls for `active` subscriptions and starts the sync for them,
        // when sync is done or an error occurs the status gets set to `completed`.
        // There can only be one participants source (`csv` or `spreadsheet`)
        // and one readouts source (`couchdb` or `materialized`) per event.
        const {data: subscriptions, error: updateErr} = await supabase
          .from<BridgeSubscription>('bridge_subscriptions')
          .update({status: 'active'})
          .match({event: eventID})
          .in(
            'source',
            type === 'participants'
              ? [BridgeSubscriptionSource.CSV, BridgeSubscriptionSource.Spreadsheet]
              : [BridgeSubscriptionSource.CouchDB, BridgeSubscriptionSource.Materialized]
          )

        if (updateErr) throw updateErr
        // open the config modal if source is not set
        if (!subscriptions.length) {
          onConfigOpen && onConfigOpen()
          return
        }

        // CouchDB with `poll` set to true is the only long lived subscription, therefore it's status won't change automatically
        if (
          subscriptions[0].source !== BridgeSubscriptionSource.CouchDB ||
          !subscriptions[0].source_params.poll
        ) {
          // wait for the subscription's status to change
          // i.e. wait for the import to end
          let currentStatus = subscriptions[0].status
          while (currentStatus === 'active') {
            await sleep(5000) // same as the tick interval in the backend ( svc/bridge/service/service.go:40 )
            const {data, error} = await supabase
              .from<BridgeSubscription>('bridge_subscriptions')
              .select('status')
              .eq('id', subscriptions[0].id)
              .single()
            if (error) throw error

            currentStatus = data.status
          }
        }

        onComplete && onComplete()
        toast({
          isClosable: true,
          title:
            type === 'participants'
              ? t('organizer:syncing:successfullyImportedParticipants')
              : t('organizer:syncing:successfullyImportedReadouts'),
          status: 'success',
        })
      } catch (e) {
        setError(e as Error)
        toast({
          description: (e as Error).message,
          isClosable: true,
          title:
            type === 'participants'
              ? t('organizer:syncing:failedToImportParticipants')
              : t('organizer:syncing:failedToImportReadouts'),
          status: 'error',
        })
      } finally {
        setSyncing(false)
      }
    },
    [eventID, onConfigOpen, onComplete, syncing, t, toast]
  )

  const syncParticipants = React.useCallback(() => sync('participants'), [sync])
  const syncReadouts = React.useCallback(() => sync('readouts'), [sync])

  return {syncParticipants, syncReadouts, error, syncing}
}

export default useSyncEventData
