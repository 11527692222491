import * as React from 'react'

import {Stack, Text} from '@chakra-ui/react'

const Dashboard = () => {
  return (
    <Stack>
      <Text>Dashboard</Text>
    </Stack>
  )
}

export default Dashboard
