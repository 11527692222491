import * as React from 'react'

import {Navigate, Outlet, useSearchParams} from 'react-router-dom'

import {AbilityContext} from '@/auth/abilities'

const RequireLoggedOut = () => {
  const ability = React.useContext(AbilityContext)
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo')

  return ability.can('access', 'LoggedOutRoute') ? (
    <Outlet />
  ) : (
    <Navigate to={{pathname: redirectTo || '/'}} />
  )
}

export default RequireLoggedOut
