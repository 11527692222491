import * as React from 'react'

import {Box, Heading, useDisclosure} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'

import {AdminSerieClass} from '@/api/models'
import {selectSelectedIdentity} from '@/auth/state'
import {DeleteResourceModal} from '@/common/components'
import DataTable from '@/common/data-table'
import {Column} from '@/common/data-table/types'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import {FilterField} from '@/common/filter-builder/types'
import {useSupabaseQuery} from '@/common/hooks'
import {useAppSelector} from '@/store'

import {EditorMode} from '../types'
import {emptySerieClass} from './constants'
import SerieClassEditorModal from './editor-modal'

const SerieClasses = ({serieID}: {serieID?: string}) => {
  const {t} = useTranslation()
  const selectedIdentity = useAppSelector(selectSelectedIdentity)

  const tableState = useTableState({sortBy: 'created_at'})
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [currentSerieClass, setCurrentSerieClass] = React.useState<AdminSerieClass>(emptySerieClass)
  const [currentMode, setCurrentMode] = React.useState<EditorMode>('add')
  const {loading, data, fetch, rows} = useSupabaseQuery<AdminSerieClass>({
    fields: '*',
    table: 'admin_serie_classes',
    order: tableState.sortBy.column as keyof AdminSerieClass,
    itemsPerPage: tableState.rowsPerPage,
    pageNumber: tableState.page,
    descending: tableState.descending,
    filter: tableState.filter,
    finalize: React.useCallback(
      (b: PostgrestFilterBuilder<AdminSerieClass>) => (serieID ? b.match({serie_id: serieID}) : b),
      [serieID]
    ),
  })

  const columns: Column<AdminSerieClass>[] = React.useMemo(
    () => [
      {
        Header: t('common:fields:id').toString(),
        id: 'id',
        renderAs: 'code',
        sortable: false,
      },
      {
        Header: t('common:entities:serie').toString(),
        id: 'serie_name',
      },
      {
        Header: t('common:fields:name').toString(),
        id: 'name',
      },
      {
        Header: t('common:fields:shortDescription').toString(),
        id: 'short_description',
        renderAs: 'longString',
      },
      {
        Header: t('common:fields:description').toString(),
        id: 'description',
        renderAs: 'longString',
      },
      {
        Header: t('series:classes:fields:priority').toString(),
        id: 'priority',
      },
      {
        Header: t('series:classes:fields:hidden').toString(),
        id: 'hidden',
        renderAs: 'boolean',
      },
      {
        Header: t('common:fields:createdAt').toString(),
        id: 'created_at',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:updatedAt').toString(),
        id: 'updated_at',
        renderAs: 'timestamp',
      },
    ],
    [t]
  )

  const defaultSelectedColumns = React.useMemo(
    () => (serieID ? ['name', 'priority', 'hidden'] : ['serie_name', 'name', 'priority', 'hidden']),
    [serieID]
  )

  const filterFields: FilterField[] = React.useMemo(
    () => [
      {
        label: t('common:fields:name'),
        name: 'name',
        variant: 'text',
      },
      {
        label: t('common:entities:serie'),
        name: 'serie_name',
        variant: 'text',
      },
      {
        label: t('series:classes:fields:priority'),
        name: 'priority',
        variant: 'number',
      },
      {
        label: t('series:classes:fields:hidden'),
        name: 'hidden',
        variant: 'boolean',
      },
      {
        label: t('common:fields:createdAt'),
        name: 'created_at',
        variant: 'datetime',
      },
      {
        label: t('common:fields:updatedAt'),
        name: 'updated_at',
        variant: 'datetime',
      },
    ],
    [t]
  )

  const handleAdd = React.useCallback(() => {
    setCurrentSerieClass(emptySerieClass)
    setCurrentMode('add')
    onEditorOpen()
  }, [onEditorOpen])

  const addAction = React.useMemo(
    () => (selectedIdentity?.role === 'admin' ? handleAdd : undefined),
    [selectedIdentity, handleAdd]
  )

  const handleEdit = React.useCallback(
    (item: AdminSerieClass) => {
      setCurrentSerieClass(item)
      setCurrentMode('update')
      onEditorOpen()
    },
    [onEditorOpen]
  )

  const handleDelete = React.useCallback(
    (item: AdminSerieClass) => {
      setCurrentSerieClass(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  const actions = React.useMemo(
    () => (selectedIdentity?.role === 'admin' ? [editAction(handleEdit), deleteAction(handleDelete)] : []),
    [selectedIdentity, handleEdit, handleDelete]
  )

  return (
    <Box>
      <Heading fontSize="xl" mb={4}>
        {t('admin:menu:serieClasses')}
      </Heading>
      <DataTable<AdminSerieClass>
        columns={columns}
        defaultSelectedColumns={defaultSelectedColumns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        loading={loading}
        onRefresh={fetch}
        onAdd={addAction}
        filterFields={filterFields}
      />
      <SerieClassEditorModal
        serieID={serieID}
        item={currentSerieClass}
        onClose={onEditorClose}
        open={isEditorOpen}
        onComplete={fetch}
        mode={currentMode}
      />
      <DeleteResourceModal
        table="serie_classes"
        id={currentSerieClass.id}
        onClose={onDeleteClose}
        open={isDeleteOpen}
        onComplete={fetch}
      />
    </Box>
  )
}

export default SerieClasses
