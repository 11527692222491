import * as React from 'react'

import {Container, Stack, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import SignInForm from './form'

const SignIn = () => {
  const {t} = useTranslation()

  return (
    <Container maxW="container.xl" mb={8} px={8}>
      <Stack align="center">
        <Text>{t('auth:signin:signIn')}</Text>
        <Stack w="400px">
          <SignInForm />
        </Stack>
      </Stack>
    </Container>
  )
}

export default SignIn
