import * as React from 'react'

import {RepeatIcon} from '@chakra-ui/icons'
import {Heading, HStack, IconButton, Skeleton, Stack, Text} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {useSupabaseQuery} from '@/common/hooks'
import {EventRentedDevice} from '@/organizer/types'

import DeviceListItem from '../list-item'

const DevicesWidget = () => {
  const {t} = useTranslation()
  const {eventID} = useParams()

  const devices = useSupabaseQuery<EventRentedDevice>({
    fields: '*',
    table: 'event_rented_devices',
    autoFetch: false,
    filter: React.useCallback(
      (b: PostgrestFilterBuilder<EventRentedDevice>) => b.match({event_id: eventID ?? '', virtual: false}),
      [eventID]
    ),
  })

  React.useEffect(() => {
    eventID && devices.fetch()
  }, [eventID]) // eslint-disable-line

  return (
    <Stack>
      <HStack>
        <Text size="sm">{t('organizer:devices:rented')}</Text>
        <IconButton
          aria-label="refetch-virtual-devices"
          icon={<RepeatIcon />}
          onClick={devices.fetch}
          size="sm"
          isLoading={devices.loading}
        />
      </HStack>
      {devices.loading
        ? Array(3)
            .fill(0)
            .map((_, i) => <Skeleton key={i} h="40px" borderRadius="lg" overflow="hidden" />)
        : devices.data?.map((d, i) => <DeviceListItem key={i} device={d} />)}
    </Stack>
  )
}

export default DevicesWidget
