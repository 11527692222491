import * as React from 'react'

import {Map as MapIcon} from 'react-feather'

import {Track} from '@/api/models'
import {DataTableAction} from '@/common/data-table/types'
import i18n from '@/i18n'

export const manageLayoutsAction: DataTableAction<Track> = {
  icon: <MapIcon size="1rem" />,
  id: 'layouts',
  to: (item: Track) => `/admin/track-layouts?filter=and(track_id.eq.${item.id})`,
  tooltip: i18n.t('tracks:manageTrackLayouts'),
}
