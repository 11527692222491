import * as React from 'react'

import {ChevronRightIcon, DeleteIcon, EditIcon} from '@chakra-ui/icons'
import {
  Box,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {AlertTriangle as AlertIcon} from 'react-feather'
import {useTranslation} from 'react-i18next'

import {Device} from '@/api/models'
import {useSupabaseQuery} from '@/common/hooks'
import {EventRentedDevice, RentedDeviceAssignemt} from '@/organizer/types'
import {formatTimestamp} from '@/utils/string'
import {isDurationActive} from '@/utils/time'

import AssignementManager from './assignment-manager'
import {emptyContext} from './constants'
import {Context} from './types'

export const DeviceContext = React.createContext<Context>(emptyContext)

type Props = {
  device: EventRentedDevice
  onEdit?: (item: Device) => void
  onDelete?: (item: Device) => void
}

const DeviceListItem = ({device, onEdit, onDelete}: Props) => {
  const {t} = useTranslation()
  const {isOpen, onToggle} = useDisclosure()
  const assignments = useSupabaseQuery<RentedDeviceAssignemt>({
    fields: '*',
    table: 'rented_device_assignments',
    filter: React.useCallback(
      (b: PostgrestFilterBuilder<RentedDeviceAssignemt>) =>
        b.match({device_id: device.id, event_id: device.event_id}),
      [device]
    ),
  })

  const isCurrentlyRented = React.useMemo(
    () => isDurationActive(device.start_time, device.end_time),
    [device]
  )

  const currentAssignment = React.useMemo(
    () => assignments?.data?.find((a) => isDurationActive(a.start_time, a.end_time)),
    [assignments]
  )

  const context = React.useMemo(
    () => ({device, assignments, isCurrentlyRented}),
    [device, assignments, isCurrentlyRented]
  )

  const handleDelete = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      onDelete && onDelete(device)
    },
    [onDelete, device]
  )

  const handleEdit = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      onEdit && onEdit(device)
    },
    [onEdit, device]
  )

  return (
    <DeviceContext.Provider value={context}>
      <Flex direction="column" opacity={isCurrentlyRented ? '1' : '0.5'}>
        <HStack
          p={2}
          gap={4}
          w="100%"
          justify="space-between"
          bgColor="gray.900"
          borderRadius="lg"
          zIndex="base"
          onClick={onToggle}
          _hover={{
            transform: 'scale(1.01)',
            cursor: 'pointer',
          }}
          _active={{
            transform: 'scale(0.99)',
          }}
          transition="all 150ms ease"
        >
          <HStack w="100%" gap={4}>
            <ChevronRightIcon
              boxSize={6}
              transition="all 250ms ease"
              transform={isOpen ? 'rotate(90deg)' : 'none'}
            />
            <Text>{device.name}</Text>
            {!currentAssignment ? (
              <HStack color="whiteAlpha.600">
                <AlertIcon size="1rem" />
                <Text fontSize="sm">{t('organizer:devices:notAssigned')}</Text>
              </HStack>
            ) : (
              <HStack flex="1" justify="space-between">
                <Tag>{currentAssignment?.type}</Tag>
                <HStack spacing={4}>
                  <Text>{t('organizer:devices:assigned')}</Text>
                  <HStack>
                    <Text>{t('common:from')}:</Text>
                    <Tag>{formatTimestamp(currentAssignment?.start_time)}</Tag>
                  </HStack>
                  <HStack>
                    <Text>{t('common:to')}:</Text>
                    <Tag>{formatTimestamp(currentAssignment?.end_time)}</Tag>
                  </HStack>
                </HStack>
              </HStack>
            )}
          </HStack>
          {device.virtual && (
            <HStack>
              <Tooltip label={t('common:actions:edit')}>
                <IconButton aria-label="edit-device" icon={<EditIcon />} onClick={handleEdit} size="sm" />
              </Tooltip>
              <Tooltip label={t('common:actions:delete')}>
                <IconButton
                  aria-label="delete-device"
                  icon={<DeleteIcon />}
                  onClick={handleDelete}
                  size="sm"
                />
              </Tooltip>
            </HStack>
          )}
        </HStack>
        <Collapse in={isOpen} animateOpacity={true} unmountOnExit={true}>
          <Flex w="100%" px={6} justify="center">
            <Stack p={2} w="100%" bgColor="blackAlpha.500" borderRadius="0 0 0.75rem 0.75rem" spacing={4}>
              <Flex gap={4}>
                {!device.virtual && (
                  <Stack>
                    <HStack>
                      <Text fontWeight="bold">{t('organizer:devices:rented')}</Text>
                      <Box
                        boxSize={2}
                        borderRadius="full"
                        bgColor={isCurrentlyRented ? 'green.400' : 'red.400'}
                      />
                    </HStack>
                    <Stack align="stretch">
                      <HStack justify="space-between">
                        <Text>{t('common:from')}:</Text>
                        <Tag>{formatTimestamp(device.start_time)}</Tag>
                      </HStack>
                      <HStack justify="space-between">
                        <Text>{t('common:to')}:</Text>
                        <Tag>{formatTimestamp(device.end_time)}</Tag>
                      </HStack>
                    </Stack>
                  </Stack>
                )}
                <Stack flex="1">
                  <Text fontWeight="bold">{t('common:fields:description')}</Text>
                  <Text>{device.description}</Text>
                </Stack>
              </Flex>
              <AssignementManager />
            </Stack>
          </Flex>
        </Collapse>
      </Flex>
    </DeviceContext.Provider>
  )
}

export default DeviceListItem
