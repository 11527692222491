import * as React from 'react'

import {FaTags} from 'react-icons/fa'

import {AdminOrganizer} from '@/api/models'
import {DataTableAction} from '@/common/data-table/types'
import i18n from '@/i18n'

export const manageTagsAction: DataTableAction<AdminOrganizer> = {
  icon: <FaTags size="1rem" />,
  id: 'tags',
  to: (item: AdminOrganizer) => `/admin/epc-tags?filter=and(organizer_id.eq.${item.id})`,
  tooltip: i18n.t('admin:organizers:manageTags'),
}
