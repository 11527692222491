import * as React from 'react'

import {Flex, Heading, Stack} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {emptyEvent} from '@/admin/events/constants'
import {AdminEvent, AdminSerieClass} from '@/api/models'
import {useSupabaseQuery} from '@/common/hooks'
import LoadingView from '@/common/loading-view'

import {emptyEventContext} from './constants'
import DevicesWidget from './devices'
import EventForm from './form'
import ParticipantsList from './participants'
import {Context} from './types'

export const EventContext = React.createContext<Context>(emptyEventContext)

const EventPage = () => {
  const {t} = useTranslation()
  const {eventID} = useParams()

  const {loading, data, fetch} = useSupabaseQuery<AdminEvent>({
    fields: '*',
    table: 'organizer_events',
    itemsPerPage: 1,
    filter: React.useCallback(
      (b: PostgrestFilterBuilder<AdminEvent>) => b.eq('id', eventID ?? ''),
      [eventID]
    ),
  })
  const event = React.useMemo(() => (!data?.length ? emptyEvent : data[0]), [data])

  const classes = useSupabaseQuery<AdminSerieClass>({
    fields: '*',
    table: 'serie_classes',
    autoFetch: false,
    order: 'name',
    filter: React.useCallback(
      (query: PostgrestFilterBuilder<AdminSerieClass>) => query.match({serie: event?.serie?.id}),
      [event]
    ),
  })
  React.useEffect(() => {
    event?.serie?.id && classes.fetch()
  }, [event]) // eslint-disable-line

  const context = React.useMemo(() => ({event, classes}), [event, classes])

  if (loading) {
    return <LoadingView />
  }

  if (!event) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Heading>{t('events:eventNotFound')}</Heading>
      </Flex>
    )
  }

  return (
    <EventContext.Provider value={context}>
      <Stack spacing={6}>
        <EventForm item={event} refetch={fetch} />
        <DevicesWidget />
        <ParticipantsList />
      </Stack>
    </EventContext.Provider>
  )
}

export default EventPage
