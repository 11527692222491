import {type TFunction} from 'i18next'
import {type NameLabelPair} from 'react-querybuilder'

import {FilterVariant, Operator, RuleGroupTypeExtended} from './types'

export const emptyQuery: RuleGroupTypeExtended = {
  combinator: 'and',
  rules: [],
}

// is there any advantage of passing TFunction instead of calling i18n.t()?
export const labelTranslations: (t: TFunction) => Record<Operator, string> = (t: TFunction) => ({
  and: t('common:filter:and'),
  empty: t('common:filter:ops:empty'),
  eq: t('common:filter:ops:eq'),
  gt: t('common:filter:ops:gt'),
  gte: t('common:filter:ops:gte'),
  ilike: t('common:filter:ops:ilike'),
  lt: t('common:filter:ops:lt'),
  lte: t('common:filter:ops:lte'),
  notempty: t('common:filter:ops:notempty'),
  noteq: t('common:filter:ops:noteq'),
  notilike: t('common:filter:ops:notilike'),
  notnull: t('common:filter:ops:notnull'),
  notov: t('common:filter:ops:notov'),
  null: t('common:filter:ops:null'),
  or: t('common:filter:or'),
  ov: t('common:filter:ops:ov'),
})

const toNameLabelPair = (t: TFunction, labelOperator: Operator): NameLabelPair => ({
  label: labelTranslations(t)[labelOperator],
  name: labelOperator,
})

export const combinators = (t: TFunction) => {
  const operators: Operator[] = ['and', 'or']
  return operators.map((c) => toNameLabelPair(t, c))
}

export const allLabeledOperators = (t: TFunction) => {
  const operators: Operator[] = [
    'empty',
    'eq',
    'gt',
    'gte',
    'ilike',
    'lt',
    'lte',
    'notempty',
    'noteq',
    'notilike',
    'notnull',
    'notov',
    'null',
    'ov',
  ]
  return operators.map((f) => toNameLabelPair(t, f))
}

const textOperators = (t: TFunction) => {
  const operators: Operator[] = ['eq', 'noteq', 'ilike', 'notilike', 'null', 'notnull']
  return operators.map((f) => toNameLabelPair(t, f))
}

const selectOperators = (t: TFunction) => {
  const operators: Operator[] = ['eq', 'noteq']
  return operators.map((f) => toNameLabelPair(t, f))
}

const rangeOperators = (t: TFunction) => {
  const operators: Operator[] = ['eq', 'noteq', 'lt', 'lte', 'gt', 'gte', 'null', 'notnull']
  return operators.map((f) => toNameLabelPair(t, f))
}

const dateOperators = (t: TFunction) => {
  const operators: Operator[] = ['eq', 'noteq', 'gt', 'gte', 'lt', 'lte', 'null', 'notnull']
  return operators.map((f) => toNameLabelPair(t, f))
}

const listOperators = (t: TFunction) => {
  const operators: Operator[] = ['ov', 'notov', 'empty', 'notempty']
  return operators.map((f) => toNameLabelPair(t, f))
}

const existsOperators = (t: TFunction) => {
  const operators: Operator[] = ['null', 'notnull']
  return operators.map((f) => toNameLabelPair(t, f))
}

export const filterInputVariantToOperators: (t: TFunction) => Record<FilterVariant, NameLabelPair[]> = (
  t: TFunction
) => ({
  boolean: [toNameLabelPair(t, 'eq')],
  date: dateOperators(t),
  datetime: dateOperators(t),
  exists: existsOperators(t),
  list: listOperators(t),
  number: rangeOperators(t),
  select: selectOperators(t),
  text: textOperators(t),
})

export const filterInputVariantToInputType: Partial<Record<FilterVariant, string>> = {
  date: 'date',
  datetime: 'datetime-local',
  number: 'number',
  select: 'text',
  text: 'text',
}

export const filterInputVariantToValueEditorType: Partial<Record<FilterVariant, string>> = {
  boolean: 'select',
  select: 'select',
}

export const filterInputVariantToSelectPresets: (
  t: TFunction
) => Partial<Record<FilterVariant, NameLabelPair[]>> = (t: TFunction) => ({
  boolean: [
    {
      label: t('common:true').toLowerCase(),
      name: 'true',
    },
    {
      label: t('common:false').toLowerCase(),
      name: 'false',
    },
  ],
})
