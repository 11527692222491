import {EditorMode} from '@/admin/types'
import {Device} from '@/api/models'

export const inputToUpsertArgs = (input: Device, mode: EditorMode, eventID?: string) => ({
  device_id: mode === 'update' ? input.id : undefined,
  name: input.name,
  description: input.description,
  virtual: eventID ? true : input.virtual,
  username: input.username,
  password: input.password,
  event_id: eventID || undefined,
})
