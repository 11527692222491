import * as React from 'react'

import {EditIcon, DeleteIcon} from '@chakra-ui/icons'

import i18n from '@/i18n'

import {DataTableAction} from './types'

export const editAction = <T extends {}>(onClick: (item: T) => void): DataTableAction<T> => ({
  icon: <EditIcon />,
  id: 'edit',
  onClick,
  tooltip: i18n.t('common:actions:edit'),
})

export const deleteAction = <T extends {}>(onClick: (item: T) => void): DataTableAction<T> => ({
  icon: <DeleteIcon />,
  id: 'delete',
  onClick,
  tooltip: i18n.t('common:actions:delete'),
})
