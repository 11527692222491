import * as React from 'react'

const useLoadingState = (onSave: () => Promise<void> | void) => {
  const [loading, setLoading] = React.useState(false)
  const [done, setDone] = React.useState(false)
  const [error, setError] = React.useState<Error | null>(null)

  const handleSubmit = React.useCallback(async () => {
    setLoading(true)
    try {
      await onSave()
      setDone(true)
    } catch (e) {
      setLoading(false)
      setError(e as Error)
      return
    }
    setLoading(false)
  }, [setLoading, onSave, setDone, setError])

  return {done, error, handleSubmit, loading}
}

export default useLoadingState
