import * as React from 'react'

import {FaCarSide, FaTags} from 'react-icons/fa'

import {Participant} from '@/api/models'
import {DataTableAction} from '@/common/data-table/types'
import i18n from '@/i18n'

export const manageCarsAction: DataTableAction<Participant> = {
  icon: <FaCarSide size="1rem" />,
  id: 'cars',
  to: (item: Participant) => `/admin/cars?filter=and(participant.eq.${item.id})`,
  tooltip: i18n.t('events:participants:manageCars'),
}

export const manageTagsAction: DataTableAction<Participant> = {
  icon: <FaTags size="1rem" />,
  id: 'tags',
  to: (item: Participant) => `/admin/participant-tags?filter=and(participant_id.eq.${item.id})`,
  tooltip: i18n.t('events:participants:manageTags'),
}
