import * as React from 'react'

import {Menu, MenuButton, MenuList, Text, useColorModeValue} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import SignInForm from '@/auth/signin/form'

const SignInMenu = () => {
  const {t} = useTranslation()

  return (
    <Menu placement="bottom-end">
      <MenuButton
        p={2}
        rounded="md"
        _hover={{
          bg: useColorModeValue('gray.200', 'gray.700'),
          textDecoration: 'none',
        }}
        fontSize="sm"
        cursor="pointer"
      >
        <Text whiteSpace="nowrap">{t('navbar:signin')}</Text>
      </MenuButton>
      <MenuList minWidth={300} p={3}>
        <SignInForm />
      </MenuList>
    </Menu>
  )
}

export default SignInMenu
