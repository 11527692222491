import * as React from 'react'

import {AddIcon, ChevronLeftIcon, ChevronRightIcon, RepeatIcon} from '@chakra-ui/icons'
import {
  Table,
  Thead,
  Tr,
  chakra,
  Tbody,
  Td,
  Skeleton,
  Box,
  Flex,
  IconButton,
  Spacer,
  Select,
  Text,
  Divider,
  useDisclosure,
  Th,
  HStack,
} from '@chakra-ui/react'
import _ from 'lodash'
import {Columns as ColumnsIcon} from 'react-feather'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'
import {useTable, Column, HeaderGroup, IdType} from 'react-table'

import FilterMenu from '../filter-builder/menu'
import ActionButton from './action-button'
import ColumnSelector from './column-selector'
import useColumnSelectorState from './column-selector/use-column-selector-state'
import {renderPresetToRenderer, defaultRowsPerPagePresets} from './constants'
import TableHeader from './table-header'
import {Props, Column as DataTableColumn} from './types'

const DataTable = <T extends {}>({
  data,
  columns,
  loading,
  totalCount,
  onRefresh,
  onAdd,
  defaultSelectedColumns,
  tableState: {
    descending,
    page,
    rowsPerPage,
    sortBy,
    setRowsPerPage,
    setDescending,
    setSortBy,
    goToPreviousPage,
    goToNextPage,
    previousCount,
    setPreviousCount,
    setPage,
    setFilter,
    filter,
  },
  actions,
  filterFields,
}: Props<T>) => {
  const {t} = useTranslation()

  const computedData = React.useMemo(
    () => (loading ? Array.from(new Array(previousCount || rowsPerPage)).map(() => ({} as T)) : data),
    [loading, data, rowsPerPage, previousCount]
  )

  const computedColumns: Column<T>[] = React.useMemo(
    () =>
      columns.map((c) => {
        if (!c.renderAs) {
          return {...c, accessor: (c.renderer ?? c.id) as keyof T extends never ? IdType<T> : never}
        }
        const {renderAs} = c
        return {
          ...c,
          accessor: (item: T) => renderPresetToRenderer[renderAs](item[c.id as keyof T]),
        } as Column<T>
      }),
    [columns]
  )

  const columnByID = React.useMemo(() => {
    const map: Record<string, DataTableColumn<T>> = {}
    for (const c of columns) {
      map[c.id] = c
    }
    return map
  }, [columns])

  const {handleSelectedColumnsChange, selectedColumns, selectedColumnIDs} = useColumnSelectorState<T>(
    computedColumns,
    defaultSelectedColumns
  )

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
    columns: selectedColumns,
    data: computedData,
  })

  const {
    isOpen: columnSelectorOpen,
    onOpen: onColumnSelectorOpen,
    onClose: onColumnSelectorClose,
  } = useDisclosure()

  const columnSelectorItems = React.useMemo(
    () =>
      loading ? [] : computedColumns.map(({id, Header}) => ({id: id as string, label: Header as string})),
    [loading, computedColumns]
  )

  const handleRowsPerPageChange = React.useCallback(
    ({target: {value}}: React.ChangeEvent<HTMLSelectElement>) => {
      setPage(0)
      setRowsPerPage(+value)
    },
    [setRowsPerPage, setPage]
  )

  const handlePreviousClick = React.useCallback(() => {
    goToPreviousPage()
    setPreviousCount(totalCount)
  }, [goToPreviousPage, setPreviousCount, totalCount])
  const handleNextClick = React.useCallback(() => {
    goToNextPage()
    setPreviousCount(totalCount)
  }, [goToNextPage, setPreviousCount, totalCount])

  const handleHeaderClick = React.useCallback(
    (column: HeaderGroup<T>) => {
      setDescending(!descending && sortBy.column === column.id)
      setSortBy({column: column.id, tag: columnByID[column.id]?.sortFieldTag})
    },
    [setDescending, setSortBy, sortBy, descending, columnByID]
  )

  const [searchParams] = useSearchParams()
  React.useEffect(() => {
    const filterQuery = searchParams.get('filter')
    setFilter(filterQuery ? (builder) => builder.or(filterQuery) : undefined)
  }, [searchParams, setFilter])

  return (
    <>
      <Box borderWidth="1px" borderRadius="lg">
        <HStack padding={1}>
          {filterFields && <FilterMenu filterFields={filterFields} />}
          <Spacer />
          {onAdd && (
            <IconButton size="sm" aria-label="add" variant="outline" onClick={onAdd}>
              <AddIcon />
            </IconButton>
          )}
          {onRefresh && (
            <IconButton
              size="sm"
              aria-label="refresh"
              variant="outline"
              onClick={onRefresh}
              isDisabled={loading}
            >
              <RepeatIcon />
            </IconButton>
          )}
          <IconButton size="sm" aria-label="columns" variant="outline" onClick={onColumnSelectorOpen}>
            <ColumnsIcon height={16} />
          </IconButton>
        </HStack>
        <Divider />
        <Box overflowX="auto">
          <Table size="sm" {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup, headerGroupIndex) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                  <>
                    {headerGroup.headers.map((column, columnIndex) => (
                      <TableHeader
                        key={columnIndex}
                        column={column}
                        onClick={handleHeaderClick}
                        sorted={sortBy.column === column.id}
                        descending={descending}
                        sortable={columnByID[column.id]?.sortable}
                      />
                    ))}
                    {!!actions?.length && <Th textAlign="right">{t('common:dataTable:actions')}</Th>}
                  </>
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row)
                return (
                  <Tr {...row.getRowProps()} key={rowIndex}>
                    <>
                      {row.cells.map((cell, cellIndex) => (
                        <Td
                          {...cell.getCellProps()}
                          key={cellIndex}
                          whiteSpace={columnByID[cell.column.id]?.disableNoWrap ? undefined : 'nowrap'}
                        >
                          {loading ? <Skeleton height="20px" /> : cell.render('Cell')}
                        </Td>
                      ))}
                      {!!actions?.length && (
                        <Td>
                          <Flex flexDirection="row" alignItems="center" justifyContent="flex-end">
                            {actions.map((action, actionIndex) => (
                              <Box key={actionIndex} mr={actionIndex === actions.length - 1 ? 0 : 2}>
                                <ActionButton item={row.original} action={action} />
                              </Box>
                            ))}
                          </Flex>
                        </Td>
                      )}
                    </>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </Box>
        <Flex pr={4} py={2} alignItems="center" fontSize="sm">
          <chakra.span ml={4}>
            {t('common:dataTable:totalCount')}: {totalCount}
          </chakra.span>
          <Spacer />
          <Text mr={2}>{t('common:dataTable:rowsPerPage')}:</Text>
          <Select
            size="sm"
            width={85}
            mr={8}
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            isDisabled={loading}
          >
            {defaultRowsPerPagePresets.map((v, i) => (
              <option key={i} value={v}>
                {v}
              </option>
            ))}
          </Select>
          <IconButton
            size="sm"
            aria-label="previous-page"
            variant="outline"
            icon={<ChevronLeftIcon h={6} w={6} />}
            isDisabled={!page || loading}
            onClick={handlePreviousClick}
          />
          <chakra.span mr={4} ml={4}>
            {t('common:dataTable:page')} {page + 1} {t('common:dataTable:outOf')}{' '}
            {Math.ceil(totalCount / rowsPerPage)}
          </chakra.span>
          <IconButton
            size="sm"
            aria-label="next-page"
            variant="outline"
            icon={<ChevronRightIcon h={6} w={6} />}
            isDisabled={page + 1 === Math.ceil(totalCount / rowsPerPage) || loading}
            onClick={handleNextClick}
          />
        </Flex>
      </Box>
      <ColumnSelector
        open={columnSelectorOpen}
        onClose={onColumnSelectorClose}
        items={columnSelectorItems}
        initialValue={selectedColumnIDs}
        onApply={handleSelectedColumnsChange}
      />
    </>
  )
}

export default DataTable
