import {extendTheme} from '@chakra-ui/react'

import * as components from './components'
import {FONT_OPEN_SANS, FONT_RALEWAY} from './constants'
import * as foundations from './foundations'

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: true,
  },
  ...foundations,
  components: {
    ...components,
  },
  fonts: {
    heading: FONT_RALEWAY,
    body: FONT_OPEN_SANS,
  },
})

export default theme
