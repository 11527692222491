import * as React from 'react'

import {useToast} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {emptyEvent} from '@/admin/events/constants'
import {supabase} from '@/api'
import {Event, SerieClass} from '@/api/models'

const useEventsClasses = (eventID: string) => {
  const {t} = useTranslation()
  const toast = useToast()
  const [loading, setLoading] = React.useState(true)
  const [event, setEvent] = React.useState<Event>(emptyEvent)
  const [classes, setClasses] = React.useState<SerieClass[]>([])

  const fetch = React.useCallback(async () => {
    if (!eventID) {
      return
    }
    setLoading(true)
    try {
      const {data: eventData, error: eventError} = await supabase
        .from('admin_events')
        .select('*')
        .match({id: eventID})
        .limit(1)
        .single()
      if (eventError) throw eventError
      if (!eventData) throw new Error('Invalid event id')

      setEvent(eventData)

      const {data: classesData, error: classesError} = await supabase
        .from('admin_serie_classes')
        .select('*')
        .match({serie_id: eventData.serie_id})
        .order('name')
      if (classesError) throw classesError
      if (!classesData || classesData.length === 0) throw new Error('No classes found')

      setClasses(classesData)
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        status: 'error',
        title: t('errors:resourcesFetchFail'),
      })
    } finally {
      setLoading(false)
    }
  }, [eventID, t, toast])

  React.useEffect(() => {
    eventID && fetch()
  }, [eventID, fetch])

  return {event, classes, loading, fetch}
}

export default useEventsClasses
