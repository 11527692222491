import {AdminSerie} from '@/api/models'

export const emptySerie: AdminSerie = {
  id: '',
  created_at: new Date(),
  updated_at: new Date(),
  organizer_id: '',
  name: '',
  public: false,
  logo: undefined,
  logo_url: undefined,
  start_date: '',
  end_date: '',
}
