import {RentedDeviceAssignemt, EventRentedDevice} from '@/organizer/types'

export const inputToUpsertArgs = (input: RentedDeviceAssignemt, device: EventRentedDevice) => ({
  device_assignment_id: input.id || undefined,
  device_id: device.id,
  event_id: device.event_id,
  type: input.type,
  start_time: input.start_time,
  end_time: input.end_time,
})
