import * as React from 'react'

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {EditorMode} from '@/admin/types'
import {AdminOrganizerUser, RPC, OrganizerMember} from '@/api/models'
import {useSupabaseRPC} from '@/common/hooks'
import {emptyMember, userRoleItems} from '@/organizer/constants'
import {selectOrganizer} from '@/organizer/state'
import {useAppSelector} from '@/store'
import {readOnlyStyles} from '@/theme/components/input'
import {CommonModalProps} from '@/utils/types'

import {inputToUpsertArgs} from './utils'

const MemberEditorModal = ({
  item,
  mode,
  open,
  onComplete,
  onClose,
}: CommonModalProps & {item?: OrganizerMember; mode: EditorMode}) => {
  const {t} = useTranslation()
  const organizer = useAppSelector(selectOrganizer)
  const [input, setInput] = React.useState<AdminOrganizerUser>(emptyMember)
  const {handleRPC, loading} = useSupabaseRPC({
    fnName: RPC.UpsertOrganizersUsers,
    params: inputToUpsertArgs(organizer?.id ?? '', input),
    mode,
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput({email: item.email, role: item.role})
  }, [item])

  const handleEmailChange = React.useCallback(({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
    setInput((prev) => ({...prev, email: value}))
  }, [])

  const handleRoleChange = React.useCallback(({target: {value}}: React.ChangeEvent<HTMLSelectElement>) => {
    if (value !== 'admin' && value !== 'basic') return
    setInput((prev) => ({...prev, role: value}))
  }, [])

  const isSubmitDisabled = React.useMemo(() => !input.email || !input.role, [input])

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`organizer:members:addMember`)
            // t(`organizer:members:updateMember`)
            t(`organizer:members:${mode}Member`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack w="100%" align="flex-end">
            <FormControl isDisabled={loading} isReadOnly={mode !== 'add'}>
              <FormLabel>{t('common:fields:email')}</FormLabel>
              <Input
                placeholder={t('common:fields:email')}
                value={input.email}
                onChange={handleEmailChange}
                _readOnly={readOnlyStyles}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>{t('common:fields:role')}</FormLabel>
              <Select value={input.role} onChange={handleRoleChange}>
                {userRoleItems.map((r) => (
                  <option key={r.value} value={r.value}>
                    {r.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </HStack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleRPC} isDisabled={isSubmitDisabled}>
              {t('common:actions:save')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default MemberEditorModal
