import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import type {User} from '@supabase/supabase-js'

import {supabase} from '@/api'
import {User as UserProfile, SelectedIdentity} from '@/api/models'
import {RootState} from '@/store'

export interface State {
  profile?: UserProfile
  profileLoading: boolean
  user?: User
  selectedIdentity?: SelectedIdentity
}

const initialState: State = {
  profileLoading: true,
  user: supabase.auth.session()?.user ?? undefined,
}

export const fetchUser = createAsyncThunk(
  'auth/fetchUser',
  async (user: User | undefined, {dispatch, getState}) => {
    const loading = (getState() as RootState).auth.profileLoading
    if (!loading) {
      dispatch(setProfileLoading(true))
    }

    dispatch(setUser(user))
    if (!user) {
      return
    }

    const thisUser = await supabase
      .from<UserProfile>('user_profile')
      .select('*')
      .filter('id', 'eq', user.id)
      .single()
    return thisUser.body
  }
)

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    setProfileLoading: (state, action: PayloadAction<boolean>) => {
      state.profileLoading = action.payload
    },
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload
    },
    setSelectedIdentity: (
      state,
      {payload}: PayloadAction<{type: 'user' | 'organizer'; id?: string} | undefined>
    ) => {
      if (!payload) {
        state.selectedIdentity = undefined
        return
      }

      switch (payload?.type) {
        case 'user':
          state.selectedIdentity = {
            type: 'user',
            id: state.user?.id ?? '',
            name: state.profile?.email,
            image_url: state.profile?.avatar_url,
            role: state.profile?.role ?? 'user',
          }
          return
        case 'organizer': {
          const organizer = state.profile?.assigned_organizers.find((o) => o.id === payload?.id)
          if (!organizer) {
            return
          }
          state.selectedIdentity = {
            type: 'organizer',
            id: organizer.id,
            name: organizer.name,
            image_url: organizer.logo_url,
            role: organizer.role,
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.profileLoading = false
      state.profile = action.payload ?? undefined
    })
    builder.addCase(fetchUser.rejected, (state) => {
      state.profileLoading = false
      state.profile = undefined
      state.selectedIdentity = undefined
    })
  },
})

export const {setProfileLoading, setUser, setSelectedIdentity} = authSlice.actions

export const selectUser = (state: RootState) => state.auth.user
export const selectProfileLoading = (state: RootState) => state.auth.profileLoading
export const selectProfile = (state: RootState) => state.auth.profile
export const selectRole = (state: RootState) => state.auth.profile?.role ?? 'user'
export const selectSelectedIdentity = (state: RootState) => state.auth.selectedIdentity

export default authSlice.reducer
