import * as React from 'react'

import {ChevronRightIcon} from '@chakra-ui/icons'
import {
  Button,
  Stack,
  Image,
  Text,
  useBreakpointValue,
  Grid,
  GridItem,
  HStack,
  Collapse,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {OrganizerSerie, SerieWithEvents} from '@/api/models'
import EventTile from '@/organizer/events/event-tile'

const SerieTile = ({serie, organizerID}: {serie: SerieWithEvents | OrganizerSerie; organizerID?: string}) => {
  const {t} = useTranslation()
  const {isOpen, onToggle} = useDisclosure()

  return (
    <Stack w="full" alignItems="stretch" justifyContent="center" spacing={4}>
      <HStack
        justify="space-between"
        position="sticky"
        top={4}
        zIndex="sticky"
        bgColor="gray.700"
        p={2}
        borderRadius="lg"
      >
        <HStack spacing={4}>
          <Tooltip label={t('organizer:series:showEvents')}>
            <Button onClick={onToggle} variant="ghost" p={0}>
              <ChevronRightIcon
                boxSize="36px"
                transition="all 250ms ease"
                transform={`rotate(${isOpen ? '90deg' : 0})`}
              />
            </Button>
          </Tooltip>
          <Button
            variant="link"
            as={Link}
            to={organizerID ? `/organizer/${organizerID}/serie/${serie.id}` : `/season-rankings/${serie.id}`}
          >
            <HStack spacing={4}>
              {serie.logo_url && <Image src={serie.logo_url} h="50px" />}
              <Text fontSize="xl">{serie.name}</Text>
            </HStack>
          </Button>
        </HStack>
        <Button as={Link} to={`/season-rankings/${serie.id}`}>
          {t('series:seasonRankings')}
        </Button>
      </HStack>
      <Collapse in={isOpen} animateOpacity={true}>
        <Grid
          templateColumns={useBreakpointValue({
            sm: 'repeat(4, 1fr)',
            md: 'repeat(8, 1fr)',
            lg: 'repeat(12, 1fr)',
          })}
          gap={4}
        >
          {serie.events?.map((e) => (
            <GridItem key={e.id} colSpan={4}>
              <EventTile event={e} />
            </GridItem>
          ))}
        </Grid>
      </Collapse>
    </Stack>
  )
}

export default SerieTile
