import * as React from 'react'

import {Input, type InputProps} from '@chakra-ui/react'
import {enGB, pl} from 'date-fns/locale'
import DatePicker from 'react-datepicker'
import {useTranslation} from 'react-i18next'

import 'react-datepicker/dist/react-datepicker.css'

const DefaultDatePickerInput = React.forwardRef((props: InputProps, ref: React.Ref<HTMLInputElement>) => (
  <Input size="sm" ref={ref} {...props} />
))
DefaultDatePickerInput.displayName = 'DefaultDatePickerInput'

const DatetimeInput = ({
  showTimeSelect,
  defaultValue,
  onChange,
  value,
  ...props
}: Omit<InputProps, 'onChange' | 'defaultValue' | 'value'> & {
  defaultValue?: Date | null
  showTimeSelect?: boolean
  onChange?: (datetime: Date | null) => void
  value?: Date | null
}) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(defaultValue || null)

  const {i18n, t} = useTranslation()

  const handleDateChange = React.useCallback(
    (date: Date | null) => {
      setSelectedDate(date)
      onChange && onChange(date)
    },
    [onChange]
  )

  const inputComponent = React.useMemo(() => <DefaultDatePickerInput {...props} />, [props])
  return (
    <DatePicker
      selected={value || selectedDate}
      onChange={handleDateChange}
      dateFormat="dd/MM/yyyy HH:mm:ss"
      customInput={inputComponent}
      showTimeSelect={showTimeSelect}
      locale={i18n.language === 'en' ? enGB : pl}
      timeCaption={t('common:datetimePicker:time')}
    />
  )
}

export default DatetimeInput
