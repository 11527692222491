import * as React from 'react'

import {Avatar, Box, Heading, Tag, useDisclosure, HStack, Text} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'

import {EditorMode} from '@/admin/types'
import {supabase} from '@/api'
import {OrganizerMember} from '@/api/models'
import {selectSelectedIdentity} from '@/auth/state'
import {DeleteResourceModal} from '@/common/components'
import DataTable from '@/common/data-table'
import {Column} from '@/common/data-table/types'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction, editAction} from '@/common/data-table/utils'
import {useSupabaseQuery} from '@/common/hooks'
import {useAppSelector} from '@/store'

import {emptyMember} from '../constants'
import {selectOrganizer} from '../state'
import EditorModal from './editor-modal'

const defaultSelectedColumns = ['avatar', 'email', 'role', 'public']

const roleColor = {
  admin: 'red',
  basic: 'gray',
}

const Members = () => {
  const {t} = useTranslation()
  const organizer = useAppSelector(selectOrganizer)
  const selectedIdentity = useAppSelector(selectSelectedIdentity)
  const tableState = useTableState({sortBy: 'created_at'})
  const [currentMember, setCurrentMember] = React.useState(emptyMember)
  const [currentMode, setCurrentMode] = React.useState<EditorMode>('add')
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const {loading, data, fetch, rows} = useSupabaseQuery<OrganizerMember>({
    fields: '*',
    table: 'organizer_members',
    order: tableState.sortBy.column as keyof OrganizerMember,
    itemsPerPage: tableState.rowsPerPage,
    pageNumber: tableState.page,
    descending: tableState.descending,
    filter: React.useCallback(
      (b: PostgrestFilterBuilder<OrganizerMember>) => b.eq('organizer_id', organizer?.id ?? ''),
      [organizer]
    ),
  })

  const columns: Column<OrganizerMember>[] = React.useMemo(
    () => [
      {
        Header: t('common:fields:email').toString(),
        id: 'email',
        renderer: (m) => (
          <HStack>
            {m.avatar_url ? <Avatar src={m.avatar_url} size="sm" /> : <Avatar size="sm" />}
            <Text>{m.email}</Text>
          </HStack>
        ),
      },
      {
        Header: t('common:fields:role').toString(),
        id: 'role',
        renderer: ({role}) => (
          <Tag borderRadius="full" colorScheme={roleColor[role]}>
            {role}
          </Tag>
        ),
      },
      {
        Header: t('common:fields:createdAt').toString(),
        id: 'created_at',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:updatedAt').toString(),
        id: 'updated_at',
        renderAs: 'timestamp',
      },
    ],
    [t]
  )

  const handleDelete = React.useCallback(async () => {
    const {error} = await supabase
      .from('organizer_users')
      .delete()
      .match({user: currentMember.user_id, organizer: organizer?.id})
    if (error) throw error
  }, [currentMember, organizer])

  const handleAdd = React.useCallback(() => {
    setCurrentMember(emptyMember)
    setCurrentMode('add')
    onEditorOpen()
  }, [onEditorOpen])

  const addAction = React.useMemo(
    () => (selectedIdentity?.role === 'admin' ? handleAdd : undefined),
    [selectedIdentity, handleAdd]
  )

  const handleEdit = React.useCallback(
    (item: OrganizerMember) => {
      setCurrentMember(item)
      setCurrentMode('update')
      onEditorOpen()
    },
    [onEditorOpen]
  )

  const handleDeleteOpen = React.useCallback(
    (item: OrganizerMember) => {
      setCurrentMember(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  const actions = React.useMemo(
    () =>
      selectedIdentity?.role === 'admin' ? [editAction(handleEdit), deleteAction(handleDeleteOpen)] : [],
    [handleEdit, handleDeleteOpen, selectedIdentity]
  )

  return (
    <Box>
      <Heading fontSize="xl" mb={4}>
        {t('organizer:members:manageMembers')}
      </Heading>
      <DataTable<OrganizerMember>
        columns={columns}
        defaultSelectedColumns={defaultSelectedColumns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        loading={loading}
        onRefresh={fetch}
        onAdd={addAction}
      />
      <EditorModal
        item={currentMember}
        mode={currentMode}
        open={isEditorOpen}
        onClose={onEditorClose}
        onComplete={fetch}
      />
      <DeleteResourceModal
        open={isDeleteOpen}
        onDelete={handleDelete}
        onClose={onDeleteClose}
        onComplete={fetch}
      />
    </Box>
  )
}

export default Members
