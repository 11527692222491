import * as React from 'react'

import {AddIcon} from '@chakra-ui/icons'
import {Stack, HStack, useToast, Spinner, IconButton, Heading} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {supabase} from '@/api'
import {AdminOrganizerUser} from '@/api/models'

import UserItem from './user-item'

type Props = {
  id: string
  setUsers: React.Dispatch<React.SetStateAction<AdminOrganizerUser[]>>
  users: AdminOrganizerUser[]
  loading: boolean
}

const UserManager = ({id, users, setUsers, loading}: Props) => {
  const {t} = useTranslation()
  const toast = useToast()
  const [usersLoading, setUsersLoading] = React.useState(false)

  const fetchUsers = React.useCallback(async () => {
    try {
      setUsersLoading(true)
      const {data, error} = await supabase.from('admin_organizer_users').select('*').match({organizer_id: id})
      if (error) throw error
      if (!data) throw new Error(t('errors:resourcesFetchFail'))

      setUsers(data.map((i) => ({email: i.email, role: i.role})))
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        title: t('errors:resourcesFetchFail'),
        variant: 'error',
      })
    }
    setUsersLoading(false)
  }, [id, t, toast, setUsers])

  React.useEffect(() => {
    id ? fetchUsers() : setUsers([])
  }, [id, fetchUsers, setUsers])

  const handleUserAdd = React.useCallback(() => {
    setUsers((users) => [...users, {email: '', role: 'basic'}])
  }, [setUsers])

  const handleUserDelete = React.useCallback(
    (index: number) => {
      setUsers((users) => users.filter((_, i) => i !== index))
    },
    [setUsers]
  )

  const handleUserChange = React.useCallback(
    (value: AdminOrganizerUser, index: number) => {
      setUsers((users) => users.map((u, i) => (i === index ? value : u)))
    },
    [setUsers]
  )

  return (
    <Stack w="100%">
      <HStack justify="space-between">
        <Heading size="md">{t('organizer:manageMembers')}</Heading>
        <IconButton
          aria-label={t('organizer:addMember')}
          icon={<AddIcon />}
          onClick={handleUserAdd}
          isDisabled={loading}
        />
      </HStack>
      <Stack spacing={4} align="center" width="100%">
        {usersLoading ? (
          <Spinner size="xl" />
        ) : (
          <>
            {users?.map((u, i) => (
              <UserItem
                key={i}
                user={u}
                index={i}
                onChange={handleUserChange}
                onDelete={handleUserDelete}
                loading={loading}
              />
            ))}
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default UserManager
