import * as React from 'react'

import {FormControl, FormLabel} from '@chakra-ui/form-control'
import {HStack, Input, Stack, FormHelperText, Box, FormErrorMessage} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {MapContainer, TileLayer} from 'react-leaflet'

import {Location} from '@/api/models'

import './leaflet.css'
import MapMarker from './map-marker'
import {isLatitudeValid, isLongitudeValid} from './utils'

type Props = {
  location: Location
  onChange: (location: Location) => void
  isDisabled?: boolean
}

const LocationInput = ({location, isDisabled = false, onChange}: Props) => {
  const {t} = useTranslation()

  const handleLongitudeChange = React.useCallback(
    ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
      onChange({...location, coordinates: [+value, location.coordinates[1]]})
    },
    [location, onChange]
  )

  const handleLatitudeChange = React.useCallback(
    ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
      onChange({...location, coordinates: [location.coordinates[0], +value]})
    },
    [location, onChange]
  )

  return (
    <Stack px={2} py={2} width="100%">
      <FormControl isRequired={true}>
        <FormLabel>{t('common:locations:location')}</FormLabel>
        <HStack w="100%">
          <FormControl
            id="longitude"
            isDisabled={isDisabled}
            isRequired={true}
            isInvalid={!isLongitudeValid(location.coordinates[0])}
          >
            <FormHelperText mt={0} mb={1}>
              {t('common:locations:longitude')}
            </FormHelperText>
            <Input value={location.coordinates[0]} type="number" onChange={handleLongitudeChange} />
            <FormErrorMessage>{t('common:locations:longitudeOutOfBounds')}</FormErrorMessage>
          </FormControl>
          <FormControl
            id="latitude"
            isDisabled={isDisabled}
            isRequired={true}
            isInvalid={!isLatitudeValid(location.coordinates[1])}
          >
            <FormHelperText mt={0} mb={1}>
              {t('common:locations:latitude')}
            </FormHelperText>
            <Input value={location.coordinates[1]} type="number" onChange={handleLatitudeChange} />
            <FormErrorMessage>{t('common:locations:latitudeOutOfBounds')}</FormErrorMessage>
          </FormControl>
        </HStack>
      </FormControl>
      <Box w="100%" h="500px">
        <MapContainer center={[52.0688246, 19.4709897]} zoom={5} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MapMarker location={location} onChange={onChange} />
        </MapContainer>
      </Box>
    </Stack>
  )
}

export default LocationInput
