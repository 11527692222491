import * as React from 'react'

import {AddIcon, RepeatIcon} from '@chakra-ui/icons'
import {
  Heading,
  Stack,
  Grid,
  GridItem,
  HStack,
  Input,
  useDisclosure,
  NumberInput,
  NumberInputField,
  Button,
  Tooltip,
  Skeleton,
  Center,
  InputRightElement,
  InputGroup,
} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {emptyParticipant} from '@/admin/participants/constants'
import ParticipantEditorModal from '@/admin/participants/editor-modal'
import {EditorMode} from '@/admin/types'
import {Participant} from '@/api/models'
import {DeleteResourceModal, SortByButton} from '@/common/components'
import useTableState from '@/common/data-table/use-table-state'
import {useSupabaseQuery} from '@/common/hooks'
import LoadingView from '@/common/loading-view'

import {emptyFilter} from './constants'
import ParticipantListItem from './participant-list-item'
import {ParticipantFilter} from './types'
import {filterParticipants} from './utils'

const ParticipantsList = () => {
  const {t} = useTranslation()
  const {serieID} = useParams()
  const [filter, setFilter] = React.useState<ParticipantFilter>(emptyFilter)
  const [editorMode, setEditorMode] = React.useState<EditorMode>('add')
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [currentParticipant, setCurrentParticipant] = React.useState<Participant>(emptyParticipant)

  const tableState = useTableState({sortBy: 'number'})
  const {
    loading,
    data: participants,
    fetch,
    rows,
  } = useSupabaseQuery<Participant>({
    fields: '*',
    table: 'participants_view',
    autoFetch: false,
    order: tableState.sortBy.column as keyof Participant,
    descending: tableState.descending,
    filter: React.useCallback(
      (query: PostgrestFilterBuilder<Participant>) => query.match({serie_id: serieID}),
      [serieID]
    ),
  })
  React.useEffect(() => {
    serieID && fetch()
  }, [serieID, fetch])

  const handleFilterChange = React.useCallback(
    ({target: {value, id}}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setFilter((prev) => ({...prev, [id]: id === 'number' ? +value : value}))
    },
    []
  )

  const handleSortByChange = React.useCallback(
    (column: string) => {
      if (!(column in emptyParticipant)) return
      tableState.setSortBy({column})
      tableState.sortBy.column === column
        ? tableState.setDescending(!tableState.descending)
        : tableState.setDescending(false)
    },
    [tableState]
  )

  const handleAdd = React.useCallback(() => {
    if (!serieID) return
    setEditorMode('add')
    setCurrentParticipant({...emptyParticipant, serie_id: serieID})
    onEditorOpen()
  }, [serieID, onEditorOpen])

  const handleEdit = React.useCallback(
    (item: Participant) => {
      setEditorMode('update')
      setCurrentParticipant(item)
      onEditorOpen()
    },
    [onEditorOpen]
  )

  const handleDelete = React.useCallback(
    (item: Participant) => {
      setCurrentParticipant(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  if (!serieID) {
    return <LoadingView />
  }

  return (
    <>
      <Stack spacing={2}>
        <Stack position="sticky" top="15px" zIndex="sticky">
          <HStack justify="space-between">
            <Heading size="md">{t('organizer:events:manageParticipants')}</Heading>
            <Heading size="sm">
              {t('organizer:events:found')}: {rows}
            </Heading>
          </HStack>
          {/* FILTER */}
          <Grid
            p={2}
            w="100%"
            templateColumns="80px repeat(3, 1fr) 90px"
            gap={2}
            bgColor="gray.900"
            borderRadius="md"
            boxShadow="lg"
          >
            <Center>
              {/* NUMBER FILTER */}
              <InputGroup>
                <NumberInput value={filter.number || ''}>
                  <NumberInputField id="number" px={2} placeholder="#" onChange={handleFilterChange} />
                  <InputRightElement>
                    <SortByButton
                      column="number"
                      current={tableState.sortBy.column}
                      descending={tableState.descending}
                      onSortChange={handleSortByChange}
                    />
                  </InputRightElement>
                </NumberInput>
              </InputGroup>
            </Center>
            <Center>
              {/* NAME FILTER */}
              <InputGroup>
                <Input
                  id="name"
                  type="text"
                  placeholder={t('participants:fields:name')}
                  value={filter.name}
                  onChange={handleFilterChange}
                />
                <InputRightElement>
                  <SortByButton
                    column="name"
                    current={tableState.sortBy.column}
                    descending={tableState.descending}
                    onSortChange={handleSortByChange}
                  />
                </InputRightElement>
              </InputGroup>
            </Center>
            <Center>
              {/* FIRST NAME FILTER */}
              <InputGroup>
                <Input
                  id="first_name"
                  type="text"
                  placeholder={t('participants:fields:firstName')}
                  value={filter.first_name}
                  onChange={handleFilterChange}
                />
                <InputRightElement>
                  <SortByButton
                    column="first_name"
                    current={tableState.sortBy.column}
                    descending={tableState.descending}
                    onSortChange={handleSortByChange}
                  />
                </InputRightElement>
              </InputGroup>
            </Center>
            <Center>
              {/* USER FILTER */}
              <InputGroup>
                <Input
                  id="user_email"
                  type="text"
                  placeholder={t('participants:fields:userEmail')}
                  value={filter.user_email}
                  onChange={handleFilterChange}
                />
                <InputRightElement>
                  <SortByButton
                    column="user_email"
                    current={tableState.sortBy.column}
                    descending={tableState.descending}
                    onSortChange={handleSortByChange}
                  />
                </InputRightElement>
              </InputGroup>
            </Center>
            <GridItem>
              <HStack justify="flex-end">
                <Tooltip label={t('common:actions:refresh')}>
                  <Button onClick={fetch} p={1}>
                    <RepeatIcon />
                  </Button>
                </Tooltip>
                <Tooltip label={t('organizer:participants:addParticipant')}>
                  <Button onClick={handleAdd} p={1}>
                    <AddIcon />
                  </Button>
                </Tooltip>
              </HStack>
            </GridItem>
          </Grid>
        </Stack>
        {/* PARTICIPANTS LIST */}
        {loading
          ? Array(10)
              .fill(0)
              .map((_, i) => <Skeleton key={i} h="46px" borderRadius="xl" overflow="hidden" />)
          : participants?.map((p, i) =>
              filterParticipants(p, filter) ? (
                <ParticipantListItem key={p.id} p={p} onEdit={handleEdit} onDelete={handleDelete} />
              ) : null
            )}
      </Stack>
      {/* MODALS */}
      <ParticipantEditorModal
        item={currentParticipant}
        onClose={onEditorClose}
        open={isEditorOpen}
        onComplete={fetch}
        mode={editorMode}
        serieID={serieID}
      />
      <DeleteResourceModal
        table="participants"
        id={currentParticipant.id}
        onClose={onDeleteClose}
        open={isDeleteOpen}
        onComplete={fetch}
      />
    </>
  )
}

export default ParticipantsList
