import {pickSourceParams} from '@/admin/bridge-subscriptions/editor-modal/utils'
import {BridgeSubscription, BridgeSubscriptionSource} from '@/api/models'

export const inputToUpsertArgs = (input: BridgeSubscription, eventID: string) => ({
  bridge_subscription_id: input.id || undefined,
  name: input.name,
  device_id:
    input.source === BridgeSubscriptionSource.CouchDB ||
    input.source === BridgeSubscriptionSource.Materialized
      ? input.device_id
      : undefined,
  event_id: eventID,
  source: input.source,
  source_params: pickSourceParams(input),
  source_state: (input.source === BridgeSubscriptionSource.CouchDB && input.source_state) || {},
  status: input.status,
})
