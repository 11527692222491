import * as React from 'react'

import {Box} from '@chakra-ui/react'

import {selectProfile} from '@/auth/state'
import {useAppSelector} from '@/store'

import SignInMenu from './signin-menu'
import UserMenu from './user-menu'

const Menu = () => {
  const user = useAppSelector(selectProfile)

  return <Box zIndex="popover">{user ? <UserMenu user={user} /> : <SignInMenu />}</Box>
}

export default Menu
