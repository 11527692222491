import * as React from 'react'

import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Image,
  useToast,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {ImageType} from 'react-images-uploading'

import {supabase} from '@/api'
import {User} from '@/api/models'
import {uploadFile} from '@/api/utils'
import {AvatarUpload} from '@/common/components'
import {useLoadingState} from '@/common/hooks'
import {SUPABASE_AVATARS_BUCKET} from '@/constants'
import {CommonModalProps} from '@/utils/types'

const AvatarUploadModal = ({onClose, onComplete, open, user}: CommonModalProps & {user: User}) => {
  const toast = useToast()
  const {t} = useTranslation()
  const [image, setImage] = React.useState<ImageType>()

  const handleUpload = React.useCallback(async () => {
    if (!image?.file) {
      return
    }
    try {
      const path = await uploadFile(SUPABASE_AVATARS_BUCKET, `users/${user.id}`, image.file)
      const {error} = await supabase.rpc('update_user_avatar', {path})
      if (error) throw error

      toast({isClosable: true, status: 'success', title: t('user:avatar:upload:success')})
      onComplete && onComplete()
      onClose()
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        status: 'error',
        title: t('user:avatar:upload:fail'),
      })
    }
  }, [user, image, toast, t, onClose, onComplete])
  const {loading, handleSubmit} = useLoadingState(handleUpload)

  return (
    <Modal isOpen={open} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('user:avatar:upload:title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack align="center">
            <AvatarUpload value={image} onChange={setImage} size="150px" />
            <Button onClick={handleSubmit} isDisabled={!image?.file} isLoading={loading}>
              {t('common:actions:save')}
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AvatarUploadModal
