import {EditorMode} from '@/admin/types'
import {DeviceAssignment} from '@/api/models'

export const inputToUpsertArgs = (input: DeviceAssignment, mode: EditorMode) => ({
  device_assignment_id: mode === 'update' ? input.id : undefined,
  device_id: input.device_id,
  event_id: input.event_id,
  type: input.type,
  start_time: input.start_time,
  end_time: input.end_time,
})
