import * as React from 'react'

import {AddIcon, RepeatIcon} from '@chakra-ui/icons'
import {Heading, HStack, IconButton, Skeleton, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {emptyDevice} from '@/admin/devices/constants'
import DeviceEditorModal from '@/admin/devices/editor-modal'
import {EditorMode} from '@/admin/types'
import {Device} from '@/api/models'
import {DeleteResourceModal} from '@/common/components'
import {useSupabaseQuery} from '@/common/hooks'
import {EventRentedDevice} from '@/organizer/types'

import DeviceListItem from '../list-item'

const VirtualDevices = () => {
  const {t} = useTranslation()
  const {eventID} = useParams()
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [currentDevice, setCurrentDevice] = React.useState<Device>(emptyDevice)
  const [currentMode, setCurrentMode] = React.useState<EditorMode>('add')

  const devices = useSupabaseQuery<EventRentedDevice>({
    fields: '*',
    table: 'event_rented_devices',
    autoFetch: false,
    filter: React.useCallback(
      (b: PostgrestFilterBuilder<EventRentedDevice>) => b.match({event_id: eventID ?? '', virtual: true}),
      [eventID]
    ),
  })
  React.useEffect(() => {
    eventID && devices.fetch()
  }, [eventID]) // eslint-disable-line

  const handleAdd = React.useCallback(() => {
    setCurrentDevice(emptyDevice)
    setCurrentMode('add')
    onEditorOpen()
  }, [onEditorOpen])

  const handleEdit = React.useCallback(
    (item: Device) => {
      setCurrentDevice(item)
      setCurrentMode('update')
      onEditorOpen()
    },
    [onEditorOpen]
  )

  const handleDelete = React.useCallback(
    (item: Device) => {
      setCurrentDevice(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  return (
    <>
      <Stack>
        <HStack>
          <Text size="sm">{t('organizer:devices:virtual')}</Text>
          <IconButton
            aria-label="add-virtual-device"
            icon={<AddIcon />}
            onClick={handleAdd}
            size="sm"
            isDisabled={devices.loading}
          />
          <IconButton
            aria-label="refetch-virtual-devices"
            icon={<RepeatIcon />}
            onClick={devices.fetch}
            size="sm"
            isLoading={devices.loading}
          />
        </HStack>
        {devices.loading
          ? Array(3)
              .fill(0)
              .map((_, i) => <Skeleton key={i} h="40px" borderRadius="lg" overflow="hidden" />)
          : devices.data?.map((d, i) => (
              <DeviceListItem key={i} device={d} onDelete={handleDelete} onEdit={handleEdit} />
            ))}
      </Stack>
      <DeviceEditorModal
        item={currentDevice}
        open={isEditorOpen}
        onClose={onEditorClose}
        onComplete={devices.fetch}
        mode={currentMode}
      />
      <DeleteResourceModal
        table="devices"
        id={currentDevice.id}
        open={isDeleteOpen}
        onClose={onDeleteClose}
        onComplete={devices.fetch}
      />
    </>
  )
}

export default VirtualDevices
