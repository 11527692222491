import {AdminOrganizer} from '@/api/models'
import i18n from '@/i18n'

import {UsersOrganizersRoleItem} from '../types'

export const userRoleItems: UsersOrganizersRoleItem[] = [
  {
    label: i18n.t('organizer:roles:admin'),
    value: 'admin',
  },
  {
    label: i18n.t('organizer:roles:basic'),
    value: 'basic',
  },
]

export const emptyOrganizer: AdminOrganizer = {
  id: '',
  created_at: new Date(),
  updated_at: new Date(),
  name: '',
  description: '',
  website: '',
  public: false,
  logo: undefined,
}
