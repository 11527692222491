import * as React from 'react'

import {Center, Spinner, useDisclosure, useToken} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Brush,
  Bar,
  BarChart,
} from 'recharts'
import {Formatter, type NameType, type ValueType} from 'recharts/types/component/DefaultTooltipContent'

import {OrganizerEventParticipant, ParticipantAssignedClass} from '@/api/models'
import {useSupabaseQuery} from '@/common/hooks'
import {formatMilliseconds, formatTimeOfDay} from '@/utils/string'

import {EventContext} from '../../..'
import {emptyAssignedClass} from '../../editor-modal/classes-manager/constants'
import ClassEditorModal from './class-editor-modal'
import {BrushIndex} from './types'
import {generateTimestamps} from './utils'

const barSize = 30
const barGap = 20

const ParticipantSummaryChart = ({participant}: {participant: OrganizerEventParticipant}) => {
  const {t} = useTranslation()
  const [gray900, cyan600] = useToken('colors', ['gray.900', 'cyan.600'])
  const {event} = React.useContext(EventContext)
  const [domain, setDomain] = React.useState([
    new Date(event.start_time).getTime(),
    new Date(event.end_time).getTime(),
  ])

  // Lap times
  const laps = React.useMemo(() => participant.lap_times, [participant])
  const minLapTime = React.useMemo(() => Math.min(...laps.map((l) => l.lap_time)), [laps])
  const maxLapTime = React.useMemo(() => Math.max(...laps.map((l) => l.lap_time)), [laps])
  const convertedLaps = React.useMemo(
    () =>
      [
        // Recharts brush operates on indexes, so in order to zoom in we need some fake timestamps
        // precision is set to 10 minutes (10 * 60 * 1000)
        ...generateTimestamps(event.start_time, event.end_time, 10 * 60 * 1000).map((t) => ({created_at: t})),
        ...laps.map((c) => {
          return {
            ...c,
            created_at: new Date(c.created_at).getTime(),
          }
        }),
      ].sort((a, b) => a.created_at - b.created_at),
    [laps, event]
  )

  const LapTimesTooltipFormatter = React.useCallback<Formatter<ValueType, NameType>>(
    (v) =>
      Array.isArray(v)
        ? [v.map((val) => formatMilliseconds(+val)), t('participants:lapTime')]
        : [formatMilliseconds(+v), t('participants:lapTime')],
    [t]
  )

  // Brush
  const [brush, setBrush] = React.useState<BrushIndex>()

  React.useEffect(() => {
    setBrush({startIndex: 0, endIndex: convertedLaps.length - 1})
  }, [convertedLaps])

  const handleBrushChange = React.useCallback((newIndex: BrushIndex | React.FormEvent<SVGElement>) => {
    if (!('startIndex' in newIndex)) return
    setBrush(newIndex)
  }, [])

  React.useEffect(() => {
    convertedLaps.length &&
      setDomain([
        convertedLaps[brush?.startIndex ?? 0]?.created_at,
        convertedLaps[brush?.endIndex ?? convertedLaps.length - 1]?.created_at,
      ])
  }, [brush, convertedLaps])

  // Classes
  const [currentClass, setCurrentClass] = React.useState(emptyAssignedClass)
  const {isOpen: isClassEditorOpen, onClose: onClassEditorClose, onOpen: onClassEditorOpen} = useDisclosure()

  // fetching classes instead of using the ones from `participant` prop in order to refetch after editing
  const classes = useSupabaseQuery<ParticipantAssignedClass>({
    fields: '*',
    table: 'event_participant_classes_joined',
    order: 'end_time',
    descending: true,
    filter: React.useCallback(
      (b: PostgrestFilterBuilder<ParticipantAssignedClass>) =>
        b.match({participant_id: participant.id, event_id: event.id}),
      [participant, event]
    ),
  })

  const convertedClasses = React.useMemo(
    () =>
      classes.data
        ?.sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime())
        .map((c) => {
          return {
            ...c,
            // draws the bar from value[0] to value[1]
            value: [new Date(c.start_time).getTime(), new Date(c.end_time).getTime()],
          }
        }),
    [classes]
  )

  const handleClassClick = React.useCallback(
    (data: any) => {
      setCurrentClass(data.payload)
      onClassEditorOpen()
    },
    [onClassEditorOpen]
  )

  const ClassesTooltipFormatter = React.useCallback<Formatter<ValueType, NameType>>(
    (v) =>
      Array.isArray(v)
        ? v.map((val) => formatTimeOfDay(new Date(val))).reverse()
        : formatTimeOfDay(new Date(v)),
    []
  )

  if (classes.loading)
    return (
      <Center h="346px" w="100%">
        <Spinner />
      </Center>
    )

  return (
    <>
      {/* LAPS CHART */}
      {!!laps?.length && (
        <ResponsiveContainer width="100%" height="100%" minHeight="200px">
          <LineChart data={convertedLaps} margin={{top: 5, right: 25, bottom: 0, left: 25}}>
            <Line
              connectNulls={true}
              type="monotone"
              dataKey="lap_time"
              stroke={cyan600}
              strokeOpacity={0}
              isAnimationActive={false}
            />
            <CartesianGrid stroke="#ccc" strokeDasharray="2 5" strokeWidth={0.5} />
            <Brush
              dataKey="created_at"
              height={30}
              stroke={cyan600}
              fill={gray900}
              travellerWidth={12}
              tickFormatter={formatTimeOfDay}
              startIndex={brush?.startIndex}
              endIndex={brush?.endIndex}
              onChange={handleBrushChange}
            />
            <XAxis
              type="number"
              orientation="top"
              domain={domain}
              dataKey="created_at"
              tickFormatter={formatTimeOfDay}
            />
            <YAxis
              domain={[minLapTime, maxLapTime]}
              allowDataOverflow={true}
              padding={{top: 10, bottom: 10}}
              tickFormatter={formatMilliseconds}
            />
            <Tooltip
              labelStyle={{color: 'black'}}
              labelFormatter={formatTimeOfDay}
              formatter={LapTimesTooltipFormatter}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
      {/* CLASSES CHART */}
      {!!classes.data?.length && (
        <>
          <ResponsiveContainer
            width="100%"
            height="100%"
            minHeight={classes.data?.length * (barSize + barGap) + 30}
          >
            <BarChart
              data={convertedClasses}
              layout="vertical"
              margin={{top: 0, right: 25, bottom: 5, left: 25}}
            >
              <CartesianGrid strokeDasharray="2 5" strokeWidth={0.5} />
              <XAxis
                axisLine={true}
                type="number"
                domain={domain}
                allowDataOverflow={true}
                tickFormatter={formatTimeOfDay}
              />
              <YAxis type="category" dataKey="name" padding={{top: 10, bottom: 0}} />
              <Tooltip labelStyle={{color: 'black'}} formatter={ClassesTooltipFormatter} />
              <Bar
                dataKey="value"
                fill={cyan600}
                maxBarSize={barSize}
                radius={5}
                onClick={handleClassClick}
                cursor="pointer"
              />
            </BarChart>
          </ResponsiveContainer>
          <ClassEditorModal
            item={currentClass}
            open={isClassEditorOpen}
            onClose={onClassEditorClose}
            onComplete={classes.fetch}
            participantID={participant.id}
          />
        </>
      )}
    </>
  )
}

export default ParticipantSummaryChart
