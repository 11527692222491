import * as React from 'react'

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {EditorMode} from '@/admin/types'
import {Participant} from '@/api/models'
import {CommonModalProps} from '@/utils/types'

import CarsManager from './cars-manager'
import ParticipantForm from './form'
import TagsManager from './tags-manager'

type Props = CommonModalProps & {
  item: Participant
  mode: EditorMode
  serieID?: string
}

const ParticipantEditorModal = ({open, item, serieID, mode, onComplete, onClose}: Props) => {
  const {t} = useTranslation()

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`participants:form:addParticipant`)
            // t(`participants:form:updateParticipant`)
            t(`participants:form:${mode}Participant`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <ParticipantForm
              item={item}
              serieID={serieID}
              open={open}
              onClose={onClose}
              onComplete={onComplete}
            />
            {item.id && (
              <>
                <CarsManager participantID={item.id} />
                <TagsManager participantID={item.id} />
              </>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ParticipantEditorModal
