import {AdminOrganizer} from '@/api/models'

export const inputToUpdateArgs = (input: AdminOrganizer) => ({
  id: input.id,
  name: input.name,
  description: input.description,
  website: input.website,
  public: input.public,
  logo: input.logo ?? null,
  updated_at: new Date(),
})
