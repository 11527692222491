import * as React from 'react'

import {Flex, Heading, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {FaClock} from 'react-icons/fa'
import {ImUserTie} from 'react-icons/im'
import {useParams} from 'react-router-dom'

import {useAppDispatch, useAppSelector} from '@/store'

import EventLapTimes from './lap-times'
import OrganizerTab from './organizer'
import {fetchRankings, selectRankings, selectRankingsLoading, selectRankingsOrganizer} from './state'

const SeasonRankings = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const rankings = useAppSelector(selectRankings)
  const organizer = useAppSelector(selectRankingsOrganizer)
  const loading = useAppSelector(selectRankingsLoading)

  const {id} = useParams()
  React.useEffect(() => {
    id && dispatch(fetchRankings(id))
  }, [id, dispatch])

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    )
  }

  if (!rankings) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Heading>{t('series:seasonRankingsNotFound')}</Heading>
      </Flex>
    )
  }

  return (
    <Stack>
      <Heading mx={8} my={4} fontSize="2xl">
        {`${rankings.name} ${t('series:seasonRankings')}`}
      </Heading>
      <Tabs w="100%" size="md" colorScheme="red">
        <TabList>
          <Tab ml={8}>
            <FaClock />
            <Text ml={2}>{t('series:seasonRankings')}</Text>
          </Tab>
          <Tab ml={8}>
            <ImUserTie />
            <Text ml={2}>{t('common:entities:organizer')}</Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            <EventLapTimes />
          </TabPanel>
          <TabPanel>
            <OrganizerTab organizer={organizer} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}

export default SeasonRankings
