import {Track} from '@/api/models'

export const inputToUpsertArgs = (input: Track) => {
  if (input.location.coordinates.length !== 2) {
    throw new Error('Invalid location coordinates')
  }
  return {
    track_id: input.id,
    name: input.name,
    description: input.description,
    website: input.website,
    public: input.public,
    picture: input.picture,
    lon: input.location.coordinates[0],
    lat: input.location.coordinates[1],
  }
}
