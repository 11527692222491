import * as React from 'react'

import {Box, Heading, useDisclosure} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {Car} from '@/api/models'
import {DeleteResourceModal} from '@/common/components'
import DataTable from '@/common/data-table'
import {Column} from '@/common/data-table/types'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import {FilterField} from '@/common/filter-builder/types'
import {useSupabaseQuery} from '@/common/hooks'

import {EditorMode} from '../types'
import {emptyCar} from './constants'
import CarEditorModal from './editor-modal'

const defaultSelectedColumns = ['name', 'public']

const Cars = () => {
  const {t} = useTranslation()
  const tableState = useTableState({sortBy: 'created_at'})
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [currentCar, setCurrentCar] = React.useState<Car>(emptyCar)
  const [currentMode, setCurrentMode] = React.useState<EditorMode>('add')
  const {loading, data, fetch, rows} = useSupabaseQuery<Car>({
    fields: '*',
    table: 'cars',
    order: tableState.sortBy.column as keyof Car,
    itemsPerPage: tableState.rowsPerPage,
    pageNumber: tableState.page,
    descending: tableState.descending,
    filter: tableState.filter,
  })

  const columns: Column<Car>[] = React.useMemo(
    () => [
      {
        Header: t('common:fields:id').toString(),
        id: 'id',
        renderAs: 'code',
        sortable: false,
      },
      {
        Header: t('common:fields:name').toString(),
        id: 'name',
      },
      {
        Header: t('common:fields:public').toString(),
        id: 'public',
        renderAs: 'boolean',
      },
      {
        Header: t('common:fields:createdAt').toString(),
        id: 'created_at',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:updatedAt').toString(),
        id: 'updated_at',
        renderAs: 'timestamp',
      },
    ],
    [t]
  )

  // TODO: Add participant field ( already did in a different branch )
  const filterFields: FilterField[] = React.useMemo(
    () => [
      {
        label: t('common:fields:name'),
        name: 'name',
        variant: 'text',
      },
      {
        label: t('common:fields:public'),
        name: 'public',
        variant: 'boolean',
      },
      {
        label: t('common:fields:createdAt'),
        name: 'created_at',
        variant: 'datetime',
      },
      {
        label: t('common:fields:updatedAt'),
        name: 'updated_at',
        variant: 'datetime',
      },
    ],
    [t]
  )

  const handleAdd = React.useCallback(() => {
    setCurrentCar(emptyCar)
    setCurrentMode('add')
    onEditorOpen()
  }, [onEditorOpen])

  const handleEdit = React.useCallback(
    (item: Car) => {
      setCurrentCar(item)
      setCurrentMode('update')
      onEditorOpen()
    },
    [onEditorOpen]
  )

  const handleDelete = React.useCallback(
    (item: Car) => {
      setCurrentCar(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  const actions = React.useMemo(
    () => [editAction(handleEdit), deleteAction(handleDelete)],
    [handleEdit, handleDelete]
  )

  return (
    <Box>
      <Heading fontSize="xl" mb={4}>
        {t('admin:menu:cars')}
      </Heading>
      <DataTable<Car>
        columns={columns}
        defaultSelectedColumns={defaultSelectedColumns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        loading={loading}
        onRefresh={fetch}
        onAdd={handleAdd}
        filterFields={filterFields}
      />
      <CarEditorModal
        item={currentCar}
        open={isEditorOpen}
        onClose={onEditorClose}
        onComplete={fetch}
        mode={currentMode}
      />
      <DeleteResourceModal
        table="cars"
        id={currentCar.id}
        open={isDeleteOpen}
        onClose={onDeleteClose}
        onComplete={fetch}
      />
    </Box>
  )
}

export default Cars
