import {AdminOrganizer, AdminOrganizerUser, OrganizerUserRole} from '@/api/models'

export const inputToUpsertArgs = (input: AdminOrganizer, users: AdminOrganizerUser[]) => ({
  organizer_id: input.id,
  roles_by_emails: users,
  name: input.name,
  description: input.description,
  website: input.website,
  public: input.public,
  logo: input.logo,
})

export const isOrganizerUserRole = (value: string): value is OrganizerUserRole => {
  const roles: OrganizerUserRole[] = ['admin', 'basic']
  return roles.includes(value as OrganizerUserRole)
}
