import {ParticipantTag} from '@/api/models'

export const inputToUpsertArgs = (input: ParticipantTag, participantID: string, eventID?: string) => ({
  participant_tag_id: input.id || undefined,
  epc: input.epc,
  event_id: input.event_id || eventID,
  participant_id: participantID,
  start_time: input.start_time,
  end_time: input.end_time,
  description: input.description || undefined,
})
