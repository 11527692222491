import * as React from 'react'

import {chakra, Box, Heading, Stack, useColorModeValue, type StackProps, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {NavLink} from 'react-router-dom'

const ChakraRouterLink = chakra(NavLink)

type MenuProps = {
  items: MenuItem[]
} & StackProps

type MenuItem =
  | {
      label: string
      link: string
      end: boolean
    }
  | {
      label: string
      children: MenuItem[]
    }

const Menu = ({items, ...rest}: MenuProps) => {
  const hoverColor = useColorModeValue('gray.200', 'gray.700')
  const hoverStyles = React.useMemo(
    () => ({
      bg: hoverColor,
      textDecoration: 'none',
    }),
    [hoverColor]
  )
  const ActiveLinkStyle = React.useCallback(
    ({isActive}: {isActive: boolean}) =>
      isActive
        ? {
            fontWeight: 600,
          }
        : {},
    []
  )

  return (
    <Stack direction="column" align="start" {...rest}>
      {items.map((item, i) => {
        if ('children' in item) {
          return (
            <Stack direction="column" key={i}>
              <Text fontSize="sm" fontWeight={600}>
                {item.label}
              </Text>
              <Box pl={1}>
                <Menu items={item.children} />
              </Box>
            </Stack>
          )
        }
        return (
          <Box key={i}>
            <ChakraRouterLink
              py={1}
              px={2}
              rounded="md"
              _hover={hoverStyles}
              style={ActiveLinkStyle}
              to={item.link}
              end={item.end}
            >
              {item.label}
            </ChakraRouterLink>
          </Box>
        )
      })}
    </Stack>
  )
}

const Sidebar = () => {
  const {t} = useTranslation()

  const menuItems = React.useMemo(
    (): MenuItem[] => [
      {
        children: [
          {
            label: t('admin:menu:dashboard'),
            link: '/admin',
            end: true,
          },
          {
            label: t('admin:menu:organizers'),
            link: '/admin/organizers',
            end: false,
          },
          {
            label: t('admin:menu:tracks'),
            link: '/admin/tracks',
            end: false,
          },
          {
            label: t('admin:menu:trackLayouts'),
            link: '/admin/track-layouts',
            end: false,
          },
          {
            label: t('admin:menu:users'),
            link: '/admin/users',
            end: false,
          },
        ],
        label: 'System',
      },
      {
        children: [
          {
            label: t('admin:menu:series'),
            link: '/admin/series',
            end: false,
          },
          {
            label: t('admin:menu:events'),
            link: '/admin/events',
            end: false,
          },
          {
            label: t('admin:menu:serieClasses'),
            link: '/admin/serie-classes',
            end: false,
          },
          {
            label: t('admin:menu:participants'),
            link: '/admin/participants',
            end: false,
          },
          {
            label: t('admin:menu:participantTags'),
            link: '/admin/participant-tags',
            end: false,
          },
          {
            label: t('admin:menu:cars'),
            link: '/admin/cars',
            end: false,
          },
        ],
        label: t('admin:menu:eventsManagement'),
      },
      {
        children: [
          {
            label: t('admin:menu:devices'),
            link: '/admin/devices',
            end: false,
          },
          {
            label: t('admin:menu:rentedDevices'),
            link: '/admin/rented-devices',
            end: false,
          },
          {
            label: t('admin:menu:deviceAssignments'),
            link: '/admin/device-assignments',
            end: false,
          },
          {
            label: t('admin:menu:bridgeSubscriptions'),
            link: '/admin/bridge-subscriptions',
            end: false,
          },
          {
            label: t('admin:menu:epcTags'),
            link: '/admin/epc-tags',
            end: false,
          },
        ],
        label: t('admin:menu:dataIngest'),
      },
    ],
    [t]
  )

  return (
    <>
      <Heading fontSize="xl" mb={4}>
        {t('admin:menu:adminPanel')}
      </Heading>
      <Menu items={menuItems} />
    </>
  )
}

export default Sidebar
