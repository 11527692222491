import * as React from 'react'

import {Box, Heading, Tag, useDisclosure} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {BridgeSubscription} from '@/api/models'
import {DeleteResourceModal} from '@/common/components'
import DataTable from '@/common/data-table'
import {Column} from '@/common/data-table/types'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import {FilterField} from '@/common/filter-builder/types'
import {useSupabaseQuery} from '@/common/hooks'

import {EditorMode} from '../types'
import {emptyBridgeSubscription, statusColor, subsciptionStatuses} from './constants'
import BridgeSubscriptionEditorModal from './editor-modal'

const defaultSelectedColumns = [
  'event_name',
  'name',
  'source',
  'status',
  'processed_laps',
  'generated_readouts',
  'processed_at',
]

const BridgeSubscriptions = () => {
  const {t} = useTranslation()
  const tableState = useTableState({sortBy: 'created_at'})
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [currentBridgeSubscription, setCurrentBridgeSubscription] =
    React.useState<BridgeSubscription>(emptyBridgeSubscription)
  const [currentMode, setCurrentMode] = React.useState<EditorMode>('add')
  const {loading, data, fetch, rows} = useSupabaseQuery<BridgeSubscription>({
    fields: '*',
    table: 'admin_bridge_subscriptions',
    order: tableState.sortBy.column as keyof BridgeSubscription,
    itemsPerPage: tableState.rowsPerPage,
    pageNumber: tableState.page,
    descending: tableState.descending,
    filter: tableState.filter,
  })

  const columns: Column<BridgeSubscription>[] = React.useMemo(
    () => [
      {
        Header: t('common:fields:id').toString(),
        id: 'id',
        renderAs: 'code',
        sortable: false,
      },
      {
        Header: t('common:entities:event').toString(),
        id: 'event_name',
      },
      {
        Header: t('common:fields:name').toString(),
        id: 'name',
      },
      {
        Header: t('common:entities:device').toString(),
        id: 'device_name',
      },
      {
        Header: t('admin:bridgeSubscriptions:fields:source').toString(),
        id: 'source',
      },
      {
        Header: t('admin:bridgeSubscriptions:fields:status').toString(),
        id: 'status',
        renderer: ({status}) => (
          <Tag borderRadius="full" colorScheme={statusColor[status]}>
            {status}
          </Tag>
        ),
      },
      {
        Header: t('admin:bridgeSubscriptions:fields:processedLaps').toString(),
        id: 'processed_laps',
      },
      {
        Header: t('admin:bridgeSubscriptions:fields:generatedReadouts').toString(),
        id: 'generated_readouts',
      },
      {
        Header: t('admin:bridgeSubscriptions:fields:processedAt').toString(),
        id: 'processed_at',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:createdAt').toString(),
        id: 'created_at',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:updatedAt').toString(),
        id: 'updated_at',
        renderAs: 'timestamp',
      },
    ],
    [t]
  )

  const filterFields: FilterField[] = React.useMemo(
    () => [
      {
        label: t('common:entities:event'),
        name: 'event_name',
        variant: 'text',
      },
      {
        label: t('common:fields:name'),
        name: 'name',
        variant: 'text',
      },
      {
        label: t('common:entities:device'),
        name: 'device_name',
        variant: 'text',
      },
      {
        label: t('admin:bridgeSubscriptions:fields:source'),
        name: 'source',
        variant: 'text',
      },
      {
        label: t('admin:bridgeSubscriptions:fields:status'),
        name: 'status',
        variant: 'select',
        values: subsciptionStatuses,
      },
      {
        label: t('admin:bridgeSubscriptions:fields:processedLaps'),
        name: 'processed_laps',
        variant: 'number',
      },
      {
        label: t('admin:bridgeSubscriptions:fields:generatedReadouts'),
        name: 'generated_readouts',
        variant: 'number',
      },
      {
        label: t('common:fields:createdAt'),
        name: 'created_at',
        variant: 'datetime',
      },
      {
        label: t('common:fields:updatedAt'),
        name: 'updated_at',
        variant: 'datetime',
      },
    ],
    [t]
  )

  const handleAdd = React.useCallback(() => {
    setCurrentBridgeSubscription(emptyBridgeSubscription)
    setCurrentMode('add')
    onEditorOpen()
  }, [onEditorOpen])

  const handleEdit = React.useCallback(
    (item: BridgeSubscription) => {
      setCurrentBridgeSubscription(item)
      setCurrentMode('update')
      onEditorOpen()
    },
    [onEditorOpen]
  )

  const handleDelete = React.useCallback(
    (item: BridgeSubscription) => {
      setCurrentBridgeSubscription(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  const actions = React.useMemo(
    () => [editAction(handleEdit), deleteAction(handleDelete)],
    [handleEdit, handleDelete]
  )

  return (
    <Box>
      <Heading fontSize="xl" mb={4}>
        {t('admin:menu:bridgeSubscriptions')}
      </Heading>
      <DataTable<BridgeSubscription>
        columns={columns}
        defaultSelectedColumns={defaultSelectedColumns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        loading={loading}
        onRefresh={fetch}
        onAdd={handleAdd}
        filterFields={filterFields}
      />
      <BridgeSubscriptionEditorModal
        item={currentBridgeSubscription}
        open={isEditorOpen}
        onClose={onEditorClose}
        onComplete={fetch}
        mode={currentMode}
      />
      <DeleteResourceModal
        table="bridge_subscriptions"
        id={currentBridgeSubscription.id}
        open={isDeleteOpen}
        onClose={onDeleteClose}
        onComplete={fetch}
      />
    </Box>
  )
}

export default BridgeSubscriptions
