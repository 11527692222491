import * as React from 'react'

import {chakra, useColorModeValue} from '@chakra-ui/react'
import type {To} from 'react-router'
import {NavLink as RouterLink} from 'react-router-dom'

type Props = {
  children: React.ReactNode
  to: To
}

const ChakraRouterLink = chakra(RouterLink)

const NavLink = ({children, to}: Props) => (
  <ChakraRouterLink
    px={2}
    py={1}
    rounded="md"
    _hover={{
      bg: useColorModeValue('gray.200', 'gray.700'),
      textDecoration: 'none',
    }}
    to={to}
  >
    {children}
  </ChakraRouterLink>
)

export default NavLink
