import {Participant} from '@/api/models'

export const emptyParticipant: Participant = {
  id: '',
  created_at: new Date(),
  updated_at: new Date(),
  name: '',
  first_name: '',
  number: 0,
  serie_id: '',
  serie_name: '',
  user_id: '',
  user_email: '',
}
