import {Location} from '@/api/models'

// postGIS uses lon/lat, but Leaflet uses lat/lon
export const swapLngLat = (coordinates: [number, number]): [number, number] => [
  coordinates[1] || 0,
  coordinates[0] || 0,
]

export const isLongitudeValid = (lon: number) => lon >= -180 && lon <= 180

export const isLatitudeValid = (lat: number) => lat >= -90 && lat <= 90

export const isLocationValid = (l: Location) =>
  l.coordinates.length === 2 && isLongitudeValid(l.coordinates[0]) && isLatitudeValid(l.coordinates[1])
