import * as React from 'react'

import {
  Modal,
  Stack,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Select,
  useToast,
  Button,
  ModalFooter,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {userRoleItems} from '@/admin/users/constants'
import {supabase} from '@/api'
import {User} from '@/api/models'
import {useLoadingState} from '@/common/hooks'
import {CommonModalProps} from '@/utils/types'

const UserRoleEditorModal = ({onClose, open, item, onComplete}: CommonModalProps & {item: User}) => {
  const {t} = useTranslation()
  const toast = useToast()
  const [role, setRole] = React.useState('')

  React.useEffect(() => {
    if (!item.role) {
      return
    }
    setRole(item.role)
  }, [item])

  const handleRoleChange = React.useCallback(
    ({target: {value}}: React.ChangeEvent<HTMLSelectElement>) => setRole(value),
    [setRole]
  )

  const handleUpdate = React.useCallback(async () => {
    if (!item) {
      return
    }
    try {
      const {error} = await supabase.from('users').update({role}).eq('id', item.id)
      if (error) {
        throw error
      }
      toast({isClosable: true, status: 'success', title: t('common:dataTable:updateSuccess')})
      onClose()
      onComplete && onComplete()
    } catch (e) {
      toast({
        description: (e as Error).toString(),
        isClosable: true,
        title: t('common:dataTable:updateFail'),
        variant: 'error',
      })
    }
  }, [item, toast, onClose, t, role, onComplete])
  const {loading, handleSubmit} = useLoadingState(handleUpdate)

  return (
    <Modal isOpen={open} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('user:editUser')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack align="center" spacing={8}>
            <HStack width="100%" justify="space-evenly">
              <Text>{item.email}</Text>
              <Stack width="30%">
                <FormControl>
                  <Select value={role} onChange={handleRoleChange}>
                    {userRoleItems.map((role, i) => (
                      <option value={role.name} key={i}>
                        {role.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </HStack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={loading} onClick={handleSubmit}>
            {t('common:actions:save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UserRoleEditorModal
