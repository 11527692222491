import * as React from 'react'

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Skeleton,
  Stack,
  Tab,
  TabList,
  Tabs,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {useParams, Link} from 'react-router-dom'

import EventClassParticipants from './class-participants'
import EventParticipants from './event-participants'
import useEventsClasses from './use-events-classes'

// TODO: This sucks
const EventClassAssignments = () => {
  const {t} = useTranslation()

  const {eventID} = useParams()
  const [currentClass, setCurrentClass] = React.useState('all')
  const {event, classes, loading} = useEventsClasses(eventID ?? '')

  const handleClassChange = React.useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setCurrentClass(e.currentTarget.name)
  }, [])

  return (
    <Stack>
      <Breadcrumb fontSize="xl" fontWeight="bold" mb={4}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/admin/events">
            {t('admin:menu:events')}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage={true}>
          <Skeleton isLoaded={!loading} size="20pc">
            <BreadcrumbLink>
              {t('admin:eventClassAssignments:heading', {eventName: event.name})}
            </BreadcrumbLink>
          </Skeleton>
        </BreadcrumbItem>
      </Breadcrumb>
      {eventID && !loading && (
        <Tabs size="md" variant="enclosed">
          <TabList overflow="scroll hidden">
            <Tab name="all" onClick={handleClassChange}>
              {t('admin:eventClassAssignments:allParticipants')}
            </Tab>
            {classes.map((c) => (
              <Tab key={c.id} name={c.id} onClick={handleClassChange}>
                {c.name}
              </Tab>
            ))}
          </TabList>
          {currentClass === 'all' ? (
            <EventParticipants eventID={eventID} />
          ) : (
            <EventClassParticipants eventID={eventID} classID={currentClass} />
          )}
        </Tabs>
      )}
    </Stack>
  )
}

export default EventClassAssignments
