import {AdminSerie} from '@/api/models'

export const inputToUpsertArgs = (input: AdminSerie) => ({
  serie_id: input.id,
  name: input.name,
  organizer_id: input.organizer_id,
  public: input.public,
  logo: input.logo,
  start_date: input.start_date,
  end_date: input.end_date,
})
