import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Input,
  Textarea,
  Text,
  Checkbox,
  useToast,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {ImageType} from 'react-images-uploading'
import {v4 as uuidv4} from 'uuid'

import {EditorMode} from '@/admin/types'
import {AdminOrganizer, RPC, AdminOrganizerUser} from '@/api/models'
import {uploadFile} from '@/api/utils'
import {ImageUpload} from '@/common/components'
import {useLoadingState, useSupabaseRPC} from '@/common/hooks'
import {SUPABASE_ORGANIZERS_BUCKET} from '@/constants'
import {CommonModalProps} from '@/utils/types'

import {emptyOrganizer} from '../constants'
import UserManager from './user-manager'
import {inputToUpsertArgs} from './utils'

const OrganizerEditorModal = ({
  item,
  mode,
  open,
  onComplete,
  onClose,
}: CommonModalProps & {item: AdminOrganizer; mode: EditorMode}) => {
  const {t} = useTranslation()
  const toast = useToast()
  const [input, setInput] = React.useState(emptyOrganizer)
  const [users, setUsers] = React.useState<AdminOrganizerUser[]>([])
  const [image, setImage] = React.useState<ImageType>()

  const {handleRPC} = useSupabaseRPC({
    fnName: RPC.UpsertOrganizer,
    params: {},
    mode,
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
    item.logo_url ? setImage({dataURL: item.logo_url}) : setImage(undefined)
  }, [item, open])

  const _handleSubmit = React.useCallback(async () => {
    try {
      const id = input.id || uuidv4()
      const filepath = image?.file
        ? await uploadFile(SUPABASE_ORGANIZERS_BUCKET, id, image?.file)
        : image?.dataURL
        ? input.logo
        : undefined
      await handleRPC(inputToUpsertArgs({...input, id, logo: filepath}, users))
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        status: 'error',
        title: t(`common:dataTable:${mode}Fail`),
      })
    }
  }, [handleRPC, image, input, users, mode, toast, t])
  const {handleSubmit, loading} = useLoadingState(_handleSubmit)

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handlePublicChange = React.useCallback(
    ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) =>
      setInput((input) => ({...input, public: checked})),
    []
  )

  const isSubmitDisabled = React.useMemo(() => !input.name || !input.website || !input.description, [input])

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`organizer:form:addOrganizer`)
            // t(`organizer:form:updateOrganizer`)
            t(`organizer:form:${mode}Organizer`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Stack spacing={4} align="center" width="100%">
              <FormControl id="name" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:name')}</FormLabel>
                <Input value={input.name ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl id="website" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:website')}</FormLabel>
                <Input value={input.website ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl id="description" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:description')}</FormLabel>
                <Textarea resize="vertical" value={input.description ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl id="public" isDisabled={loading}>
                <Checkbox isChecked={input.public ?? false} onChange={handlePublicChange}>
                  {t('common:fields:public')}
                </Checkbox>
              </FormControl>
              <Stack w="100%">
                <Text>Logo</Text>
                <ImageUpload onChange={setImage} value={image} editing={true} />
              </Stack>
              <UserManager id={item.id} users={users} setUsers={setUsers} loading={loading} />
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleSubmit} isDisabled={isSubmitDisabled}>
              {t('common:actions:save')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default OrganizerEditorModal
