import * as React from 'react'

import {Route, Routes, Navigate} from 'react-router-dom'

import Admin from '@/admin'
import AdminBridgeSubscriptions from '@/admin/bridge-subscriptions'
import AdminCars from '@/admin/cars'
import AdminDashboard from '@/admin/dashboard'
import AdminDeviceAssignments from '@/admin/device-assignments'
import AdminDevices from '@/admin/devices'
import AdminEPCTags from '@/admin/epc-tags'
import AdminEventClassAssignments from '@/admin/event-class-assignments'
import AdminEvents from '@/admin/events'
import AdminOrganizers from '@/admin/organizers'
import AdminParticipantTags from '@/admin/participant-tags'
import AdminParticipants from '@/admin/participants'
import AdminRentedDevices from '@/admin/rented-devices'
import AdminSerieClasses from '@/admin/serie-classes'
import AdminSeries from '@/admin/series'
import AdminTrackLayouts from '@/admin/track-layouts'
import AdminTracks from '@/admin/tracks'
import AdminUsers from '@/admin/users'
import SignIn from '@/auth/signin'
import SignUp from '@/auth/signup'
import Events from '@/events'
import EventPage from '@/events/event-page'
import Home from '@/home'
import StandardLayout from '@/layout'
import OrganizerRouter from '@/organizer/router'
import Series from '@/series'
import SeasonRankings from '@/series/season-rankings'
import UserProfile from '@/user/profile'

import RequireAuth from './require-auth'
import RequireLoggedOut from './require-logged-out'

const Router = () => {
  return (
    <Routes>
      <Route path="organizer/:id" element={<RequireAuth route={(params) => `organizer/${params.id}`} />}>
        {OrganizerRouter()}
      </Route>

      <Route path="/" element={<StandardLayout />}>
        <Route index={true} element={<Home />} />
        <Route path="series" element={<Series />} />
        <Route path="season-rankings/:id" element={<SeasonRankings />} />

        <Route path="events" element={<Events />} />
        <Route path="events/:id" element={<EventPage />} />

        {/* Routes below require that no user is logged in */}
        <Route element={<RequireLoggedOut />}>
          <Route path="auth">
            <Route path="signup" element={<SignUp />} />
            <Route path="signin" element={<SignIn />} />
          </Route>
        </Route>

        {/* Routes below require a user to be logged in */}
        <Route element={<RequireAuth route="LoggedInRoute" />}>
          <Route path="user">
            <Route path="profile" element={<UserProfile />} />
          </Route>
        </Route>

        {/* Routes below require a user to be an admin */}
        <Route element={<RequireAuth route="AdminPanel" />}>
          <Route path="admin" element={<Admin />}>
            <Route index={true} element={<AdminDashboard />} />
            <Route path="users" element={<AdminUsers />} />
            <Route path="organizers" element={<AdminOrganizers />} />
            <Route path="epc-tags" element={<AdminEPCTags />} />
            <Route path="series" element={<AdminSeries />} />
            <Route path="serie-classes" element={<AdminSerieClasses />} />
            <Route path="events" element={<AdminEvents />} />
            <Route path="events/:eventID/class-assignments" element={<AdminEventClassAssignments />} />
            <Route path="tracks" element={<AdminTracks />} />
            <Route path="participants" element={<AdminParticipants />} />
            <Route path="participant-tags" element={<AdminParticipantTags />} />
            <Route path="cars" element={<AdminCars />} />
            <Route path="track-layouts" element={<AdminTrackLayouts />} />
            <Route path="device-assignments" element={<AdminDeviceAssignments />} />
            <Route path="devices" element={<AdminDevices />} />
            <Route path="rented-devices" element={<AdminRentedDevices />} />
            <Route path="bridge-subscriptions" element={<AdminBridgeSubscriptions />} />
            <Route path="*" element={<Navigate to={{pathname: '/admin'}} />} />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<Navigate to={{pathname: '/'}} />} />
    </Routes>
  )
}

export default Router
