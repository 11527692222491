import * as React from 'react'

import {
  Button,
  Container,
  Flex,
  FormControl,
  Input,
  Stack,
  useToast,
  Text,
  Divider,
  FormErrorMessage,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {Link as RouterLink} from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'

import {supabase} from '@/api'
import SocialSignIn from '@/auth/social-signin'
import {PasswordInput} from '@/common/components'
import {useLoadingState} from '@/common/hooks'

import {SignUpInput} from './types'
import {isInputValid, isPasswordValid, emptySignUpInput} from './utils'

const SignUp = () => {
  const {t} = useTranslation()
  const toast = useToast()
  const [input, setInput] = React.useState<SignUpInput>(emptySignUpInput)

  const isSubmitDisabled = React.useMemo(() => !isInputValid(input), [input])

  const _handleSubmit = React.useCallback(async () => {
    if (!isInputValid(input)) {
      return
    }
    try {
      const {error} = await supabase.auth.signUp({
        email: input.email,
        password: input.password,
      })
      if (error) throw error

      toast({isClosable: true, status: 'success', title: t('auth:signin:succeeded')})
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        status: 'error',
        title: t('auth:signup:errors:failed'),
      })
    }
  }, [input, t, toast])
  const {handleSubmit, loading} = useLoadingState(_handleSubmit)

  const handleInputChange = React.useCallback(
    ({target: {name, value}}: React.ChangeEvent<HTMLInputElement>) => {
      setInput((input) => ({...input, [name]: value}))
    },
    []
  )

  return (
    <Container maxW="container.xl" mb={8} px={8}>
      <Stack align="center" spacing={4} m={3}>
        <Text>{t('auth:signup:signUp')}</Text>
        <Stack spacing={4} w="400px">
          <FormControl isRequired={true} isInvalid={!!input.email && !isEmail(input.email)}>
            <Input
              type="email"
              name="email"
              placeholder={t('auth:signup:email')}
              variant="filled"
              value={input.email}
              onChange={handleInputChange}
              isDisabled={loading}
            />
            <FormErrorMessage>{t('auth:signup:errors:invalidEmail')}</FormErrorMessage>
          </FormControl>
          <PasswordInput
            inputProps={{
              name: 'password',
              variant: 'filled',
              value: input.password ?? '',
              onChange: handleInputChange,
              isDisabled: loading,
              isRequired: true,
              placeholder: t('auth:signup:password'),
              isInvalid: !!input.password && !isPasswordValid(input.password),
            }}
            errorMsg={t('auth:signup:errors:passwordTooShort')}
          />
          <PasswordInput
            inputProps={{
              name: 'repeatPassword',
              variant: 'filled',
              value: input.repeatPassword ?? '',
              onChange: handleInputChange,
              isDisabled: loading,
              isRequired: true,
              placeholder: t('auth:signup:repeatPassword'),
              isInvalid: !!input.repeatPassword && input.password !== input.repeatPassword,
            }}
            errorMsg={t('auth:signup:errors:passwordsDontMatch')}
          />
          <Button
            bg="blue.400"
            color="white"
            _hover={{
              bg: 'blue.500',
            }}
            size="sm"
            isLoading={loading}
            isDisabled={isSubmitDisabled}
            onClick={handleSubmit}
          >
            {t('auth:signup:signUp')}
          </Button>
          <Flex direction="row" alignItems="center">
            <Divider flex={1} />
            <Text fontSize="xs" mx={2} color="gray.200">
              {t('auth:signup:alreadySignedUp')}
            </Text>
            <Divider flex={1} />
          </Flex>
          <Button
            colorScheme="gray"
            _hover={{
              bg: 'blue.500',
            }}
            size="sm"
            as={RouterLink}
            to="/auth/signin"
            isDisabled={loading}
          >
            {t('auth:signup:signIn')}
          </Button>
          <SocialSignIn isDisabled={loading} />
        </Stack>
      </Stack>
    </Container>
  )
}

export default SignUp
