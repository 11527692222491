import * as React from 'react'

import {chakra, Image, Heading, Stack, useColorModeValue, HStack, Tooltip} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {NavLink} from 'react-router-dom'

import {selectOrganizer} from '@/organizer/state'
import {useAppSelector} from '@/store'

const ChakraRouterLink = chakra(NavLink)

const Sidebar = ({id}: {id: string}) => {
  const {t} = useTranslation()
  const organizer = useAppSelector(selectOrganizer)

  const menuContents = React.useMemo(
    () => [
      {
        label: t('organizer:menu:dashboard'),
        link: `/organizer/${id}`,
        end: true,
      },
      {
        label: t('organizer:menu:members'),
        link: `/organizer/${id}/members`,
        end: false,
      },
      {
        label: t('organizer:menu:series'),
        link: `/organizer/${id}/series`,
        end: false,
      },
    ],
    [t, id]
  )
  const hoverColor = useColorModeValue('gray.200', 'gray.700')
  const hoverStyles = React.useMemo(
    () => ({
      bg: hoverColor,
      textDecoration: 'none',
    }),
    [hoverColor]
  )

  const ActiveLinkStyle = React.useCallback(
    ({isActive}: {isActive: boolean}) =>
      isActive
        ? {
            fontWeight: 600,
          }
        : {},
    []
  )

  return (
    <Stack
      p={4}
      bgColor={useColorModeValue('gray.400', 'gray.900')}
      h="100%"
      flex="1"
      borderRadius="xl"
      boxShadow="lg"
    >
      <Tooltip label={t('organizer:menu:viewDetails')}>
        <Stack align="center" spacing={4} as={NavLink} to={`/organizer/${id}/details`}>
          {organizer?.logo_url && <Image src={organizer?.logo_url} />}
          <HStack>
            <Heading fontSize="xl">{organizer?.name}</Heading>
          </HStack>
        </Stack>
      </Tooltip>
      <Stack spacing={0}>
        {menuContents.map((item, i) => (
          <ChakraRouterLink
            key={i}
            p={4}
            rounded="md"
            _hover={hoverStyles}
            style={ActiveLinkStyle}
            to={item.link}
            end={item.end}
          >
            {item.label}
          </ChakraRouterLink>
        ))}
      </Stack>
    </Stack>
  )
}

export default Sidebar
