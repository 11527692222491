import * as React from 'react'

import {Container, Skeleton, Stack} from '@chakra-ui/react'

import {SerieWithEvents} from '@/api/models'
import {useSupabaseQuery} from '@/common/hooks'

import SerieTile from './serie-tile'

const Series = () => {
  const {data, loading} = useSupabaseQuery<SerieWithEvents>({
    fields: '*',
    table: 'public_series',
  })

  return (
    <Container maxW="container.xl" px={8}>
      <Stack spacing={4}>
        {loading
          ? Array(5)
              .fill(0)
              .map((_, i) => <Skeleton key={i} h="66px" borderRadius="lg" overflow="hidden" />)
          : data?.filter((s) => !!s.events.length).map((s) => <SerieTile key={s.id} serie={s} />)}
      </Stack>
    </Container>
  )
}

export default Series
