import * as React from 'react'

import {CloseIcon} from '@chakra-ui/icons'
import {Button, IconButton} from '@chakra-ui/react'
import {type DragHandleProps} from 'react-querybuilder'

// Only used to hide the drag handle
const DragHandle = React.forwardRef<HTMLSpanElement, DragHandleProps>((props, ref) => {
  return <span ref={ref} />
})
DragHandle.displayName = 'DragHandle'

export default DragHandle
