import {PostgrestFilterBuilder} from '@supabase/postgrest-js'

export enum RPC {
  UpsertOrganizer = 'upsert_organizer',
  UpsertOrganizersUsers = 'upsert_organizers_users',
  UpsertTrack = 'upsert_track',
  UpsertTrackLayout = 'upsert_track_layout',
  UpsertSerie = 'upsert_serie',
  UpsertEvent = 'upsert_event',
  UpsertEventParticipant = 'upsert_event_participant',
  UpsertEventParticipantClass = 'upsert_event_participant_class',
  UpsertParticipant = 'upsert_participant',
  UpsertParticipantTag = 'upsert_participant_tag',
  UpsertParticipantTagByNumber = 'upsert_participant_tag_by_number',
  UpsertSerieClass = 'upsert_serie_class',
  UpsertEPCTag = 'upsert_epc_tag',
  UpsertCar = 'upsert_car',
  UpsertDevice = 'upsert_device',
  UpsertDeviceAssignment = 'upsert_device_assignment',
  UpsertRentedDevice = 'upsert_rented_device',
  UpsertBridgeSubscription = 'upsert_bridge_subscription',
  MergeCars = 'merge_cars',
}

export type FilterBuilderFn<T = any> = (builder: PostgrestFilterBuilder<T>) => PostgrestFilterBuilder<T>

export type UserRole = 'admin' | 'user'

export type User = {
  id: string
  email: string
  avatar_url: string
  role: UserRole
  assigned_organizers: AssignedOrganizer[]
}

export type SelectedIdentity =
  | {
      type: 'user'
      id: string
      name?: string
      image_url?: string
      role: UserRole
    }
  | {
      type: 'organizer'
      id: string
      name?: string
      image_url?: string
      role: OrganizerUserRole
    }

// temporarily used for homepage event cards
export type OldEvent = {
  id: string
  track_id: string
  track_name?: string
  track_icon?: string
  track_length?: number
  series_id: string
  series_name?: string
  series_icon?: string
  name: string
  started_at: string
  thumbnail: string
}

export type Event = {
  id: string
  created_at: Date
  updated_at: Date
  name: string
  description: string
  start_time: Date
  end_time: Date
  picture?: string
  picture_url?: string
  serie?: Partial<Serie>
  track_layout?: Partial<TrackLayout>
}

export type AdminEvent = Event & {
  public: boolean
  layout_id: string
  serie_id: string
  max_lap_time: number
  min_lap_time: number
}

export type Organizer = {
  name: string
  description: string
  website: string
  logo?: string
  logo_url?: string
}

export type AdminOrganizer = Organizer & {
  id: string
  created_at: Date
  updated_at: Date
  public: boolean
}

export type OrganizerUserRole = 'admin' | 'basic'

export type AdminOrganizerUser = {
  email: string
  role: OrganizerUserRole
}

export type AssignedOrganizer = {
  id: string
  name: string
  logo_url: string
  role: OrganizerUserRole
}

export type OrganizerMember = {
  organizer_id: string
  user_id: string
  created_at: Date
  updated_at: Date
  email: string
  role: OrganizerUserRole
  avatar_url: string
}

// to be expanded probably
export type OrganizerJoined = AdminOrganizer

export type OrganizerSerie = AdminSerie & {
  events: Event[]
}

export type SupabaseRPCResponse =
  | {
      success: boolean
    }
  | {
      error: string
    }

export type EPCTag = {
  epc: string
  created_at: Date
  updated_at: Date
  organizer_id: string
  organizer_name?: string
  description?: string
}
export type Location = {
  type: 'Point'
  coordinates: [number, number] // [longitude, latitude]
}

export type Track = {
  id: string
  created_at: Date
  updated_at: Date
  name: string
  description: string
  website: string
  public: boolean
  location: Location
  picture?: string
  picture_url?: string
}

export type TrackLayout = {
  id: string
  created_at: Date
  updated_at: Date
  name: string
  length: number
  description: string
  track_id: string
  track_name?: string
  image?: string
  image_url?: string
}

export type Serie = {
  id: string
  name: string
  start_date: string
  end_date: string
  logo?: string
  logo_url?: string
  organizer_id: string
}

export type AdminSerie = Serie & {
  created_at: Date
  updated_at: Date
  organizer_name?: string
  public: boolean
}

export type SerieWithEvents = Serie & {
  events: Event[]
  organizer?: Organizer
}

export type SerieClass = {
  id: string
  name: string
  priority: number
  short_description: string
  description: string
}

export type AdminSerieClass = SerieClass & {
  created_at: Date
  updated_at: Date
  hidden: boolean
  serie_id: string
  serie_name?: string
}

export type Participant = {
  id: string
  created_at?: Date
  updated_at?: Date
  name: string
  first_name: string
  number: number
  serie_id?: string
  serie_name?: string
  user_id?: string
  user_email?: string
}

export type ParticipantTag = {
  id: string
  epc: string
  created_at: Date
  updated_at: Date
  start_time: Date
  end_time: Date
  participant_id: string
  participant_name?: string
  participant_number?: number
  event_id: string
  event_name?: string
  description?: string
}

export type Car = {
  id: string
  participant: string
  created_at: Date
  updated_at: Date
  name: string
  public: boolean
}

export type Device = {
  id: string
  created_at: Date
  updated_at: Date
  name: string
  description: string
  virtual: boolean
  username: string
  password: string
}

export type DeviceAssignment = {
  id: string
  created_at: Date
  updated_at: Date
  start_time: Date
  end_time: Date
  type: string
  device_id: string
  device_name?: string
  event_id: string
  event_name?: string
}

export type RentedDevice = Omit<DeviceAssignment, 'type'>

export type ParticipantsClass = {
  id: string
  name: string
  car?: string
  start_time: Date
  end_time: Date
}

export type EventParticipant = {
  created_at: Date
  updated_at: Date
  event_id: string
  event_name?: string
  participant_id: string
  participant_name?: string
  participant_number?: number
  classes: ParticipantsClass[]
}

export type EventClassAssignment = {
  id: string
  created_at: Date
  updated_at: Date
  class_id: string
  event_id: string
  event_name?: string
  participant_id: string
  participant_number?: number
  participant_name?: string
  car_id: string
  car_name?: string
  start_time: Date
  end_time: Date
}

export type EventViewParticipantsClass = {
  id: string
  name: string
  car: string
}

export type LapTime = {
  lap_time: number
  created_at: Date
}

export type ViewParticipant = Participant & {
  avg: number
  best3: number[]
  lap_times: LapTime[]
}

export type EventViewParticipant = ViewParticipant & {
  classes: EventViewParticipantsClass[]
}

export type ParticipantAssignedClass = {
  class_assignment_id: string
  class_id: string
  name: string
  car_id?: string
  car_name?: string
  start_time: Date
  end_time: Date
}

export type OrganizerEventParticipant = ViewParticipant & {
  event: string
  classes: ParticipantAssignedClass[]
}

export type ClassViewParticipant = ViewParticipant & {
  position: number
  points: number
}

export type EventViewClass = {
  id: string
  name: string
  // participants: ClassViewParticipant[]
}

export type EventJoined = Event & {
  organizer: Organizer
  classes: EventViewClass[]
  participants: EventViewParticipant[]
}

export type SeasonRankingsParticipantPosition = {
  event_id: string
  position: number
  points: number
}

export type SeasonRankingsParticipant = {
  id: string
  name: string
  number: number
  total: number
  positions: SeasonRankingsParticipantPosition[]
}

export type SeasonRankingsClass = {
  id: string
  name: string
  participants: SeasonRankingsParticipant[]
}

export type SerieSeasonRankings = SerieWithEvents & {
  classes: SeasonRankingsClass[]
}

export enum BridgeSubscriptionSource {
  CouchDB = 'couchdb', // readouts
  Materialized = 'materialized', // readouts
  CSV = 'csv', // participants
  Spreadsheet = 'spreadsheet', // participants
}

export type BridgeSubscriptionStatus = 'active' | 'paused' | 'completed'

export type BridgeSubscriptionBase = {
  id: string
  created_at: Date
  updated_at: Date
  processed_at?: Date
  name: string
  event_id: string
  event_name?: string
  status: BridgeSubscriptionStatus
}

export type BridgeSubscription = ParticipantsSubscription | ReadoutsSubscription

export type ParticipantsSubscription = CSVSubscription | SpreadsheetSubscription

export type ReadoutsSubscription = CouchDBSubscription | MaterializedSubscription

export type CouchDBSubscription = BridgeSubscriptionBase & {
  source: BridgeSubscriptionSource.CouchDB
  source_params: {
    url: string
    poll?: boolean
    database?: string
  }
  source_state: {
    last_rev: string
  }
  device_id: string
  device_name?: string
  processed_laps: number
  generated_readouts: number
}

export type MaterializedSubscription = BridgeSubscriptionBase & {
  source: BridgeSubscriptionSource.Materialized
  source_params: {
    url: string
  }
  device_id: string
  device_name?: string
  processed_laps: number
  generated_readouts: number
}

export type CSVSubscription = BridgeSubscriptionBase & {
  source: BridgeSubscriptionSource.CSV
  source_params: {
    csv_source: string
  }
}

export type SpreadsheetSubscription = BridgeSubscriptionBase & {
  source: BridgeSubscriptionSource.Spreadsheet
  source_params: {
    api_key: string
    sheet_id: string
  }
}
