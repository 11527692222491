import {add} from 'date-fns'

import {RentedDevice} from '@/api/models'

export const emptyRentedDevice: RentedDevice = {
  id: '',
  created_at: new Date(),
  updated_at: new Date(),
  start_time: new Date(),
  end_time: add(new Date(), {hours: 1}),
  device_id: '',
  device_name: '',
  event_id: '',
  event_name: '',
}
