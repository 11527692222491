import * as React from 'react'

import {Outlet} from 'react-router'

import {setSelectedIdentity, selectProfile} from '@/auth/state'
import Footer from '@/layout/footer'
import Header from '@/layout/header'
import {useAppDispatch, useAppSelector} from '@/store'

const Layout = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectProfile)

  React.useEffect(() => {
    user && dispatch(setSelectedIdentity({type: 'user'}))
  }, [user, dispatch])

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}

export default Layout
