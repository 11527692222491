/* eslint-disable sort-keys */
/* eslint-disable import/order */
import {OldEvent} from '@/api/models'

import miatachallenge from '@/common/imgs/miatachallenge.png'
import minimiatachallenge from '@/common/imgs/minimiatachallenge.png'

import kielce from '@/common/imgs/kielce.png'
import kielceTrack2021Dark from '@/common/imgs/kielce-track-2021-dark.svg'

import poznan from '@/common/imgs/poznan.png'
import poznanTrackDark from '@/common/imgs/poznan-track-dark.svg'

import lodz from '@/common/imgs/lodz.png'
import lodzTrackDark from '@/common/imgs/lodz-track-dark.svg'

import silesia from '@/common/imgs/silesia.png'
import silesiaTrackDark from '@/common/imgs/silesia-track-dark.svg'

export const MC2021Kielce: OldEvent = {
  id: 'asd1',

  track_id: 'torkielce-mc2021',
  track_name: 'Tor Kielce - MC 2021',
  track_icon: kielceTrack2021Dark,
  track_length: 1970,

  series_id: 'mc2021',
  series_name: 'Miata Challenge 2021',
  series_icon: miatachallenge,

  name: 'Miata Challenge 2021 - V runda',
  started_at: '28.08.2021 7:00',
  thumbnail: kielce,
}

export const MC2021Poznan: OldEvent = {
  id: 'asd2',

  track_id: 'torpoznan',
  track_name: 'Tor Poznań',
  track_icon: poznanTrackDark,
  track_length: 4085,

  series_id: 'mc2021',
  series_name: 'Miata Challenge 2021',
  series_icon: miatachallenge,

  name: 'Miata Challenge 2021 - VI runda',
  started_at: '07.10.2021 7:00',
  thumbnail: poznan,
}

export const MC2021Silesia2: OldEvent = {
  id: 'mc-2021-silesia-iv',

  track_id: 'silesiaring',
  track_name: 'Silesia Ring',
  track_icon: silesiaTrackDark,
  track_length: 3636,

  series_id: 'mc2021',
  series_name: 'Miata Challenge 2021',
  series_icon: miatachallenge,

  name: 'Miata Challenge 2021 - IV runda',
  started_at: '01.08.2021 7:00',
  thumbnail: silesia,
}

export const MMC2021Lodz: OldEvent = {
  id: 'mmc-2021-lodz',

  track_id: 'torlodz',
  track_name: 'Tor Łódź',
  track_icon: lodzTrackDark,
  track_length: 1500,

  series_id: 'mmc2021',
  series_name: 'Mini Miata Challenge 2021',
  series_icon: minimiatachallenge,

  name: 'Mini Miata Challenge 2021 - II runda',
  started_at: '07.10.2021 7:00',
  thumbnail: lodz,
}
