import {Participant} from '@/api/models'

import {ParticipantFilter} from './types'

export const filterParticipants = (p: Participant, filter: ParticipantFilter) => {
  // Number
  if (filter.number > 0 && p.number !== filter.number) {
    return false
  }
  // Name
  if (filter.name && !p.name?.toLowerCase().includes(filter.name.toLowerCase())) {
    return false
  }
  // First name
  if (filter.first_name && !p.first_name?.toLowerCase().includes(filter.first_name.toLowerCase())) {
    return false
  }
  // User's e-mail
  if (filter.user_email && !p.user_email?.toLowerCase().includes(filter.user_email.toLowerCase())) {
    return false
  }

  return true
}
