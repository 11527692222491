import {add} from 'date-fns'

import {DeviceAssignment} from '@/api/models'

export const emptyDeviceAssignment: DeviceAssignment = {
  id: '',
  created_at: new Date(),
  updated_at: new Date(),
  start_time: new Date(),
  end_time: add(new Date(), {hours: 1}),
  type: '',
  device_id: '',
  device_name: '',
  event_id: '',
  event_name: '',
}
