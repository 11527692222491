import {EventViewParticipant} from '@/api/models'

export const isInClass = (p: EventViewParticipant, classID: string) => {
  let found = false
  p.classes.forEach((c) => c.id === classID && (found = true))
  return found
}

export const mcPositionToPoints = (p: number) => {
  if (p === 1) return 31
  if (p === 10) return 12
  if (p < 15) return 31 - 2 * p
  if (p === 15) return 2
  return 1
}
