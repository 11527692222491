import * as React from 'react'

import {
  Modal,
  Stack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Button,
  ModalFooter,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
  Box,
  useToast,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {ImageType} from 'react-images-uploading'
import Select, {type SingleValue} from 'react-select'
import {v4 as uuidv4} from 'uuid'

import {EditorMode} from '@/admin/types'
import {TrackLayout, RPC, Track} from '@/api/models'
import {uploadFile} from '@/api/utils'
import {ImageUpload} from '@/common/components'
import {useLoadingState, useSupabaseQuery, useSupabaseRPC} from '@/common/hooks'
import {SUPABASE_TRACKS_BUCKET} from '@/constants'
import {selectStyles} from '@/theme/components/select'
import {SelectOption} from '@/types'
import {CommonModalProps} from '@/utils/types'

import {emptyTrackLayout} from '../constants'
import {inputToUpsertArgs} from './utils'

// TODO: Add actual track layout editing ("layout" json field)
const TrackLayoutEditorModal = ({
  onClose,
  open,
  item,
  onComplete,
  mode,
}: CommonModalProps & {item: TrackLayout; mode: EditorMode}) => {
  const {t} = useTranslation()
  const toast = useToast()
  const [input, setInput] = React.useState(emptyTrackLayout)
  const [image, setImage] = React.useState<ImageType>()
  const tracks = useSupabaseQuery<Track>({
    fields: 'id, name',
    table: 'admin_tracks',
    order: 'name',
  })
  const tracksOptions = React.useMemo(
    () => tracks?.data?.map((t) => ({label: t.name, value: t.id})) || [],
    [tracks]
  )

  const {handleRPC} = useSupabaseRPC({
    fnName: RPC.UpsertTrackLayout,
    params: {},
    mode,
    onClose,
    onComplete,
  })

  React.useEffect(() => {
    item && setInput(item)
    item.image_url ? setImage({dataURL: item.image_url}) : setImage(undefined)
  }, [item, open])

  const _handleSubmit = React.useCallback(async () => {
    try {
      const id = input.id || uuidv4()
      const filepath = image?.file
        ? await uploadFile(SUPABASE_TRACKS_BUCKET, `${input.track_id}/layouts/${id}`, image?.file)
        : image?.dataURL
        ? input.image
        : undefined
      await handleRPC(inputToUpsertArgs({...input, id, image: filepath}))
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        status: 'error',
        title: t(`common:dataTable:${mode}Fail`),
      })
    }
  }, [handleRPC, image, input, mode, toast, t])
  const {handleSubmit, loading} = useLoadingState(_handleSubmit)

  const handleInputChange = React.useCallback(
    ({target: {id, value}}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInput((input) => ({...input, [id]: value}))
    },
    []
  )

  const handleTrackChange = React.useCallback((v: SingleValue<SelectOption>) => {
    setInput((prev) => ({...prev, track_id: v?.value ?? ''}))
  }, [])

  const isSubmitDisabled = React.useMemo(
    () => !input.name || input.length <= 0 || !input.description || !input.track_id,
    [input]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            // t(`tracks:layouts:form:addTrackLayout`)
            // t(`tracks:layouts:form:updateTrackLayout`)
            t(`tracks:layouts:form:${mode}TrackLayout`)
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack px={2} py={2}>
            <Stack spacing={4} align="center" width="100%">
              <FormControl id="name" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:entities:track')}</FormLabel>
                <Select
                  placeholder={t('common:entities:track')}
                  value={tracksOptions?.find((i) => i.value === input.track_id)}
                  options={tracksOptions}
                  isClearable={true}
                  isDisabled={tracks?.loading || loading}
                  onChange={handleTrackChange}
                  styles={selectStyles}
                />
              </FormControl>
              <FormControl id="name" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:name')}</FormLabel>
                <Input value={input.name ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl
                id="length"
                isDisabled={loading}
                isRequired={true}
                isInvalid={!!input.length && input.length <= 0}
              >
                <FormLabel>{t('tracks:layouts:fields:length')}</FormLabel>
                <Input value={input.length ?? ''} type="number" onChange={handleInputChange} />
                <FormErrorMessage>{t('tracks:layouts:form:lengthError')}</FormErrorMessage>
              </FormControl>
              <FormControl id="description" isDisabled={loading} isRequired={true}>
                <FormLabel>{t('common:fields:description')}</FormLabel>
                <Textarea resize="vertical" value={input.description ?? ''} onChange={handleInputChange} />
              </FormControl>
              <Box w="100%">
                <ImageUpload onChange={setImage} value={image} editing={true} />
              </Box>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleSubmit} isDisabled={isSubmitDisabled}>
              {t('common:actions:save')}
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              {t('common:actions:cancel')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TrackLayoutEditorModal
