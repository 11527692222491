import admin from './admin.json'
import auth from './auth.json'
import common from './common.json'
import errors from './errors.json'
import events from './events.json'
import home from './home.json'
import navbar from './navbar.json'
import organizer from './organizer.json'
import participants from './participants.json'
import series from './series.json'
import tracks from './tracks.json'
import user from './user.json'

export default {
  admin,
  auth,
  common,
  organizer,
  participants,
  tracks,
  errors,
  events,
  home,
  navbar,
  series,
  user,
}
