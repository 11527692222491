import * as React from 'react'

import {Box, useDisclosure} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'
import {useTranslation} from 'react-i18next'

import {EditorMode} from '@/admin/types'
import {EventClassAssignment} from '@/api/models'
import {DeleteResourceModal} from '@/common/components'
import DataTable from '@/common/data-table'
import {Column} from '@/common/data-table/types'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import {useSupabaseQuery} from '@/common/hooks'

import {emptyEventClassAssignment} from './constants'
import EventClassAssignmentEditorModal from './editor-modal'

type Props = {
  eventID: string
  classID: string
}

const EventClassParticipants = ({eventID, classID}: Props) => {
  const {t} = useTranslation()
  const tableState = useTableState({sortBy: 'participant_number'})
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [currentEventClassAssignment, setCurrentEventClassAssignment] =
    React.useState<EventClassAssignment>(emptyEventClassAssignment)
  const [currentMode, setCurrentMode] = React.useState<EditorMode>('add')
  const {loading, data, fetch, rows} = useSupabaseQuery<EventClassAssignment>({
    fields: '*',
    table: 'admin_event_class_assignments',
    autoFetch: false,
    order: tableState.sortBy.column as keyof EventClassAssignment,
    itemsPerPage: tableState.rowsPerPage,
    pageNumber: tableState.page,
    descending: tableState.descending,
    filter: React.useCallback(
      (query: PostgrestFilterBuilder<EventClassAssignment>) =>
        query.match({event_id: eventID, class_id: classID}),
      [eventID, classID]
    ),
  })

  React.useEffect(() => {
    eventID && classID && fetch()
  }, [eventID, classID, fetch])

  const columns: Column<EventClassAssignment>[] = React.useMemo(
    () => [
      {
        Header: t('common:fields:id').toString(),
        id: 'id',
        renderAs: 'code',
        sortable: false,
      },
      {
        Header: t('admin:eventClassAssignments:fields:participantNumber').toString(),
        id: 'participant_number',
      },
      {
        Header: t('admin:eventClassAssignments:fields:participantName').toString(),
        id: 'participant_name',
      },
      {
        Header: t('admin:eventClassAssignments:fields:car').toString(),
        id: 'car_name',
      },
      {
        Header: t('common:fields:startTime').toString(),
        id: 'start_time',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:endTime').toString(),
        id: 'end_time',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:createdAt').toString(),
        id: 'created_at',
        renderAs: 'timestamp',
      },
      {
        Header: t('common:fields:updatedAt').toString(),
        id: 'updated_at',
        renderAs: 'timestamp',
      },
    ],
    [t]
  )

  const handleAdd = React.useCallback(() => {
    setCurrentEventClassAssignment({...emptyEventClassAssignment, class_id: classID, event_id: eventID})
    setCurrentMode('add')
    onEditorOpen()
  }, [onEditorOpen, eventID, classID])

  const handleEdit = React.useCallback(
    (item: EventClassAssignment) => {
      setCurrentEventClassAssignment(item)
      setCurrentMode('update')
      onEditorOpen()
    },
    [onEditorOpen]
  )

  const handleDelete = React.useCallback(
    (item: EventClassAssignment) => {
      setCurrentEventClassAssignment(item)
      onDeleteOpen()
    },
    [onDeleteOpen]
  )

  const actions = React.useMemo(
    () => [editAction(handleEdit), deleteAction(handleDelete)],
    [handleEdit, handleDelete]
  )

  return (
    <Box>
      <DataTable<EventClassAssignment>
        columns={columns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        loading={loading}
        onRefresh={fetch}
        onAdd={handleAdd}
      />
      <EventClassAssignmentEditorModal
        item={currentEventClassAssignment}
        open={isEditorOpen}
        onClose={onEditorClose}
        onComplete={fetch}
        mode={currentMode}
      />
      <DeleteResourceModal
        table="event_participant_classes"
        id={currentEventClassAssignment.id}
        onClose={onDeleteClose}
        open={isDeleteOpen}
        onComplete={fetch}
      />
    </Box>
  )
}

export default EventClassParticipants
