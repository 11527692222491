import * as React from 'react'

import {Container, Flex, Heading, Stack, Text, Image} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {useAppSelector} from '@/store'

import {selectEventLayout} from '../state'

const EventLayout = () => {
  const {t} = useTranslation()
  const layout = useAppSelector(selectEventLayout)

  if (!layout) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Heading>{t('tracks:layouts:notFound')}</Heading>
      </Flex>
    )
  }

  return (
    <Container maxW="container.xl" px={8}>
      <Flex p={4} justifyContent="center">
        <Stack spacing={4} mr={4}>
          <Heading>{layout.name}</Heading>
          <Text fontSize="3xl">{layout.length}m</Text>
          <Text maxW="20vw">{layout.description}</Text>
        </Stack>
        {layout.image_url && <Image src={layout.image_url} w="100%" top={4} right={4} />}
      </Flex>
    </Container>
  )
}

export default EventLayout
