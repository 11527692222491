import * as React from 'react'

import {Heading, Stack} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import RentedDevices from './rented-devices'
import VirtualDevices from './virtual-devices'

const DevicesWidget = () => {
  const {t} = useTranslation()

  return (
    <Stack spacing={4}>
      <Heading size="md">{t('organizer:devices:manageDevices')}</Heading>
      <RentedDevices />
      <VirtualDevices />
    </Stack>
  )
}

export default DevicesWidget
