import * as React from 'react'

import {
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  MenuDivider,
  useToast,
  Avatar,
  MenuGroup,
  MenuItemOption,
  HStack,
  Text,
  Tag,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {Link, useNavigate} from 'react-router-dom'

import {supabase} from '@/api'
import {User} from '@/api/models'
import {Can} from '@/auth/abilities'
import {selectSelectedIdentity, setSelectedIdentity} from '@/auth/state'
import {useAppSelector, useAppDispatch} from '@/store'

const UserMenu = ({user}: {user: User}) => {
  const {t} = useTranslation()
  const toast = useToast()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const selectedIdentity = useAppSelector(selectSelectedIdentity)

  const handleSignOut = React.useCallback(async () => {
    try {
      const {error} = await supabase.auth.signOut()
      if (error) throw error
      navigate('/', {replace: true})
    } catch (e) {
      toast({
        description: (e as Error).message,
        isClosable: true,
        status: 'error',
        title: t('errors:failedToSignOut'),
      })
    }
  }, [t, toast, navigate])

  const handleOrganizerIdentitySelection = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      dispatch(setSelectedIdentity({type: 'organizer', id: e.currentTarget.name}))
      navigate(`/organizer/${e.currentTarget.name}`)
    },
    [dispatch, navigate]
  )

  const handleUserIdentitySelection = React.useCallback(() => {
    if (selectedIdentity?.type === 'user') return
    dispatch(setSelectedIdentity({type: 'user', id: user.id}))
    navigate('/')
  }, [dispatch, navigate, user, selectedIdentity])

  return (
    <Menu placement="bottom-end">
      <MenuButton rounded="full" cursor="pointer" minW={0}>
        <HStack spacing={4}>
          {selectedIdentity?.role === 'admin' && <Tag colorScheme="red">admin</Tag>}
          <Text whiteSpace="nowrap">{selectedIdentity?.name}</Text>
          {selectedIdentity?.image_url ? (
            <Avatar size="sm" src={selectedIdentity?.image_url} />
          ) : (
            <Avatar size="sm" />
          )}
        </HStack>
      </MenuButton>
      <MenuList>
        {!!user.assigned_organizers?.length && (
          <>
            <MenuItemOption
              onClick={handleUserIdentitySelection}
              isChecked={selectedIdentity?.type === 'user'}
            >
              <HStack>
                {user.avatar_url ? <Avatar size="sm" src={user.avatar_url} /> : <Avatar size="sm" />}
                <Text>{user.email}</Text>
              </HStack>
            </MenuItemOption>
            <MenuGroup title={t('navbar:menu:organizers')}>
              {user.assigned_organizers.map((o) => (
                <MenuItemOption
                  key={o.id}
                  minH="40px"
                  name={o.id}
                  onClick={handleOrganizerIdentitySelection}
                  isChecked={selectedIdentity?.id === o.id}
                >
                  <HStack>
                    {o.logo_url && <Avatar size="sm" name={o.name} mr="12px" src={o.logo_url} />}
                    <Text>{o.name}</Text>
                  </HStack>
                </MenuItemOption>
              ))}
            </MenuGroup>
            <MenuDivider />
          </>
        )}
        <MenuItem as={Link} to="/user/profile">
          {t('navbar:menu:profile')}
        </MenuItem>
        <Can I="access" an="AdminPanel">
          <MenuItem as={Link} to="/admin">
            {t('navbar:menu:adminPanel')}
          </MenuItem>
        </Can>
        <MenuItem onClick={handleSignOut}>{t('navbar:menu:signout')}</MenuItem>
      </MenuList>
    </Menu>
  )
}

export default UserMenu
