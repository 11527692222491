import {AdminSerieClass} from '@/api/models'

export const emptySerieClass: AdminSerieClass = {
  id: '',
  created_at: new Date(),
  updated_at: new Date(),
  name: '',
  short_description: '',
  description: '',
  priority: 0,
  hidden: true,
  serie_id: '',
  serie_name: '',
}
